import { useEffect } from "react";
import CallbackContent from "../components/CallbackContent";
import {
  fetchPipedriveOrganizationFields,
  fetchPipedrivePersonFields,
} from "../pipedrive";
import { useAuth0 } from "@auth0/auth0-react";

export function SalesforceCallback() {
  function handleCallback() {
    const hash = window.location.hash.substr(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get("access_token");
    const refreshToken = params.get("refresh_token");
    const instanceUrl = params.get("instance_url");
    const id = params.get("id");

    // Set the expiry time to 15 minutes from now
    const expiryTime = ((Date.now() / 1000) | 0) + 15 * 60;

    localStorage.setItem("salesforceAccessToken", accessToken);
    localStorage.setItem("salesforceRefreshToken", refreshToken);
    localStorage.setItem("salesforceInstanceUrl", instanceUrl);
    localStorage.setItem("salesforceExpiryTime", expiryTime.toString());
    localStorage.setItem("salesforceId", id);

    // get the user's name
    fetch(id, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("salesforceName", data.Name);
      });

    window.location.href = "/v1/dashboard";
  }

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div>
      <CallbackContent />
    </div>
  );
}

export function HubSpotCallback() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  function handleCallback() {
    const code = new URLSearchParams(window.location.search).get("code");

    fetch("https://api.compelling.ai/hubspot/HubSpotAuth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        grantType: "authorization_code",
        codeOrToken: code,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        const HSaccessToken = data.access_token;
        const HSrefreshToken = data.refresh_token;
        const HSexpiresIn = data.expires_in;
        const HSuserId = data.userData.user_id;
        const HSuser = data.userData.user;
        const HSowner = data.ownerData;
        const HSname = HSowner.firstName + " " + HSowner.lastName;

        const HSexpiresAt = ((Date.now() / 1000) | 0) + HSexpiresIn;

        localStorage.setItem("hubspotAccessToken", HSaccessToken);
        localStorage.setItem("hubspotRefreshToken", HSrefreshToken);
        localStorage.setItem("hubspotExpiresAt", HSexpiresAt.toString());
        localStorage.setItem("hubspotUserId", HSuserId);
        localStorage.setItem("hubspotEmail", HSuser);
        localStorage.setItem("hubspotName", HSname);
        localStorage.setItem("hubspotOwnerId", HSowner.id);
        // Prepare metadata to send to Lambda, adjust based on the data context
        const metadataPayload = {
          userId: user.sub,
          appMetadata: {
            hubspot: {
              accessToken: HSaccessToken,
              refreshToken: HSrefreshToken,
              expiresAt: HSexpiresAt.toString(),
              email: HSuser,
              name: HSname,
              userId: HSowner.id,
            },
          },
        };

        const apiUrl = "https://api.compelling.ai/data/user";
        getAccessTokenSilently({
          authorizationParams: {
            audience: `https://auth0-jwt-authorizer`,
          },
        })
          .then((token) => {
            fetch(apiUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(metadataPayload),
            })
              .then((response) => response.json())
              .then(() => {
                window.location.href = "/v1/dashboard";
              })
              .catch((error) => {
                console.error("Error sending data to Lambda:", error);
                window.location.href = "/v1/dashboard";
              });
          })
          .catch((err) => {
            console.log(err);
            window.location.href = "/v1/dashboard";
          });
      });
  }

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div>
      <CallbackContent />
    </div>
  );
}

export function PipedriveCallback() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  function handleCallback() {
    const code = new URLSearchParams(window.location.search).get("code");

    fetch("https://api.compelling.ai/pipedrive/PipedriveAuth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        grantType: "authorization_code",
        codeOrToken: code,
        env: process.env.REACT_APP_ENV,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        const PDdomain = data.api_domain;
        const PDaccessToken = data.access_token;
        const PDrefreshToken = data.refresh_token;
        const PDexpiresIn = data.expires_in;
        const PDuserId = data.userData.data.id;
        const PDuser = data.userData.data.email;
        const PDname = data.userData.data.name;

        const PDexpiresAt = ((Date.now() / 1000) | 0) + PDexpiresIn;

        localStorage.setItem("pipedriveAccessToken", PDaccessToken);
        localStorage.setItem("pipedriveRefreshToken", PDrefreshToken);
        localStorage.setItem("pipedriveExpiresAt", PDexpiresAt.toString());
        localStorage.setItem("pipedriveUserId", PDuserId);
        localStorage.setItem("pipedriveEmail", PDuser);
        localStorage.setItem("pipedriveName", PDname);
        localStorage.setItem("pipedriveDomain", PDdomain);

        // fetchPipedriveOrganizationFields().then((data) => {
        //   localStorage.setItem(
        //     "pipedriveOrganizationFields",
        //     JSON.stringify(data)
        //   );
        // });
        //
        // fetchPipedrivePersonFields().then((data) => {
        //   localStorage.setItem("pipedrivePersonFields", JSON.stringify(data));
        // });

        // Prepare metadata to send to Lambda, adjust based on the data context
        const metadataPayload = {
          userId: user.sub,
          appMetadata: {
            pipedrive: {
              accessToken: PDaccessToken,
              refreshToken: PDrefreshToken,
              expiresAt: PDexpiresAt.toString(),
              domain: PDdomain,
              email: PDuser,
              name: PDname,
              userId: PDuserId,
              organizationFields: JSON.stringify(data),
              personFields: JSON.stringify(data),
            },
          },
        };

        const apiUrl = "https://api.compelling.ai/data/user";
        getAccessTokenSilently({
          authorizationParams: {
            audience: `https://auth0-jwt-authorizer`,
          },
        })
          .then((token) => {
            fetch(apiUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(metadataPayload),
            })
              .then((response) => response.json())
              .then(() => {
                window.location.href = "/v1/dashboard";
              })
              .catch((error) => {
                console.error("Error sending data to Lambda:", error);
                window.location.href = "/v1/dashboard";
              });
          })
          .catch((err) => {
            console.log(err);
            window.location.href = "/v1/dashboard";
          });
      });
  }

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div>
      <CallbackContent />
    </div>
  );
}

export function OutreachCallback() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  function handleCallback() {
    const code = new URLSearchParams(window.location.search).get("code");

    fetch("https://api.compelling.ai/outreach/OutreachAuth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        grantType: "authorization_code",
        codeOrToken: code,
        env: process.env.REACT_APP_ENV,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        // const PDdomain = data.api_domain;
        // const PDaccessToken = data.access_token;
        // const PDrefreshToken = data.refresh_token;
        // const PDexpiresIn = data.expires_in;
        // const PDuserId = data.userData.data.id;
        // const PDuser = data.userData.data.email;
        // const PDname = data.userData.data.name;
        //
        // const PDexpiresAt = ((Date.now() / 1000) | 0) + PDexpiresIn;
        //
        // localStorage.setItem("pipedriveAccessToken", PDaccessToken);
        // localStorage.setItem("pipedriveRefreshToken", PDrefreshToken);
        // localStorage.setItem("pipedriveExpiresAt", PDexpiresAt.toString());
        // localStorage.setItem("pipedriveUserId", PDuserId);
        // localStorage.setItem("pipedriveEmail", PDuser);
        // localStorage.setItem("pipedriveName", PDname);
        // localStorage.setItem("pipedriveDomain", PDdomain);
        //
        // // Prepare metadata to send to Lambda, adjust based on the data context
        // const metadataPayload = {
        //   userId: user.sub,
        //   appMetadata: {
        //     pipedrive: {
        //       accessToken: PDaccessToken,
        //       refreshToken: PDrefreshToken,
        //       expiresAt: PDexpiresAt.toString(),
        //       domain: PDdomain,
        //       email: PDuser,
        //       name: PDname,
        //       userId: PDuserId,
        //       organizationFields: JSON.stringify(data),
        //       personFields: JSON.stringify(data),
        //     },
        //   },
        // };
        //
        // const apiUrl = "https://api.compelling.ai/data/user";
        // getAccessTokenSilently({
        //   authorizationParams: {
        //     audience: `https://auth0-jwt-authorizer`,
        //   },
        // })
        //   .then((token) => {
        //     fetch(apiUrl, {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${token}`,
        //       },
        //       body: JSON.stringify(metadataPayload),
        //     })
        //       .then((response) => response.json())
        //       .then(() => {
        //         window.location.href = "/v1/dashboard";
        //       })
        //       .catch((error) => {
        //         console.error("Error sending data to Lambda:", error);
        //         window.location.href = "/v1/dashboard";
        //       });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     window.location.href = "/v1/dashboard";
        //   });
      });
  }

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div>
      <CallbackContent />
    </div>
  );
}
