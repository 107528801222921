import { default as React, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import MessageRating from "./MessageRating";

const EDITOR_HOLDER_ID = "editorjs";

export const nameRegex =
  /\[Your Name\]|\[YourName\]|\[Dein Vorname\]|\[Your Name|\[Name\]|\[Dein Name\]|\[Ihr Name\]|\[Ihr Name|\[Your name here\]|\[uw naam\]|\[je naam\]|\(Your Name\)|\(Your Name/gi;

export default function Editor({
  resetEditor,
  setResetEditor,
  name,
  apiData,
  editorData,
  setEditorData,
  textCopied,
  followUp,
  followUpUI,
  setFollowUpUI,
  setLocalEmail,
  setLocalFollowUp,
  preds,
  fetching,
}) {
  const ejInstance = useRef();

  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current.destroy();
      ejInstance.current = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (followUpUI) {
      setFollowUpUI(false);
      if (ejInstance.current) {
        ejInstance.current.render(editorData);
      }
    } else if (resetEditor) {
      if (ejInstance.current) {
        ejInstance.current.render(editorData);
      }
    } else {
      if (followUp) {
        setLocalFollowUp(null, editorData);
      } else {
        setLocalEmail(null, editorData);
      }
    }
  }, [editorData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log("apiData", apiData);
    if (ejInstance.current && apiData.data) {
      if (editorData.blocks[0].id === "Placeholder" || resetEditor) {
        ejInstance.current.render({
          time: new Date().getTime(),
          blocks: [
            {
              type: "paragraph",
              data: {
                text: JSON.parse(apiData.data).prediction,
              },
            },
          ],
        });
        setEditorData({
          time: new Date().getTime(),
          blocks: [
            {
              type: "paragraph",
              data: {
                text: JSON.parse(apiData.data).prediction,
              },
            },
          ],
        });
        setResetEditor(false);
      } else {
        ejInstance.current.render({
          time: new Date().getTime(),
          blocks: [
            ...editorData.blocks,
            {
              type: "paragraph",
              data: {
                text: JSON.parse(apiData.data)
                  .prediction.replace(nameRegex, { name }.name)
                  .trim(),
              },
            },
          ],
        });
        setEditorData({
          time: new Date().getTime(),
          blocks: [
            ...editorData.blocks,
            {
              type: "paragraph",
              data: {
                text: JSON.parse(apiData.data)
                  .prediction.replace(nameRegex, { name }.name)
                  .trim(),
              },
            },
          ],
        });
      }
    }
  }, [apiData]); // eslint-disable-line react-hooks/exhaustive-deps

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITOR_HOLDER_ID,
      logLevel: "ERROR",
      data: editorData,
      onReady: () => {
        ejInstance.current = editor;
      },
      onChange: async () => {
        let content = await editor.save();
        // Put your logic here to save this data to your DB
        setEditorData(content);
      },
      autofocus: false,
      tools: {
        header: Header,
      },
    });
  };

  return (
    <React.Fragment>
      <Transition
        show={textCopied}
        enter="duration-400 transition ease-in-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-200 transition ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <span className="bg-indigo-100 mr-3 mt-3 float-right text-indigo-700 text-xs font-sans inline-flex items-center px-2.5 py-1.5 rounded border border-indigo-300">
          Copied
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="currentColor"
            className="bi bi-clipboard2-check ml-1"
            viewBox="0 0 16 16"
          >
            <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
            <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
            <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
          </svg>
        </span>
      </Transition>
      <div id={EDITOR_HOLDER_ID} className="p-20 ph-no-capture -mb-4">
        {" "}
      </div>
      <MessageRating
        preds={preds}
        editorData={editorData}
        fetching={fetching}
      ></MessageRating>
    </React.Fragment>
  );
}
