export const msalConfig = {
  auth: {
    clientId: "3f7e31cc-e5a6-42ef-a7f8-2a701340f130",
    authority:
      "https://login.microsoftonline.com/89b5b5d1-7952-441c-a00e-f83701396526", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.href,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["https://compelling-ai.api.crm4.dynamics.com/.default"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const dynamicsConfig = {
  dynamicsEndpoint: "https://compelling-ai.api.crm4.dynamics.com/api/data/v9.2",
};
