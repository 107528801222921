const url = "https://api.compelling.ai/pipedrive/PipedriveAPI";
const apiToken = localStorage.getItem("pipedriveAccessToken");
const companyDomain = localStorage.getItem("pipedriveDomain")
  ? localStorage.getItem("pipedriveDomain").replace("https://", "")
  : localStorage.getItem("pipedriveDomain");

export async function createPipedriveOrganization(organizationData) {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint: "/organizations",
    method: "POST",
    data: {
      name: organizationData.Account.Namen,
    },
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to create organization: " + response.statusText);
  }

  const data = await response.json();
  return data;
}

export async function createPipedrivePerson(personDetails, organizationId) {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint: "/persons",
    method: "POST",
    data: {
      name: personDetails.vorname + " " + personDetails.name,
      email: personDetails.email,
      phone: personDetails.phone,
      org_id: organizationId,
    },
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to create person: " + response.statusText);
  }

  const data = await response.json();
  return data;
}

export async function fetchPipedriveOrganizationFields() {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint: "/organizationFields",
    method: "GET",
    data: {},
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error(
      "Failed to fetch organization fields: " + response.statusText
    );
  }

  const data = await response.json();
  return data;
}

export async function fetchPipedrivePersonFields() {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint: "/personFields",
    method: "GET",
    data: {},
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch person fields: " + response.statusText);
  }

  const data = await response.json();
  return data;
}

export async function logPipedriveActivity(personId, organizationId, details) {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint: "/activities",
    method: "POST",
    data: {
      subject: details.subject,
      type: details.type, // This should match the custom or chosen activity type
      done: details.done, // Set to true if the activity is already completed
      // due_date: details.dueDate, // Optionally set a due date
      person_id: personId,
      org_id: organizationId, // Optional: link to an organization
      note: details.note, // Optional: any additional notes
    },
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to log activity: " + response.statusText);
  }

  const data = await response.json();
  return data;
}

export async function fetchPipedrivePersonByEmail(email) {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint:
      "/persons/search?term=" + email + "&fields=email&exact_match=true",
    method: "GET",
    data: {},
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch person: " + response.statusText);
  }

  const data = await response.json();

  // Check if items array exists and has at least one item
  if (data.data && data.data.items && data.data.items.length > 0) {
    // Return the first item from the items array
    return data.data.items[0].item;
  } else {
    // Return null or throw an error if no person was found
    return null;
  }
}

export async function fetchPipedriveOrganizationByName(name) {
  const requestBody = {
    token: apiToken,
    companyDomain: companyDomain,
    endpoint:
      "/organizations/search?term=" + name + "&fields=name&exact_match=true",
    method: "GET",
    data: {},
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch organization: " + response.statusText);
  }

  const data = await response.json();

  // Check if items array exists and has at least one item
  if (data.data && data.data.items && data.data.items.length > 0) {
    // Return the first item from the items array
    return data.data.items[0].item;
  } else {
    // Return null or throw an error if no organization was found
    return null;
  }
}
