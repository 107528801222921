import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { Crisp } from "crisp-sdk-web";

import "../styles/Main.css";
import posthog from "posthog-js";

posthog.init("phc_VOWM0Yeow3HykaGqZiO2b3RcfvPgxMwHWCHLw2J43zN", {
  api_host: "https://eu.posthog.com",
  opt_in_site_apps: true,
  // opt_out_capturing_by_default: true,
});

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Main extends Component {
  componentDidMount() {
    Crisp.configure("42d5f5c8-a41c-47bc-8fda-1cc076b55c0b");
  }
  render() {
    return (
      <>
        {/*<script className="_iub_cs_activate" data-iub-purposes="4">*/}
        {/*  {posthog.opt_in_capturing()}*/}
        {/*</script>*/}
        <Outlet />
      </>
    );
  }
}

export default Main;
