import { token } from "../pages/Composer";
import { updateHubSpotCompanyProperty } from "../hubSpot";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPreds = async (
  Product,
  Contact,
  Language,
  compellingTags,
  toneTags
) => {
  //plug each entry from compellingTags array into a string and enclose in quotes
  let compellingTagsString = "";
  compellingTags.forEach((tag) => {
    compellingTagsString += '"' + tag + '", ';
    if (tag === compellingTags[compellingTags.length - 1]) {
      compellingTagsString = compellingTagsString.slice(0, -2);
    }
  });

  //same for toneTags
  let toneTagsString = "";
  toneTags.forEach((tag) => {
    toneTagsString += tag + ", ";
    if (tag === toneTags[toneTags.length - 1]) {
      toneTagsString = toneTagsString.slice(0, -2);
    }
  });

  console.log(compellingTagsString);
  return fetch(API_URL + `/api/asterisk/editor/`, {
    method: "POST",
    body: JSON.stringify({
      datapoints: "",
      "ai-german": Language === "DE" ? true : false,
      "message-mode": true,
      "question-mode": true,
      "chat-mode": false,
      recipient:
        Contact.fullname +
        ", " +
        Contact.jobtitle +
        " at " +
        Contact.parentcustomerid_account.name,
      product: Product.description,
      question:
        "Your task is to gather information about the company " +
        Contact.parentcustomerid_account.name +
        ", only focusing on events pertaining " +
        compellingTagsString +
        ", that happened within the last two months. " +
        "Step 1: Research news articles and updates related to " +
        Contact.parentcustomerid_account.name +
        ". Focus on finding information about " +
        compellingTagsString +
        " that happened within the past two months only and " +
        "would be relevant to an " +
        Contact.jobtitle +
        " at that company. Step 2: If you were unable to find any relevant information regarding " +
        compellingTagsString +
        " within the specified time frame, simply describe the 3 most pressing challenges of an " +
        Contact.jobtitle +
        " at " +
        Contact.parentcustomerid_account.name +
        " by yourself without using a tool, in one or two sentences. " +
        "Please ensure that all provided information is events no older than two months. " +
        "Only share what you know for certain; avoid providing speculative or uncertain details. " +
        "If you cannot find any relevant information at all, simply respond with 'No relevant information.'",
      tone: toneTagsString,
      token: token,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
    })
    .finally((response) => {
      return "done";
    });
};

export const chatPreds = async (Email, Prompt, Language, FollowUp) => {
  if (FollowUp) {
    if (Language !== "DE") {
      Prompt =
        "Please write an email following up on this previous message, after the recipient has not responded.";
    } else {
      Prompt =
        "Bitte schreibe eine E-Mail, die auf diese vorhergehende Nachricht folgt, nachdem der Empfäner nicht geantwortet hat.";
    }
  }
  return fetch(
    "https://europe-west3-seekwhens.cloudfunctions.net/asterisk-chat-v1",
    {
      method: "POST",
      body: JSON.stringify({
        "ai-german": Language === "DE" ? true : false,
        current_email: Email,
        chat_prompt: Prompt,
        token: token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
    })
    .finally((response) => {
      return "done";
    });
};

export const demoAuth = async (email, setLoading, interactionType) => {
  if (!email.includes("@seekwhens.com")) {
    setLoading(true);
    return fetch(API_URL + `/api/compelling-demo-auth/`, {
      method: "POST",
      body: JSON.stringify({
        interactionType: interactionType,
        email: email,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
      })
      .finally(() => {
        setLoading(false);
        return "done";
      });
  } else {
    return "internal login";
  }
};

export const postRating = async (rating, message) => {
  return fetch(API_URL + `/api/compelling-demo-eval/`, {
    method: "POST",
    body: JSON.stringify({
      message: message,
      rating: rating,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
    })
    .finally(() => {
      return "done";
    });
};

export const fetchPredsV2 = async (
  Product,
  Contact,
  Language,
  compellingTags,
  toneTags,
  rules,
  templates,
  loggedUser,
  noUseBestPractices,
  linkedinProfile,
  client_name,
  mode
) => {
  //plug each entry from compellingTags array into a string and enclose in quotes
  let compellingTagsString = "";
  compellingTags.forEach((tag) => {
    compellingTagsString += '"' + tag + '", ';
    if (tag === compellingTags[compellingTags.length - 1]) {
      compellingTagsString = compellingTagsString.slice(0, -2);
    }
  });

  //same for toneTags
  let toneTagsString = "";
  toneTags.forEach((tag) => {
    toneTagsString += tag + ", ";
    if (tag === toneTags[toneTags.length - 1]) {
      toneTagsString = toneTagsString.slice(0, -2);
    }
  });

  //check if linkedinProfile is matching the current contact
  let profileSummary = "";
  if (linkedinProfile) {
    // if (linkedinProfile.profileData.profile.fullName === Contact.fullname) {
    //   profileSummary = linkedinProfile.profileData.summary;
    // } else {
    //   profileSummary = "";
    // }
    if (linkedinProfile.activities.length > 0) {
      profileSummary =
        JSON.stringify(linkedinProfile.activities) +
        " Profile Summary: " +
        linkedinProfile.summary;
    } else {
      profileSummary = linkedinProfile.summary;
    }
  }

  let account_id = JSON.parse(localStorage.getItem("current_account"))["_id"];

  return fetch(
    "https://europe-west3-seekwhens.cloudfunctions.net/asterisk-gen-v2",
    {
      method: "POST",
      body: JSON.stringify({
        ai_language: Language,
        recipient: {
          name: Contact.fullname,
          company: Contact.parentcustomerid_account.name,
          jobtitle: Contact.jobtitle,
        },
        product: Product.description,
        event_tags: compellingTagsString,
        tone: toneTagsString,
        rules: rules,
        no_best_practices: noUseBestPractices,
        templates: templates,
        channel_token: token,
        user: loggedUser,
        profile_summary: profileSummary,
        client_name: client_name,
        account_id: account_id,
        mode: mode,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
    })
    .catch((error) => {
      console.log(`This is an fetch error: ${error}`);
    })
    .finally((response) => {
      return "done";
    });
};

export const fetchResearch = async (query) => {
  return fetch("https://europe-west3-seekwhens.cloudfunctions.net/asterisk", {
    method: "POST",
    body: query,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(`This is an fetch error: ${error}`);
    })
    .finally((response) => {
      return "done";
    });
};

export const fetchProspects = async (query) => {
  return fetch(API_URL + `/api/asterisk/prospects/`, {
    method: "POST",
    body: JSON.stringify({
      query: query,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(`This is an fetch error: ${error}`);
    })
    .finally((response) => {
      return "done";
    });
};

export async function getCRMTechInsight(companies: Company[]) {
  const url =
    "https://qkj9jh0z47.execute-api.eu-central-1.amazonaws.com/default/asterisk";

  for (const company of companies) {
    console.log("domain: ", company.original.domain);
    if (!company.original.checked) {
      const requestBody = {
        hostname: "api.wappalyzer.com",
        token: "up76keSMf48ApFMzA2o5n9la4aC4zUc78k3DpbDG",
        endpoint: `/lookup/v2/?urls=https://${company.original.domain}&recursive=false`,
        method: "GET",
        data: {
          properties: {},
        },
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to update contact: " + response.statusText);
      }

      const data = await response.json();
      console.log("response: ", data);

      // get all technologies that are where the categories array contains id 53 (CRM) OR 32 (Marketing Automation)
      let crmTech;
      try {
        crmTech = data[0].technologies.filter((tech) =>
          tech.categories.some(
            (category) => category.id === 53 || category.id === 32
          )
        );
      } catch (error) {
        console.log("error: ", error);
        const hubSpotAllCompanies = JSON.parse(
          localStorage.getItem("hubSpotAllCompanies")
        );
        const companyToUpdate = hubSpotAllCompanies.find(
          (companyToUpdate) => companyToUpdate.id === company.original.id
        );
        companyToUpdate.error = true;
        console.log("companyToUpdate: ", companyToUpdate);
        localStorage.setItem(
          "hubSpotAllCompanies",
          JSON.stringify(hubSpotAllCompanies)
        );
        continue;
      }

      // sort the crmTech array so that entries in categories array with id 53 (CRM) are first
      crmTech.sort((a, b) => {
        if (a.categories[0].id === 53) {
          return -1;
        } else {
          return 1;
        }
      });

      // create a string of all technologies names in the crmTech array
      const crmTechString = crmTech.map((tech) => tech.name).join(", ");

      // update the company in local storage object hubSpotAllCompanies with the new crm property using the company id
      const hubSpotAllCompanies = JSON.parse(
        localStorage.getItem("hubSpotAllCompanies")
      );
      const companyToUpdate = hubSpotAllCompanies.find(
        (companyToUpdate) => companyToUpdate.id === company.original.id
      );
      companyToUpdate.crm = crmTechString;
      companyToUpdate.checked = true;
      console.log("companyToUpdate: ", companyToUpdate);
      localStorage.setItem(
        "hubSpotAllCompanies",
        JSON.stringify(hubSpotAllCompanies)
      );

      // if the company has a crm property, update the company in hubspot
      if (crmTechString !== "") {
        updateHubSpotCompanyProperty(companyToUpdate);
      }
    } else {
      console.log("company already checked");
    }
  }
}

export async function createLinkedinSummary(recentPosts, prospectName) {
  return fetch(API_URL + `/api/asterisk/summarize/`, {
    method: "POST",
    body: JSON.stringify({
      recentPosts: recentPosts,
      prospectName: prospectName,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(`This is an fetch error: ${error}`);
    });
}

export async function generateSequence(prospectIDs, sequenceID, mailboxID) {
  return fetch(
    "https://europe-west3-seekwhens.cloudfunctions.net/compelling-sq",
    {
      method: "POST",
      body: JSON.stringify({
        prospect_ids: prospectIDs,
        sequence_id: sequenceID,
        mailbox_id: mailboxID,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      console.log(`This is an fetch error: ${error}`);
    });
}
