import { useEffect, useState } from "react";

import { postRating } from "../utils/seekwhensAPI";

export default function MessageRating({ preds, editorData, fetching }) {
  const [showRating, setShowRating] = useState(false);
  const [showThanks, setShowThanks] = useState(false);

  useEffect(() => {
    if (preds && !fetching) {
      setShowRating(true);
      const buttons = document.querySelectorAll(".animated-button");
      buttons.forEach((button, index) => {
        button.style.animationDelay = `${index * 100}ms`;
      });
    } else {
      setShowRating(false);
    }
  }, [preds, fetching]);

  const setRating = (rating) => {
    setShowRating(false);
    postRating(rating, editorData.blocks.map((e) => e.data.text).join("\n\n"));
    setShowThanks(true);
    setTimeout(() => {
      setShowThanks(false);
    }, 2000);
  };

  return (
    <>
      <div
        id="messageRating"
        className={`${!showRating ? "hidden" : ""} bottom-0 overflow-hidden`}
      >
        <div className="flex flex-row justify-center">
          <div className="rounded-t-md px-4 pt-4 text-yellow-800 border border-b-0 border-yellow-300 bg-yellow-50">
            <span className="font-mono text-yellow-700 text-xs block text-center pb-4">
              Please rate this message to help us improve {"<3"}
            </span>
            <span className="font-mono text-yellow-500 text-xs mr-2">
              Very bad
            </span>
            <button
              className={`${
                preds ? "button-animation" : ""
              } bg-white hover:bg-stone-200 text-gray-800 font-semibold py-2 px-4 rounded-tl`}
              onClick={() => setRating(1)}
            >
              1
            </button>
            <button
              className={`${
                preds ? "animated-button" : ""
              } bg-white hover:bg-stone-200 text-gray-800 font-semibold py-2 px-4 border-l border-stone-200`}
              onClick={() => setRating(2)}
            >
              2
            </button>
            <button
              className={`${
                preds ? "animated-button" : ""
              } bg-white hover:bg-stone-200 text-gray-800 font-semibold py-2 px-4 border-l border-stone-200`}
              onClick={() => setRating(3)}
            >
              3
            </button>
            <button
              className={`${
                preds ? "animated-button" : ""
              } bg-white hover:bg-stone-200 text-gray-800 font-semibold py-2 px-4 border-l border-stone-200`}
              onClick={() => setRating(4)}
            >
              4
            </button>
            <button
              className={`${
                preds ? "animated-button" : ""
              } bg-white hover:bg-stone-200 text-gray-800 font-semibold py-2 px-4 rounded-tr border-l border-stone-200`}
              onClick={() => setRating(5)}
            >
              5
            </button>
            <span className="font-mono text-yellow-500 text-xs ml-2">
              Very good
            </span>
          </div>
        </div>
      </div>
      <div
        className={`${!showThanks ? "hidden" : ""} bottom-0 overflow-hidden`}
      >
        <div className="flex flex-row justify-center">
          <div className="rounded-t-md px-4 pt-4 text-yellow-800 border border-b-0 border-yellow-300 bg-yellow-50">
            <span className="font-mono text-yellow-700 text-xs block text-center pb-4">
              Thanks! {"👋"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
