import React, { Fragment } from "react";

export default function LanguageSelect({
  selectedLanguage,
  setSelectedLanguage,
}) {
  return (
    <Fragment>
      <ul className="mr-4 items-center text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
        {/*<li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">*/}
        {/*  <div className="flex items-center pl-3">*/}
        {/*    <input*/}
        {/*      onChange={() => {*/}
        {/*        setSelectedLanguage(null, "EN");*/}
        {/*      }}*/}
        {/*      checked={selectedLanguage === "EN"}*/}
        {/*      id="horizontal-list-radio-english"*/}
        {/*      type="radio"*/}
        {/*      value=""*/}
        {/*      name="list-radio"*/}
        {/*      className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 focus:ring-indigo-500 focus:ring-2"*/}
        {/*    />*/}
        {/*    <label*/}
        {/*      htmlFor="horizontal-list-radio-english"*/}
        {/*      className="w-full py-3 ml-2 font-medium text-gray-900"*/}
        {/*    >*/}
        {/*      🇬🇧*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*</li>*/}
        {/*<li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">*/}
        {/*  <div className="flex items-center pl-3">*/}
        {/*    <input*/}
        {/*      onChange={() => {*/}
        {/*        setSelectedLanguage(null, "DE");*/}
        {/*      }}*/}
        {/*      checked={selectedLanguage === "DE"}*/}
        {/*      id="horizontal-list-radio-german"*/}
        {/*      type="radio"*/}
        {/*      value=""*/}
        {/*      name="list-radio"*/}
        {/*      className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 focus:ring-indigo-500 focus:ring-2"*/}
        {/*    />*/}
        {/*    <label*/}
        {/*      htmlFor="horizontal-list-radio-german"*/}
        {/*      className="w-full py-3 ml-2 font-medium text-gray-900"*/}
        {/*    >*/}
        {/*      🇩🇪*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*</li>*/}
        <li className="w-full rounded-lg">
          <div className="flex items-center bg-none rounded-lg">
            <select
              className="border-0 rounded-lg"
              onChange={(e) => {
                setSelectedLanguage(null, e.target.value);
              }}
              value={selectedLanguage}
            >
              <option value="EN">🇬🇧</option>
              <option value="FR">🇫🇷</option>
              <option value="DE">🇩🇪</option>
              <option value="NL">🇳🇱</option>
            </select>
          </div>
        </li>
        {/*<li className="w-full border-gray-200">*/}
        {/*  <div className="flex items-center pl-3">*/}
        {/*    <input*/}
        {/*      onChange={() => {*/}
        {/*        setSelectedLanguage(null, "NL");*/}
        {/*      }}*/}
        {/*      checked={selectedLanguage === "NL"}*/}
        {/*      id="horizontal-list-radio-dutch"*/}
        {/*      type="radio"*/}
        {/*      value=""*/}
        {/*      name="list-radio"*/}
        {/*      className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 focus:ring-indigo-500 focus:ring-2"*/}
        {/*    />*/}
        {/*    <label*/}
        {/*      htmlFor="horizontal-list-radio-dutch"*/}
        {/*      className="w-full py-3 ml-2 font-medium text-gray-900"*/}
        {/*    >*/}
        {/*      🇳🇱*/}
        {/*    </label>*/}
        {/*  </div>*/}
        {/*</li>*/}
      </ul>
    </Fragment>
  );
}
