import React, { Fragment } from "react";

export default function MessageModeSelect({ messageMode, setMessageMode }) {
  return (
    <Fragment>
      <ul className="mr-4 items-center text-xs font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
        <li className="w-full rounded-lg">
          <div className="flex items-center bg-none rounded-lg">
            <select
              className="border-0 rounded-lg text-xs"
              onChange={(e) => {
                setMessageMode(null, e.target.value);
              }}
              value={messageMode}
            >
              <option value="email">Email</option>
              <option value="linkedin">LinkedIn Invite</option>
              <option value="callscript" disabled>
                Call Script
              </option>
            </select>
          </div>
        </li>
      </ul>
    </Fragment>
  );
}
