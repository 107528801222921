import { dynamicsConfig } from "./msalAuthConfig";

/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function callDynamicsAPI(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  // fetch from two different endpoints

  const fetchContacts = fetch(
    dynamicsConfig.dynamicsEndpoint +
      "/contacts?$select=fullname,_parentcustomerid_value,jobtitle,emailaddress1&$expand=parentcustomerid_account($select=name)&$orderby=fullname",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));

  const fetchProducts = fetch(
    dynamicsConfig.dynamicsEndpoint + "/products?$select=name,description",
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));

  // wait for both to finish
  const [contacts, products] = await Promise.all([
    fetchContacts,
    fetchProducts,
  ]);

  console.log({
    contacts: contacts.value,
    products: products.value,
  });

  // change every null value to an empty string in contacts
  contacts.value.forEach((contact) => {
    Object.keys(contact).forEach((key) => {
      if (contact[key] === null) {
        contact[key] = "";
      }
    });
  });

  // combine the results and return them
  return {
    contacts: contacts.value,
    products: products.value,
  };
}
