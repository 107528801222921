import { date } from "zod";

export async function getHubspotContacts() {
  const url = "https://api.compelling.ai/hubspot/HubSpotAPI";
  let after = ""; // For pagination
  let allContacts = [];
  let companyIds = new Set(); // To store unique company IDs

  do {
    const requestBody = {
      token: localStorage.getItem("hubspotAccessToken"),
      endpoint: "/crm/v3/objects/contacts/search",
      method: "POST",
      data: {
        limit: 100,
        filterGroups: [
          {
            filters: [
              {
                propertyName: "hubspot_owner_id",
                operator: "EQ",
                value: localStorage.getItem("hubspotOwnerId"),
              },
            ],
          },
        ],
        properties: [
          "jobtitle",
          "company",
          "company_domain",
          "company_description",
          "company_industry",
          "company_employees",
          "company_linkedin",
          "firstname",
          "lastname",
          "email",
          "hubspot_owner_id",
          "associatedcompanyid",
          "linkedin_url",
          "phone",
        ],
        after: after, // For pagination
      },
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch contacts: " + response.statusText);
    }

    const data = await response.json();
    allContacts = allContacts.concat(data.results); // Accumulate contacts
    allContacts = allContacts.filter((contact) => contact !== undefined); // Remove entry if undefined

    // Update the 'after' value for pagination, if applicable
    after = data.paging ? data.paging.next.after : "";
  } while (after); // Continue fetching while there are more pages

  // After fetching all contacts, extract unique company IDs
  allContacts.forEach((contact) => {
    // Check if the company property is null before adding to the set of company IDs
    if (contact && contact.properties) {
      if (contact.properties.associatedcompanyid) {
        companyIds.add(contact.properties.associatedcompanyid);
      }
    }
  });

  // If there are no company IDs to look up, return early
  if (companyIds.size === 0) {
    console.log("HubSpot Contacts Early:", allContacts);
    return allContacts;
  }

  // Convert Set to Array
  companyIds = Array.from(companyIds);

  // Prepare batch request to fetch company information
  const batchRequestBody = {
    token: localStorage.getItem("hubspotAccessToken"),
    endpoint: "/crm/v3/objects/companies/batch/read",
    method: "POST",
    data: {
      inputs: companyIds.map((id) => ({ id })),
      properties: [
        "domain",
        "name",
        "description",
        "industry",
        "number_of_employees",
        "linkedin_company_page",
      ], // Requesting company name property
    },
  };

  const batchResponse = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(batchRequestBody),
  });

  if (!batchResponse.ok) {
    throw new Error("Failed to fetch companies: " + batchResponse.statusText);
  }

  const companyData = await batchResponse.json();
  const companyDataMap = {}; // Mapping of company IDs to names

  // Extract company names from batch response
  companyData.results.forEach((company) => {
    companyDataMap[company.id] = company.properties;
  });

  // Update the company property of contacts with the company name
  allContacts.forEach((contact) => {
    const companyId = contact.properties.associatedcompanyid;
    if (companyId && companyDataMap[companyId]) {
      contact.properties.company = companyDataMap[companyId].name;
      contact.properties.company_domain = companyDataMap[companyId].domain;
      contact.properties.company_description =
        companyDataMap[companyId].description;
      contact.properties.company_industry = companyDataMap[companyId].industry;
      contact.properties.company_employees =
        companyDataMap[companyId].number_of_employees;
      contact.properties.company_linkedin =
        companyDataMap[companyId].linkedin_company_page;
    }
  });

  console.log("HubSpot Contacts:", allContacts);
  return allContacts;

  // const contacts = await fetch("https://api.compelling.ai/hubspot/HubSpotAPI", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     method: "GET",
  //     token: localStorage.getItem("hubspotAccessToken"),
  //     endpoint:
  //       "/crm/v3/objects/contacts?properties=firstname&properties=lastname&properties=email&properties=jobtitle&properties=company&properties=hubspot_owner_id",
  //   }),
  // });
  // const contactsJson = await contacts.json();
  // console.log("HubSpot Contacts:", contactsJson.results);
  // return contactsJson;
}

export async function updateHubSpotContactProperty(prospect, body, followup) {
  const url = "https://api.compelling.ai/hubspot/HubSpotAPI";
  let compelling_data = {};
  if (followup) {
    compelling_data = {
      properties: {
        compelling_followup: body.trim(),
      },
    };
  } else {
    compelling_data = {
      properties: {
        compelling_outreach: body.trim(),
      },
    };
  }

  const requestBody = {
    token: localStorage.getItem("hubspotAccessToken"),
    endpoint: `/crm/v3/objects/contacts/${prospect.id}`,
    method: "PATCH",
    data: compelling_data,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to update contact: " + response.statusText);
  }

  const data = await response.json();
  return data;
}

export async function createHubSpotSequenceTask(prospect) {
  const url = "https://api.compelling.ai/hubspot/HubSpotAPI";
  console.log("Prospect:", prospect);
  // Prepare the request body with task properties and associations
  const requestBody = {
    token: localStorage.getItem("hubspotAccessToken"),
    endpoint: "/crm/v3/objects/tasks",
    method: "POST",
    data: {
      properties: {
        hs_task_subject: `Add Contact ${
          prospect.firstname + " " + prospect.lastname
        } to a Sequence`,
        hs_task_body: "Please add this contact to a sequence",
        hubspot_owner_id: localStorage.getItem("hubspotOwnerId").toString(),
        hs_task_status: "NOT_STARTED",
        hs_task_type: "TODO",
        hs_timestamp: new Date().getTime(), // Setting the due date to now
      },
      associations: [
        {
          to: {
            id: prospect.id.toString(),
          },
          types: [
            {
              associationCategory: "HUBSPOT_DEFINED",
              associationTypeId: 204,
            },
          ],
        },
      ],
    },
  };

  // Send a POST request to our AWS Lambda proxy to create the task in HubSpot
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to create task: " + response.statusText);
  }

  const data = await response.json();
  return data;
}

export async function getAllHubSpotCompanies() {
  // check if companies are already stored locally
  if (localStorage.getItem("hubSpotAllCompanies")) {
    return JSON.parse(localStorage.getItem("hubSpotAllCompanies"));
  }

  const url = "https://api.compelling.ai/hubspot/HubSpotAPI";
  let after = "0"; // For pagination
  let allCompanies = [];

  do {
    const endpoint = `/crm/v3/objects/companies?properties=name&properties=domain&properties=crm_tech&after=${after}`;
    const requestBody = {
      token: localStorage.getItem("hubspotAccessToken"),
      endpoint: endpoint,
      method: "GET",
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch companies: " + response.statusText);
    }

    const data = await response.json();
    allCompanies = allCompanies.concat(
      data.results.map((company) => ({
        id: company.id,
        name: company.properties.name,
        domain: company.properties.domain,
        crm: company.properties.crm_tech,
      }))
    ); // Transform and accumulate companies

    // Update the 'after' value for pagination, if applicable
    after = data.paging ? data.paging.next.after : "";
    console.log("after:", after);
  } while (after); // Continue fetching while there are more pages

  // Store companies data locally and add timestamp
  const companies = allCompanies.map((company) => {
    return {
      ...company,
      timestamp: new Date().getTime(),
    };
  });
  localStorage.setItem("hubSpotAllCompanies", JSON.stringify(companies));
}

export async function updateHubSpotCompanyProperty(company) {
  const url = "https://api.compelling.ai/hubspot/HubSpotAPI";

  const requestBody = {
    token: localStorage.getItem("hubspotAccessToken"),
    endpoint: `/crm/v3/objects/companies/${company.id}`,
    method: "PATCH",
    data: {
      properties: {
        crm_tech: company.crm,
      },
    },
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok) {
    throw new Error("Failed to update company: " + response.statusText);
  }

  const data = await response.json();
  return data;
}
