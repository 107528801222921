import { Box, Container, Divider } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import Amplify from "@aws-amplify/core";
import { useAuth0 } from "@auth0/auth0-react";

import Editor from "../components/Editor";
import Nav from "../components/Nav";
import Settings from "../components/Settings";
import Login from "../components/Login";
import LanguageSelect from "../components/LanguageSelect";
import MessageModeSelect from "../components/MessageModeSelect";
import * as gen from "../generated";
import {
  fetchPredsV2,
  chatPreds,
  fetchResearch,
  createLinkedinSummary,
} from "../utils/seekwhensAPI";
import FollowUp from "../components/FollowUp";
import { classNames } from "./Main";
import { Dialog, Menu, Transition } from "@headlessui/react";
import CompellingPredictions from "../components/CompellingPredictions";
import {
  updateHubSpotContactProperty,
  createHubSpotSequenceTask,
} from "../hubSpot";

Amplify.configure(gen.config);

let randomToken = require("random-token");
export const token = randomToken(32);

const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      {
        id: "Placeholder",
        type: "paragraph",
        data: {
          text: "Provide a prospect to generate your superhuman outreach 🔮",
        },
      },
    ],
  };
};

export const useLocalStorage = (storageKey, fallbackState) => {
  const storedValue =
    JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState;
  const [value, setValue] = useState(storedValue);

  const setStoredValue = (key, newValue, nestedKey) => {
    console.log("setStoredValue", key, newValue, nestedKey);
    let updatedValue;

    if (
      typeof newValue === "string" ||
      typeof newValue === "number" ||
      typeof newValue === "boolean"
    ) {
      updatedValue = newValue;
    } else if (key === null && typeof newValue === "object") {
      updatedValue = newValue;
    } else {
      if (nestedKey) {
        updatedValue = {
          ...value,
          [key]: {
            ...value[key],
            [nestedKey]: newValue,
          },
        };
      } else {
        updatedValue = { ...value, [key]: newValue };
      }
    }

    setValue(updatedValue);
    localStorage.setItem(storageKey, JSON.stringify(updatedValue));
  };

  return [value, setStoredValue];
};

function Composer() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [loggedIn, setLoggedIn] = useLocalStorage("logged-in-v0.2", false);
  const [noUseBestPractices, setNoUseBestPractices] = useLocalStorage(
    "local-settings-no-best-practices",
    false
  );
  const [localSettings, setLocalSettings] = useLocalStorage(
    "local-settings",
    null
  );

  const [localSettingsJSON, setLocalSettingsJSON] = useLocalStorage(
    "local-settings-json",
    null
  );

  const [messageMode, setMessageMode] = useLocalStorage(
    "local-message-mode",
    "email"
  );

  const [localEmail, setLocalEmail] = useLocalStorage("local-email", null);
  const [localFollowUp, setLocalFollowUp] = useLocalStorage(
    "local-followup",
    null
  );
  const [latestUpdateModal, setLatestUpdateModal] = useLocalStorage(
    "local-v0.2",
    null
  );
  const [rules, setRules] = useLocalStorage("local-rules", "");
  const [templates, setTemplates] = useLocalStorage("local-templates", "");
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage(
    "local-language",
    "EN"
  );
  const [loggedUser, setLoggedUser] = useLocalStorage("local-user", "");

  const [linkedinProfile, setLinkedinProfile] = useLocalStorage(
    "compelling-linkedin-profile",
    ""
  );

  const [compellingUser, setCompellingUser] = useLocalStorage(
    "local-compelling-user",
    { fullName: "Stranger" }
  );

  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [dynamicsAPIData, setDynamicsAPIData] = useState(null);
  const [salesforceAPIData, setSalesforceAPIData] = useState(null);
  const [hubSpotAPIData, setHubSpotAPIData] = useState(null);
  const [resetEditor, setResetEditor] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [preds, setPreds] = useState(false);
  const [apiData, setApiData] = useState({});
  const [editorData, setEditorData] = useState(DEFAULT_INITIAL_DATA);
  const [Prompt, setPrompt] = useState("");
  const [textCopied, setTextCopied] = useState(false);
  const [requiredFields, setRequiredFields] = useState(false);
  const [followUp, setFollowUp] = useState(false);
  const [compellingTags, setCompellingTags] = useState([]);
  const [toneTags, setToneTags] = useState([]);
  const [followUpUI, setFollowUpUI] = useState(false);
  const [followUpCreated, setFollowUpCreated] = useState(false);
  const [searchParams] = useSearchParams();
  const [joinWaitlist, setJoinWaitlist] = useState(
    searchParams.get("ref") === "landing"
  );
  const [asteriskData, setAsteriskData] = useState(null);
  // const [kgCalled, setKgCalled] = useState(false);

  const { accounts } = useMsal();
  const name =
    accounts[0] && accounts[0].name
      ? accounts[0].name
      : localStorage.getItem("hubspotName")
      ? localStorage.getItem("hubspotName")
      : isAuthenticated
      ? user.name
      : "Superhuman Sales Rep";
  const channel = "asterisk-" + token;

  const callAsterisk = (company) => {
    const asteriskQuery = JSON.stringify({
      q: company,
      gl: selectedLanguage.toLowerCase(),
      autocorrect: false,
    });

    // if (!kgCalled) {
    // setKgCalled(true);
    // call seekwhens astersik research api
    fetchResearch(asteriskQuery).then((response) => {
      setAsteriskData(response);
    });
    // // wait 500ms before allowing another call
    // setTimeout(() => {
    //   setKgCalled(false);
    // }, 500);
    // }
  };

  const generateMessage = () => {
    setApiData({});
    setFetching(true);
    setPreds(false);
    setResetEditor(true);
    fetchPredsV2(
      selectedProduct,
      selectedContact,
      selectedLanguage,
      compellingTags,
      toneTags,
      rules,
      templates,
      loggedUser,
      noUseBestPractices,
      linkedinProfile,
      user["https://compelling.ai/claims/client_name"].toLowerCase(),
      messageMode
    ).then(() => {
      console.log("done");
      setFetching(false);
      setPreds(true);
    });
  };

  const editEmail = (followUpPrompt) => {
    setFetching(true);
    setPreds(false);
    setResetEditor(true);
    chatPreds(
      editorData.blocks.map((e) => e.data.text).join("\n\n"),
      Prompt,
      selectedLanguage,
      followUpPrompt
    ).then(() => {
      console.log("edit done");
      setFetching(false);
      setPreds(true);
    });
    setPrompt("");
  };

  const handleSendMessage = () => {
    // if HubSpot, add message to contact and add task
    if (localStorage.getItem("hubspotAccessToken")) {
      let body = editorData.blocks.map((e) => e.data.text).join("\n\n");
      // let subject = "Introducing " + selectedProduct.name;
      console.log("selectedContact", selectedContact);
      let prospect = {
        id: selectedContact.id,
        firstname: selectedContact.fullname.split(" ")[0],
        lastname: selectedContact.fullname.split(" ")[1],
        email: selectedContact.emailaddress1,
      };
      updateHubSpotContactProperty(prospect, body, followUpCreated);
      if (!followUpCreated) {
        createHubSpotSequenceTask(prospect);
      }
    }
  };

  const handleSendEmail = () => {
    window.open(
      // open mail client with body from editor content
      `mailto:${selectedContact.emailaddress1}?subject=Introducing%20${
        selectedProduct.name
      }&body=${encodeURIComponent(
        editorData.blocks.map((e) => e.data.text).join("\n\n")
      )}`
    );
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    editEmail();
  };

  useEffect(() => {
    //Subscribe via WebSockets
    const subscription = gen.subscribe(channel, (data) => setApiData(data));
    return () => subscription.unsubscribe();
  }, [channel]);

  useEffect(() => {
    setSelectedProduct(
      localSettingsJSON &&
        localSettingsJSON.settings &&
        localSettingsJSON.settings.opportunities &&
        localSettingsJSON.settings.opportunities.find(
          (opportunity) => opportunity.selected === true
        )
    );
    if (window.location.href.includes("prospect")) {
      const prospectID = window.location.href.split("prospect=")[1];
      let current_account = localStorage.getItem("current_account");
      let current_list = localStorage.getItem("current_list");
      let prospect = JSON.parse(current_account).Prospects.find(
        (prospect) => prospect._id === prospectID
      );
      if (!localStorage.getItem("@yaireo/tagify/prospect-tone/value")) {
        setToneTags(JSON.parse(current_list).settings.tone);
      } else {
        setToneTags(
          JSON.parse(localStorage.getItem("@yaireo/tagify/prospect-tone/value"))
        );
      }
      setCompellingTags(JSON.parse(current_list).settings.event_tags);
      setLocalSettingsJSON(null, {
        prospect: {
          name: prospect.vorname + " " + prospect.name,
          email: prospect.email,
          companyname: JSON.parse(current_account).Account["Account Name"]
            ? JSON.parse(current_account).Account["Account Name"]
            : JSON.parse(current_account).Account.Anschrift.Firma
            ? JSON.parse(current_account).Account.Anschrift.Firma
            : JSON.parse(current_account).Account.Namen,
          jobtitle: prospect.jobtitel,
          id: prospect.source_id ? prospect.source_id : 0,
          linkedin: prospect.linkedin_url,
          phone: prospect.phone,
        },
        settings: {
          opportunities: [
            {
              name:
                user["https://compelling.ai/claims/client_name"] + " Example",
              description: JSON.parse(current_list).settings.company_desc,
              id: 0,
              selected: true,
              productid: JSON.parse(current_list).list_id,
            },
          ],
        },
      });
      setRequiredFields(true);
    }
  }, []);

  useEffect(() => {
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    if (
      linkedinProfile &&
      linkedinProfile.profileData &&
      linkedinProfile.profileData.recentPosts &&
      !linkedinProfile.profileData.summary
    ) {
      createLinkedinSummary(
        linkedinProfile.profileData.recentPosts,
        linkedinProfile.profileData.profile.fullName
      ).then((summary) => {
        setLinkedinProfile("profileData", {
          ...linkedinProfile.profileData,
          summary: summary.summary,
        });
      });
    }
  }, [linkedinProfile]);

  return (
    <React.Fragment>
      <Transition appear show={latestUpdateModal !== "v0.2.5"} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-stone-100 w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                      <div className="w-full max-w-md">
                        <div>
                          <h2 className="text-center text-3xl tracking-tight text-gray-900">
                            What's new in{" "}
                            <span className="font-bold font-mono">
                              compelling v0.2.5
                            </span>
                          </h2>
                          <ul className="mt-10 space-y-6 tracking-tight text-gray-900 text-left text-sm font-mono overflow-y-scroll">
                            <li className="mt-2">
                              <div className="flex items-center">
                                <small className="mt-0.5 mr-1">
                                  Thursday, Sep 7th, 2023{" "}
                                </small>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                  />
                                </svg>
                              </div>
                              <div
                                className="p-4 text-red-800 border border-red-300 rounded-lg bg-red-50"
                                role="alert"
                              >
                                <div className="flex items-center">
                                  <span className="font-bold mr-4 text-lg">
                                    🎯
                                  </span>
                                  <h4 className="text-sm font-medium">
                                    <span className="font-bold">
                                      Experimental:
                                    </span>{" "}
                                    Prospect research
                                  </h4>
                                </div>
                                <div className="mt-2 text-xs">
                                  By solely providing an account name and your
                                  product description, you can now find the
                                  right prospect to reach out to.{" "}
                                  <span className="">
                                    We advise to use the provided LinkedIn
                                    profile to verify the contact, since this is
                                    still an experimental feature.
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li className="mt-2">
                              <div className="flex items-center">
                                <small className="mt-0.5 mr-1">
                                  Monday, Aug 21st, 2023{" "}
                                </small>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                  />
                                </svg>
                              </div>
                              <div
                                className="p-4 mb-4 text-gray-800 border border-gray-300 rounded-lg bg-gray-50"
                                role="alert"
                              >
                                <div className="flex items-center">
                                  <span className="font-bold mr-4 text-lg">
                                    🔗
                                  </span>
                                  <h4 className="text-sm font-medium">
                                    HubSpot & Salesforce are here
                                  </h4>
                                </div>
                                <div className="mt-2 text-xs">
                                  You are now able to connect both your HubSpot
                                  or Salesforce accounts with compelling.
                                  Allowing you to use all your CRM data to
                                  automate research and message generation, with
                                  more integrations to come.
                                </div>
                              </div>
                            </li>
                            <li className="mt-2">
                              <div className="flex items-center">
                                <small className="mt-0.5 mr-1">
                                  Wednesday, June 28th, 2023{" "}
                                </small>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                  />
                                </svg>
                              </div>
                              <div
                                className="p-4 mb-4 text-green-800 border border-green-300 rounded-lg bg-green-50"
                                role="alert"
                              >
                                <div className="flex items-center">
                                  <span className="font-bold mr-4 text-lg">
                                    🧪
                                  </span>
                                  <h4 className="text-sm font-medium">
                                    Introducing Research Tabs
                                  </h4>
                                </div>
                                <div className="mt-2 text-xs">
                                  With Research Tabs you now get additional info
                                  on your accounts and contacts. In this first
                                  iteration we are adding general info and
                                  technology stack, but we are already working
                                  on the next iteration.
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-10">
                          <button
                            type="button"
                            className="group relative flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => {
                              setLatestUpdateModal(null, "v0.2.5");
                            }}
                          >
                            Let's go!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Nav compellingUser={compellingUser} />
      <Box py={"32px"}></Box>
      <Container className="min-h-100vh max-w-8xl">
        <div className="flex flex-row relative">
          <div className="basis-1/3 p-10">
            <Settings
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              dynamicsAPIData={dynamicsAPIData}
              setDynamicsAPIData={setDynamicsAPIData}
              localSettings={localSettings}
              setLocalSettings={setLocalSettings}
              setRequiredFields={setRequiredFields}
              setCompellingTags={setCompellingTags}
              compellingTags={compellingTags}
              setToneTags={setToneTags}
              toneTags={toneTags}
              rules={rules}
              setRules={setRules}
              templates={templates}
              setTemplates={setTemplates}
              noUseBestPractices={noUseBestPractices}
              setNoUseBestPractices={setNoUseBestPractices}
              localSettingsJSON={localSettingsJSON}
              setLocalSettingsJSON={setLocalSettingsJSON}
              callAsterisk={callAsterisk}
              salesforceAPIData={salesforceAPIData}
              setSalesforceAPIData={setSalesforceAPIData}
              hubSpotAPIData={hubSpotAPIData}
              setHubSpotAPIData={setHubSpotAPIData}
            />
          </div>
          <div className="basis-2/3 mb-auto p-10 sticky top-16">
            <div className="mb-3 flex flex-row">
              <LanguageSelect
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
              <MessageModeSelect
                messageMode={messageMode}
                setMessageMode={setMessageMode}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <FollowUp
                followUp={followUp}
                setFollowUp={setFollowUp}
                preds={preds}
                editEmail={editEmail}
                setEditorData={setEditorData}
                localEmail={localEmail}
                localFollowUp={localFollowUp}
                setFollowUpUI={setFollowUpUI}
                followUpCreated={followUpCreated}
                setFollowUpCreated={setFollowUpCreated}
              />
            </div>
            <div
              className={classNames(
                !preds
                  ? "from-compelling-bright"
                  : !followUp
                  ? "from-green-400"
                  : "from-amber-300",
                "bg-gradient-to-b rounded-md px-1 pt-1"
              )}
            >
              <div className="bg-stone-100 text-stone-800 border-r-4 border-b-4 border-stone-200 border-opacity-50 rounded-md shadow-xl min-h-80 font-mono text-sm">
                <Editor
                  resetEditor={resetEditor}
                  setResetEditor={setResetEditor}
                  name={name}
                  apiData={apiData}
                  editorData={editorData}
                  setEditorData={setEditorData}
                  textCopied={textCopied}
                  followUp={followUp}
                  followUpUI={followUpUI}
                  setFollowUpUI={setFollowUpUI}
                  setLocalEmail={setLocalEmail}
                  setLocalFollowUp={setLocalFollowUp}
                  preds={preds}
                  fetching={fetching}
                />
              </div>
            </div>
            <CompellingPredictions
              apiData={apiData}
              preds={preds}
              fetching={fetching}
              asteriskData={asteriskData}
              selectedContact={selectedContact}
              linkedinProfile={linkedinProfile}
              setLinkedinProfile={setLinkedinProfile}
            ></CompellingPredictions>
            <div className="py-3 -z-10 mx-2">
              <button
                onClick={() => {
                  generateMessage(followUp);
                }}
                disabled={fetching || !requiredFields}
                type="Button"
                className="disabled:bg-neutral-200 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {fetching ? (
                  <div role="status" className="items-center">
                    <div className="inline mr-2">Thinking</div>
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 inline text-white/50 animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : (
                  <div>
                    <span>Generate </span>
                    <span
                      className={fetching || !requiredFields ? "grayscale" : ""}
                    >
                      ✨
                    </span>
                  </div>
                )}
              </button>
              <div className="float-right">
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      editorData.blocks.map((e) => e.data.text).join("\n\n")
                    );
                    setTextCopied(true);
                    setTimeout(() => {
                      setTextCopied(false);
                    }, 2000);
                  }}
                  disabled={!preds}
                  type="Button"
                  className="inline-flex justify-center rounded-md py-2 px-4 text-indigo-600 text-sm font-medium disabled:text-neutral-200"
                >
                  Copy
                </button>
                <button
                  onClick={() => {
                    handleSendEmail();
                  }}
                  disabled={!preds}
                  type="Button"
                  className="inline-flex justify-center rounded-md border border-indigo-600 ml-2 py-2 px-4 text-indigo-600 text-sm font-medium shadow-sm hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-white disabled:border-neutral-200 disabled:text-neutral-200"
                >
                  Send via Email
                </button>
                <button
                  onClick={() => {
                    handleSendMessage();
                  }}
                  disabled={!preds}
                  type="Button"
                  className="disabled:bg-neutral-200 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 ml-2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {localStorage.getItem("hubspotAccessToken")
                    ? "Send to HubSpot"
                    : "Send to CRM"}
                </button>
              </div>
              {/* A prompt input window the user can write prompts in*/}
              <form
                className={classNames(
                  preds ? "opacity-100" : "opacity-50",
                  "mt-8"
                )}
                onSubmit={onFormSubmit}
              >
                <label
                  htmlFor="chat"
                  className="mb-2 text-sm font-medium text-gray-900 sr-only"
                >
                  Talk to your message
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-xl grayscale">
                    🤖
                  </div>
                  <input
                    disabled={!preds}
                    type="text"
                    id="chat"
                    className="block w-full p-4 pl-10 pr-20 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:shadow-2xl focus:shadow-compelling-bright focus:border-indigo-500 focus:ring-0"
                    placeholder="Talk to your message"
                    required
                    value={Prompt}
                    onChange={(e) => {
                      setPrompt(e.target.value);
                    }}
                  />
                  <button
                    disabled={!preds}
                    type="submit"
                    className="disabled:bg-neutral-300 text-white absolute right-2 bottom-2 bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-md text-sm px-4 py-2"
                  >
                    {fetching ? (
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 inline text-white/50 animate-spin fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 26"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          transform="rotate(-35,12,12)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </form>
              {/*<div className="mt-8">*/}
              {/*  <label*/}
              {/*    htmlFor="prompt"*/}
              {/*    className={classNames(*/}
              {/*      preds ? "text-gray-700" : "text-neutral-200",*/}
              {/*      "block text-xs font-medium"*/}
              {/*    )}*/}
              {/*  >*/}
              {/*    Talk to your Email*/}
              {/*  </label>*/}
              {/*  <div className="mt-1">*/}
              {/*    <input*/}
              {/*      disabled={!preds}*/}
              {/*      type="text"*/}
              {/*      name="prompt"*/}
              {/*      id="prompt"*/}
              {/*      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 disabled:border-gray-100 rounded-md"*/}
              {/*      value={prompt}*/}
              {/*      // onChange={(e) => setPrompt(e.target.value)}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Composer;
