import React, { Fragment, useEffect, useState } from "react";
import Nav from "../components/Nav";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Container,
  createTheme,
  Divider,
  InputLabel,
  Slider,
  ThemeProvider,
  FormControl,
  Select,
} from "@mui/material";
import Fuse from "fuse.js";
import { Dialog, Transition } from "@headlessui/react";
import Papa from "papaparse";
import Tooltip from "@mui/material/Tooltip";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

import { fetchPredsV2, generateSequence } from "../utils/seekwhensAPI";
import { token, useLocalStorage } from "./Composer";
import * as gen from "../generated";
import { nameRegex } from "../components/Editor";
import { CompellingTextField, CompellingSelect } from "../components/Settings";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { classNames } from "../pages/Main";
import { getHubspotContacts } from "../hubSpot";
import {
  createPipedriveOrganization,
  createPipedrivePerson,
  logPipedriveActivity,
  fetchPipedrivePersonByEmail,
  fetchPipedriveOrganizationByName,
} from "../pipedrive";

const Hub = () => {
  const searchOptions = {
    shouldSort: false,
    threshold: 0.1,
    keys: [
      "Account.Namen",
      "Account.Anschrift.Firma",
      "Account.Anschrift['Account Name']",
      "Prospects.name",
      "Prospects.vorname",
      "Prospects.jobtitel",
    ],
  };

  const compellingTheme = createTheme({
    palette: {
      primary: {
        light: "#8888ff",
        main: "#4F46E5",
        dark: "#4338ca",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ecf096",
        main: "#dde546",
        dark: "#c9ce10",
        contrastText: "#000",
      },
    },
  });

  const [linkedinProfile, setLinkedinProfile] = useLocalStorage(
    "compelling-linkedin-profile",
    ""
  );

  const [noUseBestPractices, setNoUseBestPractices] = useLocalStorage(
    "local-settings-no-best-practices",
    false
  );

  const [localSettingsJSON, setLocalSettingsJSON] = useLocalStorage(
    "local-settings-json",
    null
  );

  const [currentProspects, setCurrentProspects] = useLocalStorage(
    "local-current-prospects",
    []
  );

  const [compellingUser, setCompellingUser] = useLocalStorage(
    "local-compelling-user",
    { fullName: "Stranger" }
  );

  const [selectedList, setSelectedList] = useLocalStorage(
    "local-selected-list",
    null
  );

  const [rules, setRules] = useLocalStorage("local-rules", "");

  const [loggedUser, setLoggedUser] = useLocalStorage("local-user", "");

  const [connected, setConnected] = useLocalStorage("connectedAccounts", {
    microsoft: false,
    salesforce: false,
    hubspot: false,
    pipedrive: false,
    linkedin: false,
    outreach: false,
  });

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);

  const name = user ? user.name : "Stranger";

  const [searchFuse, setSearchFuse] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSetting, setFilterSetting] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(null);
  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const [fullCount, setFullCount] = useState(0);
  const [availableContacts, setAvailableContacts] = useState([]);
  const [researchCount, setResearchCount] = useState(0);
  const [mainCount, setMainCount] = useState(0);
  const [infoFoundCount, setInfoFoundCount] = useState(0);
  const [averageScore, setAverageScore] = useState(0.0);
  const [selectionSlider, setSelectionSlider] = useState(0);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [numberOfSelected, setNumberOfSelected] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState(
    user["https://compelling.ai/claims/client_name"]
  );
  const [researchMode, setResearchMode] = useState(false);
  const [checkedAccounts, setCheckedAccounts] = useState(null);
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);
  const [activeMessageId, setActiveMessageId] = useState(null);
  const [messageCharCount, setMessageCharCount] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [apiData, setApiData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [fetchingAccounts, setFetchingAccounts] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [compellingTags, setCompellingTags] = useState([]);
  const [toneTags, setToneTags] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedSource, setSelectedSource] = useState("");
  const [hubSpotAPIData, setHubSpotAPIData] = useState(null);
  const [fieldSelectionModal, setFieldSelectionModal] = useState(false);
  const [personFields, setPersonFields] = useState([]);
  const [organizationFields, setOrganizationFields] = useState([]);
  const [gettingProspect, setGettingProspect] = useState(false);
  const [currentProspectLanguage, setCurrentProspectLanguage] = useState("EN");
  const [sequenceLoading, setSequenceLoading] = useState(false);
  const [hideFilters, setHideFilters] = useState(true);

  const channel = "asterisk-" + token;

  // Pagination states
  const [page, setPage] = useState(1);
  const [pageSize] = useState(100);
  const [hasMore, setHasMore] = useState(true);

  const handleSync = () => {
    if (selectedSource === "hubspot") {
      async function handleHubSpotFetch() {
        const data = await getHubspotContacts();
        setHubSpotAPIData(data);
        syncData(data);
      }

      handleHubSpotFetch();
    } else if (selectedSource === "pipedrive") {
      setFieldSelectionModal(true);
      setPersonFields(
        JSON.parse(localStorage.getItem("pipedrivePersonFields")).data
      );
      setOrganizationFields(
        localStorage.getItem("pipedriveOrganizationFields")
      );
    }
  };

  const handleAddToSequence = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSequenceLoading(true);
    let prospectIDs = [];
    selectedAccounts.forEach((account) => {
      account.Prospects.forEach((prospect) => {
        prospectIDs.push(prospect["_id"]);
      });
    });
    let sequenceResult = await generateSequence(prospectIDs, 4, 1);
    setSequenceLoading(false);
    console.log(sequenceResult);
  };

  const newMessage = (event, prospect, account) => {
    event.preventDefault();
    event.stopPropagation();
    setGettingProspect(true);
    fetch("https://api.compelling.ai/linkedin/LinkedInAPI", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: "getProfile",
        identifier: prospect.linkedin_url
          .split("/in/")[1]
          .replace(/\/\s*$/, ""),
        accound_id: JSON.parse(localStorage.getItem("local-user"))[
          "https://compelling.ai/claims/user_metadata"
        ]["linkedinAuthId"],
      }),
    }).then((response) => {
      if (!response.ok) {
        throw new Error("HTTP error " + response.status);
      } else {
        response.json().then((data) => {
          console.log(data);
          setCurrentProspectLanguage(
            data.primary_locale.language === "de" ? "DE" : "EN"
          );
          const current_list = localStorage.getItem("current_list");
          setLocalSettingsJSON(null, {
            prospect: {
              name: prospect.vorname + " " + prospect.name,
              email: prospect.email,
              companyname: account.Account["Account Name"]
                ? account.Account["Account Name"]
                : account.Account.Anschrift.Firma
                ? account.Account.Anschrift.Firma
                : account.Account.Namen,
              jobtitle: prospect.jobtitel,
              linkedin: prospect.linkedin_url,
              phone: prospect.phone,
              language: data.primary_locale.language === "de" ? "DE" : "EN",
            },
            settings: {
              opportunities: [
                {
                  name:
                    user["https://compelling.ai/claims/client_name"] +
                    " Example",
                  description: JSON.parse(current_list).settings.company_desc,
                  id: 0,
                  selected: true,
                  productid: JSON.parse(current_list).list_id,
                },
              ],
            },
          });
          generateMessage(
            prospect,
            account,
            "linkedin",
            data.primary_locale.language === "de" ? "DE" : "EN"
          );
          setActiveMessageId(prospect["_id"]);
          setGettingProspect(false);
        });
      }
    });
  };

  const generateMessage = (prospect, account, mode, language) => {
    setApiData({});
    setMessage("");
    setFetching(true);
    fetchPredsV2(
      selectedProduct,
      {
        fullname: prospect.vorname + " " + prospect.name,
        emailaddress1: prospect.email,
        parentcustomerid_account: {
          name: account.Account["Account Name"]
            ? account.Account["Account Name"]
            : account.Account.Anschrift.Firma
            ? account.Account.Anschrift.Firma
            : account.Account.Namen,
        },
        jobtitle: prospect.jobtitel,
        id: prospect["_id"],
        linkedin: prospect.linkedin_url,
        phone: prospect.phone,
      },
      language,
      compellingTags,
      toneTags,
      rules,
      "",
      loggedUser,
      noUseBestPractices,
      linkedinProfile,
      user["https://compelling.ai/claims/client_name"].toLowerCase(),
      mode
    ).then(() => {
      console.log("done");
      setFetching(false);
    });
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSliderChange = (event) => {
    setSelectionSlider(event.target.value);
    setSelectedAccounts(
      availableContacts.filter(
        (contact) => contact.Research.rating >= 100 - event.target.value
      )
    );
    setNumberOfSelected(selectedAccounts.length);
  };

  function parseUrls(urlsString) {
    const urlsArray = urlsString.match(/\[\d+\]\s*https?:\/\/[^\s\|]*/g) || [];

    urlsArray.forEach((entry, index) => {
      if (entry[entry.length - 1] === ",") {
        urlsArray[index] = entry.slice(0, -1);
      }
    });

    return urlsArray
      .map((url) => {
        const fullUrlMatch = url.match(/https?:\/\/[^\s\|]*/g);
        if (!fullUrlMatch) {
          return null;
        }
        const fullUrl = fullUrlMatch[0];
        const baseUrlMatch = fullUrl.match(/:\/\/(?:www\.)?([^/]+)/);
        if (!baseUrlMatch) {
          return null;
        }
        const baseUrl = baseUrlMatch[1];
        const domainParts = baseUrl.split(".");
        const name = domainParts[domainParts.length - 2];
        const base = `${name}.${domainParts[domainParts.length - 1]}`;
        return { fullUrl, baseUrl: base, name };
      })
      .filter((entry) => entry !== null);
  }

  const selectionSliderMarks = [
    {
      value: 0,
      label: "None",
    },
    {
      value: 25,
      label: "75%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 75,
      label: "25%",
    },
    {
      value: 100,
      label: "All",
    },
  ];

  const getUserMetadata = async (pageToLoad = 1) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://auth0-jwt-authorizer`,
        },
      });

      const accountMetricsResponse = await fetch(
        "https://api.compelling.ai/data/MongoAPI",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            action: "metrics",
            client_name: selectedAccount,
            list_id: selectedList ? selectedList["list_id"] : null,
          }),
        }
      );

      const accountMetrics = await accountMetricsResponse.json();
      console.log(accountMetrics);

      setFullCount(accountMetrics["fullCount"]);
      setResearchCount(accountMetrics["researchedCount"]);
      setInfoFoundCount(accountMetrics["infoFoundCount"]);
      setAverageScore(accountMetrics["averageScore"]);

      const accountDataResponse = await fetch(
        "https://api.compelling.ai/data/MongoAPI",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            action: "load",
            client_name: selectedAccount,
            list_id: selectedList ? selectedList["list_id"] : null,
            page: pageToLoad,
            pageSize: pageSize,
          }),
        }
      );

      const receivedData = await accountDataResponse.json();

      console.log(receivedData);

      setCurrentProspects((prevProspects) => [
        ...prevProspects,
        ...receivedData["prospects"],
      ]);

      let initialList = receivedData["leads"];
      let trackingList = initialList.map((lead) => {
        let prospects = receivedData["prospects"].filter(
          (prospect) => prospect.account_reference === lead._id
        );
        return {
          ...lead,
          Prospects: prospects,
        };
      });

      let user_metadata = {
        tracking_list: trackingList,
        list_info: user["https://compelling.ai/claims/list_ids"]
          ? receivedData["list_info"].filter((list) =>
              user["https://compelling.ai/claims/list_ids"].includes(
                list["list_id"]
              )
            )
          : receivedData["list_info"],
      };

      setUserMetadata((prevMetadata) => ({
        ...prevMetadata,
        tracking_list: [
          ...(prevMetadata?.tracking_list || []),
          ...trackingList,
        ],
        list_info: user_metadata.list_info,
      }));

      if (!selectedList || Object.keys(selectedList).length === 0) {
        setSelectedList(null, user_metadata["list_info"][0]);
        setCompellingTags(user_metadata["list_info"][0].settings.event_tags);
        console.log("Compelling Tags", compellingTags);
      } else {
        setCompellingTags(selectedList.settings.event_tags);
      }

      console.log(user_metadata);

      if (receivedData["leads"].length < pageSize) {
        setHasMore(false);
        setFetchingAccounts(false);
      } else {
        setPage(pageToLoad);
        setFetchingAccounts(false);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const syncData = async (data) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: `https://auth0-jwt-authorizer`,
        },
      });

      await fetch("https://api.compelling.ai/data/MongoAPI", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: "sync",
          client_name: selectedAccount,
          list_id: selectedList ? selectedList["list_id"] : null,
          data: data,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          console.log("Success:", response);
          getUserMetadata();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateMetadata = async (metadata) => {
    const userId = user.sub;
    const apiUrl = "https://api.compelling.ai/data/user";
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: `https://auth0-jwt-authorizer`,
      },
    });

    console.log("Metadata", metadata);

    const { userMetadata, appMetadata } = metadata;
    let body = {};

    if (appMetadata) {
      body = {
        userId,
        appMetadata,
      };
    } else {
      body = {
        userId,
        userMetadata,
      };
    }

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const calculateAverageScore = (trackingList) => {
  //   const scores = trackingList
  //     .map((contact) => {
  //       if (
  //         contact.item.Research &&
  //         contact.item.Research.rating &&
  //         contact.item.Research.rating !== 1 &&
  //         contact.item.Research.rating !== 0
  //       ) {
  //         return contact.item.Research.rating;
  //       } else {
  //         return 0;
  //       }
  //     })
  //     .filter((score) => score !== null && score !== 0 && score !== 1);
  //   const above75 = trackingList
  //     .map((contact) => {
  //       if (
  //         contact.item.Research &&
  //         contact.item.Research.rating &&
  //         contact.item.Research.rating !== 1 &&
  //         contact.item.Research.rating !== 0 &&
  //         contact.item.Research.rating >= 75
  //       ) {
  //         return contact.item;
  //       } else {
  //         return 0;
  //       }
  //     })
  //     .filter((score) => score !== null && score !== 0 && score !== 1);

  // TODO: This needs to done for the slider to work
  //   const researched = trackingList
  //     .map((contact) => {
  //       if (
  //         contact.item.Research &&
  //         contact.item.Research.rating &&
  //         contact.item.Research.rating !== 0
  //       ) {
  //         return contact.item;
  //       } else {
  //         return null;
  //       }
  //     })
  //     .filter(
  //       (contact) => contact !== null && contact.Research.rating !== undefined
  //     );

  //   console.log("Full Count", trackingList.length);
  //   console.log("Researched Count", researched.length);
  //   console.log("Info found Count", scores.length);
  //   console.log("Scores 75 and above", above75);
  //   const sum = scores.reduce((a, b) => a + b, 0);
  //   const avg = sum / scores.length || 0;
  //   setFullCount(trackingList.length);
  //   setAvailableContacts(researched);
  //   console.log("Researched", researched);
  //   setResearchCount(researched.length);
  //   setInfoFoundCount(scores.length);
  //   setAverageScore(avg.toPrecision(3));
  // };

  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        const newResults = results.data
          .map((contact) => ({
            account: contact["Account Name"],
            prospect: contact["Prospect Name"],
            job_title: contact["Job Title"],
          }))
          .slice(0, 10);
      },
    });
  };

  React.useEffect(() => {
    console.log("connected", connected);
  }, [connected]);

  React.useEffect(() => {
    const pipedriveExpiresAt = localStorage.getItem("pipedriveExpiresAt");
    const hubspotExpiresAt = localStorage.getItem("hubspotExpiresAt");

    let currentConnected = connected;

    if (
      pipedriveExpiresAt &&
      parseInt(pipedriveExpiresAt) > Date.now() / 1000
    ) {
      setConnected(null, { ...currentConnected, pipedrive: true });
      currentConnected.pipedrive = true;
    } else {
      setConnected(null, { ...currentConnected, pipedrive: false });
      currentConnected.pipedrive = false;
    }

    if (hubspotExpiresAt && parseInt(hubspotExpiresAt) > Date.now() / 1000) {
      setConnected(null, { ...connected, hubspot: true });
      currentConnected.hubspot = true;
    } else {
      setConnected(null, { ...connected, hubspot: false });
      currentConnected.hubspot = false;
    }

    if (
      localStorage.getItem("local-user") &&
      JSON.parse(localStorage.getItem("local-user"))[
        "https://compelling.ai/claims/user_metadata"
      ] &&
      JSON.parse(localStorage.getItem("local-user"))[
        "https://compelling.ai/claims/user_metadata"
      ]["linkedinAuthId"]
    ) {
      setConnected(null, { ...connected, linkedin: true });
      currentConnected.linkedin = true;
    } else {
      setConnected(null, { ...connected, linkedin: false });
      currentConnected.linkedin = false;
    }
  }, []);

  useEffect(() => {
    setSelectedProduct(
      localSettingsJSON &&
        localSettingsJSON.settings &&
        localSettingsJSON.settings.opportunities &&
        localSettingsJSON.settings.opportunities.find(
          (opportunity) => opportunity.selected === true
        )
    );
  }, []);

  useEffect(() => {
    const subscription = gen.subscribe(channel, (data) => setApiData(data));
    return () => subscription.unsubscribe();
  }, [channel]);

  useEffect(() => {
    console.log("apiData", apiData);
    if (apiData.data && JSON.parse(apiData.data).prediction) {
      setMessage(
        message +
          JSON.parse(apiData.data).prediction.replace(
            nameRegex,
            { name }.name.split(" ")[0]
          )
      );
    }
  }, [apiData]);

  useEffect(() => {
    setMessageCharCount(message.length);
  }, [message]);

  useEffect(() => {
    console.log("user", user);
    setLoggedUser(null, user);
    if (user["https://compelling.ai/claims/user_metadata"]) {
      let user_settings = user["https://compelling.ai/claims/user_metadata"];
      setUserMetadata({
        ...userMetadata,
        user_settings,
      });
      setCheckedAccounts(user_settings["checked_accounts"] || []);
    }
    setCompellingUser(null, {
      fullName: user.name,
      email: user.email,
      userId: user.sub,
      listIds: user["https://compelling.ai/claims/list_ids"],
      campaignIds: user["https://compelling.ai/claims/campaign_ids"],
      subscription: user["https://compelling.ai/claims/subscription"],
      userMetadata: user["https://compelling.ai/claims/user_metadata"],
    });
  }, [user]);

  useEffect(() => {
    if (checkedAccounts !== null) {
      updateMetadata({ userMetadata: { checked_accounts: checkedAccounts } });
    }
  }, [checkedAccounts]);

  useEffect(() => {
    console.log("GETTING");
    getUserMetadata();
  }, [getAccessTokenSilently, selectedAccount, selectedList]);

  const [updateInterval, setUpdateInterval] = useState(null);
  useEffect(() => {
    if (
      userMetadata &&
      userMetadata["tracking_list"] &&
      userMetadata["tracking_list"].length !== 0
    ) {
      userMetadata["tracking_list"] = userMetadata["tracking_list"].map(
        (contact) => {
          if (!contact.Research) {
            return {
              ...contact,
              Research: {
                rating: null,
              },
            };
          } else {
            return contact;
          }
        }
      );
      const interval = setInterval(() => {
        if (
          userMetadata &&
          userMetadata["tracking_list"] &&
          userMetadata["tracking_list"].length !== 0
        ) {
          const zeroRatings = userMetadata["tracking_list"].filter(
            (contact) => contact.Research.rating === 0
          );
          if (zeroRatings.length !== 0) {
            getUserMetadata();
          } else {
            clearInterval(interval);
          }
        }
      }, 120000); // 2 minutes
      setUpdateInterval(interval);
    }
  }, [userMetadata]);

  useEffect(() => {
    if (userMetadata && userMetadata["tracking_list"]) {
      const fullContacts = userMetadata["tracking_list"]
        .filter((contact) => contact.Research.rating !== undefined)
        .map((contact) => ({
          item: Object.assign(contact, {}),
          matches: [],
          score: 1,
        }))
        .sort((a, b) => b.item.Research.rating - a.item.Research.rating);

      if (userMetadata["list_info"].length === 0) {
        setSearchResults([]);
      } else {
        setSearchResults(fullContacts);
      }

      const fuse = new Fuse(userMetadata["tracking_list"], searchOptions);
      setSearchFuse(fuse);
      // calculateAverageScore(fullContacts);
    }
  }, [userMetadata]);

  useEffect(() => {
    if (selectedList && selectedList["settings"]) {
      if (selectedList["settings"]["event_tags"].length !== 0) {
        setMainCount(fullCount);
        setResearchMode(false);
      } else {
        setMainCount(fullCount);
        setResearchMode(false);
      }
    }
    if (window.location.hostname === "staging.compelling.ai") {
      setResearchMode(true);
    }
  }, [fullCount]);

  useEffect(() => {
    if (
      searchFuse &&
      userMetadata &&
      userMetadata["tracking_list"] &&
      userMetadata["tracking_list"] !== []
    ) {
      if (searchTerm !== "") {
        const results = searchFuse.search(searchTerm);
        setSearchResults(results);
      } else {
        const fullContacts = userMetadata["tracking_list"]
          .map((contact) => ({
            item: Object.assign(contact, {}),
            matches: [],
            score: 1,
          }))
          .sort((a, b) => b.item.Research.rating - a.item.Research.rating);
        setSearchResults(fullContacts);
      }
    }
  }, [searchTerm]);

  function filterByCampaignStatus(data, campaignId, status) {
    return data.filter((item) => {
      if (item.Campaigns && item.Campaigns[campaignId]) {
        return item.Campaigns[campaignId].status === status;
      }
      return false;
    });
  }

  useEffect(() => {
    if (
      userMetadata &&
      userMetadata["tracking_list"] &&
      userMetadata["tracking_list"].length > 0
    ) {
      if (filterSetting !== "") {
        const testID = "55a95eae-539e-4183-b004-b9c356995dad";
        const results = filterByCampaignStatus(
          userMetadata["tracking_list"],
          testID,
          filterSetting
        );
        console.log(results);
        setSearchResults(
          results
            .map((contact) => ({
              item: Object.assign(contact, {}),
              matches: [],
              score: 1,
            }))
            .sort((a, b) => b.item.Research.rating - a.item.Research.rating)
        );
      } else {
        const fullContacts = userMetadata["tracking_list"]
          .map((contact) => ({
            item: Object.assign(contact, {}),
            matches: [],
            score: 1,
          }))
          .sort((a, b) => b.item.Research.rating - a.item.Research.rating);
        setSearchResults(fullContacts);
      }
    }
  }, [filterSetting]);

  useEffect(() => {
    if (searchResults) {
    }
  }, [searchResults]);

  function lerpColor(a, b, amount) {
    const red = a[0] + (b[0] - a[0]) * amount;
    const green = a[1] + (b[1] - a[1]) * amount;
    const blue = a[2] + (b[2] - a[2]) * amount;
    let alpha = 1;

    return `rgba(${Math.round(red)}, ${Math.round(green)}, ${Math.round(
      blue
    )}, ${alpha})`;
  }

  const startColor = [243, 244, 246];
  const endColor = [79, 70, 229];

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight &&
      hasMore
    ) {
      setFetchingAccounts(true);
      getUserMetadata(page + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  return (
    <React.Fragment>
      <Transition appear show={showCsvUpload} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setShowCsvUpload(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-stone-100 w-full max-w-xl transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md">
                      <div className="flex items-center justify-center w-full">
                        <label
                          htmlFor="dropzone-file"
                          className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-8 h-8 text-gray-500 mb-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                              />
                            </svg>
                            <p className="mb-4 text-sm text-gray-500">
                              <span className="font-semibold">
                                Click to import
                              </span>{" "}
                              your CRM data from a CSV file.
                            </p>
                            <div
                              className="flex items-center p-4 mx-8 text-xs text-gray-500 border border-gray-300 rounded-lg bg-gray-50"
                              role="alert"
                            >
                              <svg
                                className="flex-shrink-0 inline w-4 h-4 mr-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                              </svg>
                              <span className="sr-only">Info</span>
                              <div>
                                <span className="font-medium">
                                  Please import a CSV file
                                </span>{" "}
                                that only contains exactly these columns with
                                column names and order matching:{" "}
                                <div className="mt-2 font-mono p-2 rounded-md bg-stone-100 text-center">
                                  Account Name, Prospect Name, Job Title
                                </div>
                              </div>
                            </div>
                            <p className="text-xs text-gray-500"></p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            accept=".csv"
                            className="hidden"
                            onChange={handleCsvFileChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Nav compellingUser={compellingUser} />
      <Box py={"64px"}></Box>
      <Container className="min-h-100vh">
        <div className="flex-col flex items-center justify-center p-10">
          {isAuthenticated && userMetadata && userMetadata["tracking_list"] ? (
            <>
              <div
                className={classNames(
                  showSettings &&
                    "bg-gradient-to-b from-transparent from-35% to-gray-200",
                  "flex-row w-full max-w-xl border-b border-gray-300 mb-2 rounded-b-md"
                )}
              >
                <div className="flex items-center justify-between mb-1">
                  {user["https://compelling.ai/claims/business_id"] ===
                    "65ae9275648bed88ae8f82b7" && (
                    <select
                      id="account-select"
                      name="account-select"
                      value={selectedAccount}
                      onChange={(e) => setSelectedAccount(e.target.value)}
                      className="mr-4 mb-4 rounded-md text-xs bg-transparent border border-stone-200 py-2 pl-3 pr-7 focus:ring-0 focus:border-indigo-600 focus:outline-none focus:ring-indigo-600"
                    >
                      <option value="oppy">Oppy Examples</option>
                      <option value="evolut">Evolut</option>
                      <option value="traffic">Traffic</option>
                      <option value="okta">Okta</option>
                      <option value="intalento">Intalento</option>
                    </select>
                  )}
                  <select
                    id="list-select"
                    name="list-select"
                    value={selectedList ? selectedList["list_id"] : ""}
                    onChange={(e) => {
                      setSelectedList(
                        null,
                        userMetadata["list_info"].find(
                          (list) => list["list_id"] === e.target.value
                        )
                      );
                    }}
                    className="mr-auto ml-1 rounded-md text-xs hover:bg-stone-200 bg-transparent border-none pl-2 py-2 pr-7 focus:ring-0 focus:border-indigo-600 focus:outline-none focus:ring-indigo-600 cursor-pointer"
                  >
                    {userMetadata["list_info"].map((list) => (
                      <option value={list["list_id"]} key={list["list_id"]}>
                        {list["list_name"]}
                      </option>
                    ))}
                  </select>
                  <button
                    className="rounded-md text-xs hover:bg-stone-200 py-2 px-3  focus:ring-0 focus:border-indigo-600 focus:outline-none focus:ring-indigo-600 mr-1"
                    onClick={() => setShowSettings(!showSettings)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 h-4"
                    >
                      <path d="M10 3.75a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM17.25 4.5a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM5 3.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1 0-1.5h1.5a.75.75 0 0 1 .75.75ZM4.25 17a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM17.25 17a.75.75 0 0 0 0-1.5h-5.5a.75.75 0 0 0 0 1.5h5.5ZM9 10a.75.75 0 0 1-.75.75h-5.5a.75.75 0 0 1 0-1.5h5.5A.75.75 0 0 1 9 10ZM17.25 10.75a.75.75 0 0 0 0-1.5h-1.5a.75.75 0 0 0 0 1.5h1.5ZM14 10a2 2 0 1 0-4 0 2 2 0 0 0 4 0ZM10 16.25a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                    </svg>
                  </button>
                </div>
                {showSettings && (
                  <div className="flex-row py-2 px-2">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="rounded-md">
                        <CompellingTextField
                          id="prospect-name"
                          size="small"
                          label="List Name"
                          name="prospect-name"
                          variant="outlined"
                          fullWidth
                          value={selectedList ? selectedList["list_name"] : ""}
                          onChange={(e) => {
                            setSelectedList(null, {
                              ...selectedList,
                              list_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="flex">
                        <select
                          id="opportunity-select"
                          name="opportunity-select"
                          autoComplete="opportunity"
                          value={selectedSource}
                          onChange={(e) => setSelectedSource(e.target.value)}
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-7 focus:ring-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm truncate mr-2"
                        >
                          <option value="">Select a source</option>
                          {connected.hubspot && (
                            <option value="hubspot">HubSpot</option>
                          )}
                          {connected.salesforce && (
                            <option value="salesforce">Salesforce</option>
                          )}
                          {connected.pipedrive && (
                            <option value="pipedrive">Pipedrive</option>
                          )}
                        </select>
                        <button
                          className="mr-1 text-indigo-600 hover:text-compelling-bright"
                          onClick={handleSync}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.5 17a4.5 4.5 0 0 1-1.44-8.765 4.5 4.5 0 0 1 8.302-3.046 3.5 3.5 0 0 1 4.504 4.272A4 4 0 0 1 15 17H5.5Zm5.25-9.25a.75.75 0 0 0-1.5 0v4.59l-1.95-2.1a.75.75 0 1 0-1.1 1.02l3.25 3.5a.75.75 0 0 0 1.1 0l3.25-3.5a.75.75 0 1 0-1.1-1.02l-1.95 2.1V7.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      {/*<FormControl className="w-full shadow-sm rounded-md">*/}
                      {/*  <InputLabel id="source-select-label">Source</InputLabel>*/}
                      {/*  <CompellingSelect*/}
                      {/*    labelId="source-select-label"*/}
                      {/*    label="Source"*/}
                      {/*    id="source-select"*/}
                      {/*    name="source-select"*/}
                      {/*    autoComplete="source"*/}
                      {/*    onChange={(e) => {}}*/}
                      {/*    placeholder="Select a source"*/}
                      {/*  >*/}
                      {/*    <option value="HubSpot">HubSpot All</option>*/}
                      {/*  </CompellingSelect>*/}
                      {/*</FormControl>*/}
                    </div>
                    <Tags
                      id="prospect-compelling-events"
                      className="mt-2 block w-full bg-white rounded-md border-gray-300 shadow-sm text-xs"
                      // tagifyRef={tagifyRef} // optional Ref object for the Tagify instance itself, to get access to  inner-methods
                      value={compellingTags}
                      settings={{
                        maxTags: 10,
                        placeholder: "Define up to 10 event tags",
                        id: "prospect-compelling-events",
                        whitelist: [
                          "Funding",
                          "Open sales roles",
                          "Expansion",
                          "Regulatory change",
                          "New partnership",
                          "Acquisition",
                          "Leadership change",
                          "New product",
                          "New service",
                          "New feature",
                          "New pricing",
                          "New competitor",
                        ],
                        delimiters: null,
                        dropdown: {
                          classname: "tags-look",
                          enabled: 0, // show the dropdown immediately on focus
                          maxItems: 10,
                          position: "text", // place the dropdown near the typed text
                          closeOnSelect: false, // keep the dropdown open after selecting a suggestion
                          highlightFirst: true,
                        },
                      }}
                      onChange={(e) => {
                        console.log(e.detail.value);
                        // set the compellingTags by transforming the array of objects into an array of strings [{"value":"Intrapreneurship Program"}] -> ["Intrapreneurship Program"]
                        setCompellingTags(
                          JSON.parse(e.detail.value).map((tag) => tag.value)
                        );
                      }}
                    />
                    {/*<button>Save</button>*/}
                    {/*<button>Cancel</button>*/}
                  </div>
                )}
              </div>
              {/*<div className="max-w-2xl text-left mb-4">*/}
              {/*  {userMetadata["list_name"]}*/}
              {/*</div>*/}
              <div className="rounded-lg bg-stone-700 w-full max-w-xl p-4 mb-12 relative">
                <div className="flex mb-4 justify-between font-mono text-stone-400">
                  <div className="text-center border rounded-lg p-4 border-stone-600">
                    <span className="text-4xl font-bold block mb-1">
                      {mainCount}
                    </span>
                    <span className="text-xs block">Accounts</span>
                  </div>
                  {/*{userMetadata["tracking_list"].length} Accounts*/}
                  <div className="text-center border rounded-lg p-4 border-stone-600">
                    <span className="text-4xl font-bold block mb-1">
                      {researchCount === 0
                        ? "0.00%"
                        : ((infoFoundCount * 100) / researchCount).toPrecision(
                            3
                          ) + "%"}
                    </span>
                    <span className="text-xs block">Event Coverage</span>
                  </div>
                  <div className="text-center border rounded-lg p-4 border-stone-600">
                    <span className="text-4xl font-bold block mb-1">
                      {averageScore}
                    </span>
                    <span className="text-xs block">Average Score</span>
                  </div>
                  <div className="text-center border rounded-lg p-4 border-stone-600">
                    <span className="text-4xl font-bold block mb-1">
                      {checkedAccounts.length}
                    </span>
                    <span className="text-xs block">In Sequence</span>
                  </div>
                </div>
                <div className="text-stone-400 text-xs font-mono w-4/5">
                  <span className="block">
                    Compelling Event Tags
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 inline ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </span>
                  {compellingTags.length > 0 ? (
                    <>
                      {compellingTags.slice(0, 5).map((tag) => (
                        <span
                          className="bg-stone-600 me-2 px-2.5 py-0.5 rounded inline-flex mt-2"
                          key={tag}
                        >
                          {tag}
                        </span>
                      ))}
                      {compellingTags.length > 5 && (
                        <span className="bg-stone-600 me-2 px-2.5 py-0.5 rounded inline-flex mt-2">
                          + {compellingTags.length - 5} more
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="bg-stone-600 me-2 px-2.5 py-0.5 rounded inline-flex mt-2">
                      No tags yet
                    </span>
                  )}
                </div>
                <div className="absolute bottom-4 right-4 text-[#EEE8AA]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              <div className="w-full flex flex-grow mb-12 max-w-xl">
                <form className="flex flex-grow">
                  <label
                    htmlFor="prospect-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only"
                  >
                    Search
                  </label>
                  <div className="relative flex flex-grow">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="prospect-search"
                      className="truncate block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-600 focus:border-indigo-600"
                      placeholder="Search Prospects, Companies ..."
                      required
                      value={searchTerm}
                      onChange={handleChange}
                    />
                  </div>
                </form>
                <div
                  className={classNames(
                    hideFilters && "hidden w-0",
                    "items-center flex transition ease-in-out"
                  )}
                >
                  {/*<button className="text-white bg-gray-200 font-medium rounded-lg text-sm px-4 py-2 my-auto ml-3 mr-1">*/}
                  {/*  Add Filter*/}
                  {/*</button>*/}
                  <Tabs defaultValue="all" className="my-auto">
                    <div className="flex items-center ml-2">
                      <TabsList>
                        <TabsTrigger
                          onClick={() => setFilterSetting("")}
                          value="all"
                        >
                          All
                        </TabsTrigger>
                        <TabsTrigger
                          onClick={() => setFilterSetting("In Campaign")}
                          value="new"
                        >
                          New
                        </TabsTrigger>
                        <TabsTrigger
                          onClick={() => setFilterSetting("Contacted")}
                          value="contacted"
                        >
                          Contacted
                        </TabsTrigger>
                        {/*<TabsTrigger*/}
                        {/*  onClick={() => setFilterSetting("Reply Received")}*/}
                        {/*  value="replied"*/}
                        {/*>*/}
                        {/*  Replied*/}
                        {/*</TabsTrigger>*/}
                        <TabsTrigger
                          onClick={() => setFilterSetting("Completed")}
                          value="completed"
                        >
                          Completed
                        </TabsTrigger>
                      </TabsList>
                    </div>
                  </Tabs>
                </div>
                <button
                  className="text-gray-900"
                  onClick={() => setHideFilters(!hideFilters)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                    />
                  </svg>
                </button>
              </div>
              {researchMode && (
                <div className="flex w-full max-w-xl items-center pl-4 mb-8">
                  <ThemeProvider theme={compellingTheme}>
                    <Slider
                      aria-label="focus-slider"
                      value={selectionSlider}
                      onChange={handleSliderChange}
                      step={1}
                      classes={{
                        markLabel: "!text-xs !font-mono",
                      }}
                      min={0}
                      max={100}
                      color="primary"
                      track={false}
                      marks={selectionSliderMarks}
                    />
                  </ThemeProvider>
                  <button
                    disabled={numberOfSelected === 0}
                    className="ml-8 w-2/3 text-stone-600 border border-stone-200 rounded-lg bg-white/50 p-4 text-sm float-right disabled:opacity-50 hover:border-indigo-600"
                    onClick={handleAddToSequence}
                  >
                    {!sequenceLoading ? (
                      <>
                        Add
                        <span className="text-stone-900 font-bold font-mono mx-1.5">
                          {numberOfSelected}
                        </span>
                        leads to sequence
                      </>
                    ) : (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline w-4 h-4 me-3 text-transparent animate-spin"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="rgb(79 70 229)"
                          />
                        </svg>
                        <span className="text-stone-600 text-sm">
                          Sending to Outreach …
                        </span>
                      </>
                    )}
                  </button>
                </div>
              )}
              <div className="mb-8">
                <ul className="max-w-xl w-full">
                  {searchResults &&
                    (searchResults.length !== 0 ? (
                      searchResults.map(
                        (contact, index) =>
                          index <= mainCount &&
                          (contact.item.Account["Account Name"] ||
                            contact.item.Account.Anschrift.Firma ||
                            contact.item.Account.Namen) && (
                            <li
                              key={contact.item["Internal ID"].toString()}
                              value={
                                contact.item["Account Name"]
                                  ? contact.item["Account Name"]
                                  : contact.item.Account.Anschrift.Firma
                                  ? contact.item.Account.Anschrift.Firma
                                  : contact.item.Account.Namen
                              }
                              className={`w-full relative bg-white my-1 rounded-md border ${
                                index === 0 &&
                                contact.item.Research.rating >= 95 &&
                                "shadow-xl shadow-[#EEE8AA] "
                              }
                            ${
                              contact.item["Internal ID"] !== previewOpen &&
                              previewOpen !== null &&
                              "" //" hidden"
                            }
                            `}
                              style={
                                contact.item.Research.rating >= 95
                                  ? {
                                      borderColor: "#EEE8AA",
                                    }
                                  : {
                                      borderColor: lerpColor(
                                        startColor,
                                        endColor,
                                        (Math.round(
                                          contact.item.Research.rating / 10
                                        ) *
                                          10) /
                                          100
                                      ),
                                    }
                              }
                            >
                              <div className="flex absolute top-0 -left-8">
                                <div className="flex-grow my-auto pt-2 flex h-full items-center">
                                  <input
                                    id={
                                      contact.item["Internal ID"].toString() +
                                      "_checkbox"
                                    }
                                    type="checkbox"
                                    onChange={() => {
                                      return;
                                    }}
                                    checked={
                                      researchMode
                                        ? contact.item.Research.rating >=
                                          100 - selectionSlider
                                        : checkedAccounts.includes(
                                            contact.item["Internal ID"]
                                          )
                                    }
                                    onClick={() => {
                                      if (!researchMode) {
                                        if (
                                          checkedAccounts.includes(
                                            contact.item["Internal ID"]
                                          )
                                        ) {
                                          setCheckedAccounts(
                                            checkedAccounts.filter(
                                              (id) =>
                                                id !==
                                                contact.item["Internal ID"]
                                            )
                                          );
                                        } else {
                                          setCheckedAccounts([
                                            ...checkedAccounts,
                                            contact.item["Internal ID"],
                                          ]);
                                        }
                                      }
                                    }}
                                    className="w-5 h-5 text-indigo-600 bg-stone-100/25 border-stone-300 rounded focus:ring-compelling-bright focus:ring-2 cursor-pointer hover:bg-compelling-bright/25 hover:border-indigo-600"
                                  />
                                </div>
                              </div>
                              <div
                                className="group cursor-pointer pb-3 w-full sm:pb-4 p-4"
                                onClick={() => {
                                  // if (contact.item.Research.rating <= 95) {
                                  if (
                                    contact.item["Internal ID"] === previewOpen
                                  ) {
                                    setPreviewOpen(null);
                                  } else {
                                    setPreviewOpen(contact.item["Internal ID"]);
                                    localStorage.setItem(
                                      "current_account",
                                      JSON.stringify(contact.item)
                                    );
                                    localStorage.setItem(
                                      "current_list",
                                      JSON.stringify(selectedList)
                                    );
                                  }
                                  // }
                                }}
                              >
                                {contact.item.Research.reasoning != null && (
                                  <div className="font-mono items-center bg-white/90 text-left backdrop-blur-sm p-4 rounded absolute w-56 top-0 -right-60 text-xs font-normal hidden group-hover:block">
                                    <p className="mb-2 bg-gray-100 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded me-2 border border-gray-500 ">
                                      <svg
                                        className="w-2.5 h-2.5 me-1.5"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
                                      </svg>
                                      {contact.item.Research.last_researched}
                                    </p>
                                    <p>{contact.item.Research.reasoning}</p>
                                  </div>
                                )}
                                {/*{contact.item.Research.rating >= 95 && (*/}
                                {/*  <div className="group absolute z-10 flex rounded-md items-center w-full -ml-4 -mt-4 h-full p-2 justify-center backdrop-blur-md transition duration-150 ease-in-out hover:backdrop-blur-sm">*/}
                                {/*    <div className="rounded border border-gray-500 text-xs text-gray-500 p-2 flex group-hover:bg-gray-900 group-hover:border-transparent group-hover:text-white">*/}
                                {/*      <svg*/}
                                {/*        xmlns="http://www.w3.org/2000/svg"*/}
                                {/*        fill="none"*/}
                                {/*        viewBox="0 0 24 24"*/}
                                {/*        strokeWidth={1.5}*/}
                                {/*        stroke="currentColor"*/}
                                {/*        className="w-4 h-4"*/}
                                {/*      >*/}
                                {/*        <path*/}
                                {/*          strokeLinecap="round"*/}
                                {/*          strokeLinejoin="round"*/}
                                {/*          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"*/}
                                {/*        />*/}
                                {/*      </svg>*/}
                                {/*      <span className="ml-1">*/}
                                {/*        Get PRO to unlock*/}
                                {/*      </span>*/}
                                {/*    </div>*/}
                                {/*  </div>*/}
                                {/*)}*/}
                                {index === 0 &&
                                  contact.item.Research.rating >= 55 && (
                                    <div className="relative group-hover:-translate-y-2 transition duration-150 ease-in-out -z-10">
                                      <div className="absolute -top-8 left-1/2 w-2 h-full text-3xl rotate-[-15deg] group-hover:rotate-[-5deg] transition duration-150 ease-in-out">
                                        🔥
                                      </div>
                                      <div className="absolute top-1 -right-9 w-2 h-full text-5xl group-hover:rotate-[-32deg] group-hover:-translate-y-12 transition duration-500 ease-in-out">
                                        🚀
                                      </div>
                                      <div className="absolute top-2 -left-16 w-2 h-full text-4xl transform -scale-x-100 group-hover:rotate-[-10deg] transition duration-700 ease-in-out">
                                        🎉
                                      </div>
                                    </div>
                                  )}
                                <div className="absolute bottom-[0.0025em] w-full justify-center -ml-4 hidden group-hover:flex text-gray-200">
                                  {contact.item["Internal ID"] !==
                                  previewOpen ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                      />
                                    </svg>
                                  )}
                                </div>
                                <div className="flex items-center space-x-4">
                                  <div className="flex-shrink-0">
                                    <a
                                      href={
                                        "https://" +
                                        contact.item.Account.Kontakt.domain
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        className="w-6 h-6 opacity-50 grayscale group-hover:opacity-100 group-hover:grayscale-0 group-hover:scale-110 transition duration-150 ease-in-out"
                                        src={
                                          // "https://api.dicebear.com/7.x/big-ears/svg?seed=" +
                                          // contact.item["Internal ID"]
                                          "https://www.google.com/s2/favicons?domain=" +
                                          contact.item.Account.Kontakt.domain
                                        }
                                        alt="logo"
                                        loading="lazy"
                                      />
                                    </a>
                                  </div>
                                  <div className="flex-1 min-w-0">
                                    <p className="text-sm font-medium text-gray-900 truncate mb-1">
                                      {contact.item.Account["Account Name"]
                                        ? contact.item.Account["Account Name"]
                                        : contact.item.Account.Anschrift.Firma
                                        ? contact.item.Account.Anschrift.Firma
                                        : contact.item.Account.Namen}
                                    </p>
                                    <div className="text-xs text-gray-900 flex items-center">
                                      <ul className="whitespace-nowrap">
                                        <li className="inline-flex mr-1">
                                          {"Social Media" in
                                            contact.item.Account &&
                                            "LinkedIn" in
                                              contact.item.Account[
                                                "Social Media"
                                              ] && (
                                              <a
                                                href={
                                                  contact.item.Account[
                                                    "Social Media"
                                                  ].LinkedIn
                                                }
                                              >
                                                <Tooltip
                                                  disableHoverListener={
                                                    !contact.item.Account[
                                                      "Social Media"
                                                    ].LinkedIn
                                                  }
                                                  arrow
                                                  title={
                                                    <React.Fragment>
                                                      Visit LinkedIn Profile
                                                    </React.Fragment>
                                                  }
                                                  placement="bottom"
                                                >
                                                  <span className="-ml-1 me-1">
                                                    <button
                                                      role="link"
                                                      onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        window.open(
                                                          contact.item.Account[
                                                            "Social Media"
                                                          ].LinkedIn,
                                                          "_blank",
                                                          "noreferrer"
                                                        );
                                                      }}
                                                      disabled={
                                                        !contact.item.Account[
                                                          "Social Media"
                                                        ].LinkedIn
                                                      }
                                                      className="disabled:opacity-25 p-1 rounded-md hover:bg-gray-100"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0px"
                                                        y="0px"
                                                        className="w-4 h-4 text-gray-500"
                                                        viewBox="0 0 192 192"
                                                      >
                                                        {
                                                          <g>
                                                            <path
                                                              d="M0,192v-192h192v192z"
                                                              fill="none"
                                                            ></path>
                                                            <g fill="currentColor">
                                                              <g id="surface1">
                                                                <path d="M156,0h-120c-19.875,0 -36,16.125 -36,36v120c0,19.875 16.125,36 36,36h120c19.875,0 36,-16.125 36,-36v-120c0,-19.875 -16.125,-36 -36,-36zM59.36539,162.98077h-29.82693l-0.17307,-89.30769h29.82692zM43.70192,61.99038h-0.17308c-9.75,0 -16.03846,-6.72115 -16.03846,-15.08653c0,-8.56731 6.49039,-15.0577 16.41347,-15.0577c9.92308,0 16.00961,6.49038 16.21153,15.0577c0,8.36538 -6.31731,15.08653 -16.41346,15.08653zM162.77885,162.98077h-30.08654v-48.51923c0,-11.74039 -3.11538,-19.73077 -13.61538,-19.73077c-8.01923,0 -12.34615,5.39423 -14.42308,10.61538c-0.77885,1.875 -0.98077,4.44231 -0.98077,7.06731v50.56731h-30.23077l-0.17308,-89.30769h30.23077l0.17308,12.60577c3.86538,-5.97116 10.29808,-14.42308 25.70192,-14.42308c19.09616,0 33.37501,12.46154 33.37501,39.25961v51.86539z"></path>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        }
                                                      </svg>
                                                    </button>
                                                  </span>
                                                </Tooltip>
                                              </a>
                                            )}
                                        </li>
                                        <li className="inline-flex mr-2">
                                          <Tooltip
                                            disableHoverListener={
                                              !contact.item.Account.Kontakt
                                                .Telefon[0]
                                            }
                                            arrow
                                            title={
                                              <React.Fragment>
                                                {
                                                  contact.item.Account.Kontakt
                                                    .Telefon[0]
                                                }
                                              </React.Fragment>
                                            }
                                            placement="bottom"
                                          >
                                            <span>
                                              <button
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  event.stopPropagation();
                                                  navigator.clipboard.writeText(
                                                    contact.item.Account.Kontakt
                                                      .Telefon[0]
                                                  );
                                                }}
                                                disabled={
                                                  !contact.item.Account.Kontakt
                                                    .Telefon[0]
                                                }
                                                className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="currentColor"
                                                  className="w-4 h-4 text-gray-500"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </button>
                                            </span>
                                          </Tooltip>
                                        </li>
                                      </ul>
                                      {(contact.item.Account.Kategorie ||
                                        (contact.item.Enrichment &&
                                          contact.item.Enrichment
                                            .company_enrichment &&
                                          contact.item.Enrichment
                                            .company_enrichment.industry)) && (
                                        <span className="bg-gray-100 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full truncate">
                                          {contact.item.Account.Kategorie
                                            ? contact.item.Account.Kategorie
                                            : contact.item.Enrichment
                                                .company_enrichment.industry}
                                        </span>
                                      )}
                                      <span className="text-gray-500 whitespace-nowrap">
                                        {contact.item.Prospects.length >= 1 && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-3 h-3 pb-0.5 inline-flex mr-1"
                                          >
                                            <path d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z" />
                                          </svg>
                                        )}
                                        {contact.item.Prospects[0] &&
                                          contact.item.Prospects[0].vorname +
                                            " " +
                                            contact.item.Prospects[0].name}
                                        {contact.item.Prospects.length > 1 &&
                                          " +  " +
                                            (contact.item.Prospects.length - 1)}
                                      </span>
                                    </div>
                                    {/*<p className="text-sm text-gray-500 truncate">*/}
                                    {/*  {contact.item.emailaddress1}*/}
                                    {/*</p>*/}
                                  </div>
                                  <div className="font-mono items-center text-center text-base font-semibold text-gray-900 justify-center z-20 group">
                                    {contact.item.Research.rating && (
                                      <>{contact.item.Research.rating}%</>
                                    )}
                                    {contact.item.Research.rating >= 95 && (
                                      <span className="bg-[#EEE8AA] block text-[#C5B358] text-xs font-medium px-2.5 py-0.5 rounded">
                                        Unicorn
                                      </span>
                                    )}
                                    {contact.item.Research.rating >= 75 &&
                                      contact.item.Research.rating < 95 && (
                                        <span className="bg-compelling-bright/50 block text-indigo-600 text-xs font-medium px-2.5 py-0.5 rounded">
                                          High
                                        </span>
                                      )}
                                    {contact.item.Research.rating >= 50 &&
                                      contact.item.Research.rating < 75 && (
                                        <span className="bg-compelling-bright/25 block text-indigo-400 text-xs font-medium px-2.5 py-0.5 rounded">
                                          Good
                                        </span>
                                      )}
                                    {contact.item.Research.rating >= 25 &&
                                      contact.item.Research.rating < 50 && (
                                        <span className="bg-gray-300 block text-gray-500 text-xs font-medium px-2.5 py-0.5 rounded">
                                          Wait
                                        </span>
                                      )}
                                    {contact.item.Research.rating >= 0 &&
                                      contact.item.Research.rating !== null &&
                                      contact.item.Research.rating < 25 && (
                                        <span className="bg-white border border-gray-300 block text-gray-300 text-xs font-medium px-2.5 py-0.5 rounded">
                                          Base
                                        </span>
                                      )}
                                    {!contact.item.Research.rating && (
                                      <div
                                        role="status"
                                        className="items-center px-2.5"
                                      >
                                        <svg
                                          aria-hidden="true"
                                          className="w-6 h-6 inline text-black/50 animate-spin fill-black"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {contact.item["Internal ID"] ===
                                  previewOpen && (
                                  <>
                                    <p className="text-gray-300 absolute bottom-4 text-xs flex items-center">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                        className="w-4 h-4 mr-1"
                                      >
                                        <path d="M8.543 2.232a.75.75 0 0 0-1.085 0l-5.25 5.5A.75.75 0 0 0 2.75 9H4v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V9h1.25a.75.75 0 0 0 .543-1.268l-5.25-5.5Z" />
                                      </svg>
                                      <span className="pt-0.5">
                                        {contact.item.Source !== "compelling"
                                          ? "Customer"
                                          : "compelling"}
                                      </span>
                                    </p>
                                    <div>
                                      <p className="text-gray-500 mt-4 text-xs">
                                        {contact.item.Research.info}
                                      </p>
                                      <div className="bg-gradient-to-r from-gray-50 from-75% to-gray-100 to-100% mt-2 rounded-full max-w-xl p-2 mr-3 flex overflow-x-auto">
                                        {contact.item.Research.sources &&
                                          parseUrls(
                                            contact.item.Research.sources
                                          ).map(
                                            (
                                              { fullUrl, baseUrl, name },
                                              index
                                            ) => (
                                              <button
                                                key={index}
                                                className="border rounded-full flex p-2 mr-2 items-center hover:border-compelling-bright"
                                                onClick={() =>
                                                  window.open(fullUrl, "_blank")
                                                }
                                              >
                                                <span className="text-xs ml-1 mr-2">
                                                  {index + 1}
                                                </span>
                                                <Divider
                                                  sx={{ bgcolor: "white" }}
                                                  orientation="vertical"
                                                  flexItem
                                                />
                                                <div className="h-[12px] w-[12px] mr-3.5">
                                                  <img
                                                    className="ml-2 h-[12px] w-[12px] mr-1"
                                                    src={
                                                      "https://www.google.com/s2/favicons?domain=" +
                                                      baseUrl
                                                    }
                                                    alt={name + " favicon"}
                                                    height={16}
                                                    width={16}
                                                  />
                                                </div>
                                                <p className="mr-1 whitespace-nowrap text-xs">
                                                  {name}
                                                </p>
                                              </button>
                                            )
                                          )}
                                      </div>
                                    </div>
                                    <div className="flex">
                                      <div className="flex-grow mr-4">
                                        <div className="p-2 bg-gray-50 flex justify-center min-w-1/2 rounded-md mb-8 mt-4">
                                          {user[
                                            "https://compelling.ai/claims/business_id"
                                          ] === "65bd6cbdf1e2ee421665b46a" ||
                                          selectedList["list_id"] ===
                                            "65bd6d08f1e2ee421665b46c" ? (
                                            <div className="flex-row py-8">
                                              <div className="flex mb-2">
                                                <div className="group/saas">
                                                  {contact.item[
                                                    "Website Insights"
                                                  ].saas_state.is_saas ? (
                                                    <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-green-400">
                                                      SaaS
                                                      <span className="ml-1.5">
                                                        ✅
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-red-400">
                                                      SaaS
                                                      <span className="ml-1.5">
                                                        ❌
                                                      </span>
                                                    </span>
                                                  )}
                                                  {contact.item[
                                                    "Website Insights"
                                                  ].saas_state.saas_reasoning !=
                                                    null && (
                                                    <div className="font-mono items-center bg-white/90 text-left backdrop-blur-sm p-4 rounded absolute w-56 top-0 -right-60 text-xs font-normal hidden group-hover/saas:block">
                                                      <p>
                                                        {
                                                          contact.item[
                                                            "Website Insights"
                                                          ].saas_state
                                                            .saas_reasoning
                                                        }
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="group/login">
                                                  {contact.item[
                                                    "Website Insights"
                                                  ].saas_state.has_login ? (
                                                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded border border-green-400">
                                                      Login
                                                      <span className="ml-1.5">
                                                        ✅
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-red-400">
                                                      Login
                                                      <span className="ml-1.5">
                                                        ❌
                                                      </span>
                                                    </span>
                                                  )}
                                                  {contact.item[
                                                    "Website Insights"
                                                  ].saas_state
                                                    .login_reasoning !=
                                                    null && (
                                                    <div className="font-mono items-center bg-white/90 text-left backdrop-blur-sm p-4 rounded absolute w-56 top-0 -right-60 text-xs font-normal hidden group-hover/login:block">
                                                      <p>
                                                        {
                                                          contact.item[
                                                            "Website Insights"
                                                          ].saas_state
                                                            .login_reasoning
                                                        }
                                                      </p>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div>
                                                {contact.item[
                                                  "Website Insights"
                                                ].Technologies
                                                  .Authentication ? (
                                                  <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded border border-yellow-300">
                                                    {contact.item[
                                                      "Website Insights"
                                                    ].Technologies.Authentication.replace(
                                                      " ; ",
                                                      ", "
                                                    )}
                                                    <span className="ml-1.5">
                                                      💡
                                                    </span>
                                                  </span>
                                                ) : (
                                                  <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-gray-500 opacity-50">
                                                    Auth unknown
                                                    <span className="ml-1.5">
                                                      ❓
                                                    </span>
                                                  </span>
                                                )}
                                              </div>
                                              <div className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-gray-500 mt-6">
                                                {"Misc" in contact.item &&
                                                  contact.item["Misc"][
                                                    "FT1000Rank"
                                                  ] && (
                                                    <p className="text-gray-500 text-xs flex items-center">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 16 16"
                                                        fill="currentColor"
                                                        className="w-4 h-4 mr-1"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          d="M12 1.69a.494.494 0 0 0-.438-.494 32.352 32.352 0 0 0-7.124 0A.494.494 0 0 0 4 1.689v.567c-.811.104-1.612.24-2.403.406a.75.75 0 0 0-.595.714 4.5 4.5 0 0 0 4.35 4.622A3.99 3.99 0 0 0 7 8.874V10H6a1 1 0 0 0-1 1v2h-.667C3.597 13 3 13.597 3 14.333c0 .368.298.667.667.667h8.666a.667.667 0 0 0 .667-.667c0-.736-.597-1.333-1.333-1.333H11v-2a1 1 0 0 0-1-1H9V8.874a3.99 3.99 0 0 0 1.649-.876 4.5 4.5 0 0 0 4.35-4.622.75.75 0 0 0-.596-.714A30.897 30.897 0 0 0 12 2.256v-.567ZM4 3.768c-.49.066-.976.145-1.458.235a3.004 3.004 0 0 0 1.64 2.192A3.999 3.999 0 0 1 4 5V3.769Zm8 0c.49.066.976.145 1.458.235a3.004 3.004 0 0 1-1.64 2.192C11.936 5.818 12 5.416 12 5V3.769Z"
                                                          clipRule="evenodd"
                                                        />
                                                      </svg>
                                                      FT 1000 Rank:
                                                      <span className="ml-1">
                                                        {
                                                          contact.item["Misc"][
                                                            "FT1000Rank"
                                                          ]
                                                        }
                                                      </span>
                                                    </p>
                                                  )}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="flex-row space-y-2 py-8">
                                              {contact.item.Misc
                                                .Unternehmenstyp && (
                                                <p className="text-gray-500 text-xs flex items-center">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16"
                                                    fill="currentColor"
                                                    className="w-4 h-4 mr-1"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M1.75 2a.75.75 0 0 0 0 1.5H2v9h-.25a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 .75.75v1.5c0 .414.336.75.75.75h.5a.75.75 0 0 0 .75-.75V3.5h.25a.75.75 0 0 0 0-1.5h-7.5ZM3.5 5.5A.5.5 0 0 1 4 5h.5a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5v-.5Zm.5 2a.5.5 0 0 0-.5.5v.5A.5.5 0 0 0 4 9h.5a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5H4Zm2-2a.5.5 0 0 1 .5-.5H7a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-.5.5h-.5A.5.5 0 0 1 6 6v-.5Zm.5 2A.5.5 0 0 0 6 8v.5a.5.5 0 0 0 .5.5H7a.5.5 0 0 0 .5-.5V8a.5.5 0 0 0-.5-.5h-.5ZM11.5 6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h2.75a.75.75 0 0 0 0-1.5H14v-5h.25a.75.75 0 0 0 0-1.5H11.5Zm.5 1.5h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H12a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 .5-.5Zm0 2.5a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-.5a.5.5 0 0 0-.5-.5H12Z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>

                                                  <span className="pt-0.5">
                                                    {
                                                      contact.item.Misc
                                                        .Unternehmenstyp
                                                    }
                                                  </span>
                                                </p>
                                              )}
                                              {contact.item.Misc.MAKlasse && (
                                                <p className="text-gray-500 text-xs flex items-center">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16"
                                                    fill="currentColor"
                                                    className="w-4 h-4 mr-1"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M11 4V3a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v1H4a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1ZM9 2.5H7a.5.5 0 0 0-.5.5v1h3V3a.5.5 0 0 0-.5-.5ZM9 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                                                      clipRule="evenodd"
                                                    />
                                                    <path d="M3 11.83V12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-.17c-.313.11-.65.17-1 .17H4c-.35 0-.687-.06-1-.17Z" />
                                                  </svg>

                                                  <span className="pt-0.5">
                                                    {contact.item.Misc.MAKlasse}
                                                  </span>
                                                </p>
                                              )}
                                              {"Website Insights" in
                                                contact.item &&
                                                contact.item[
                                                  "Website Insights"
                                                ]["Traffic rank"] && (
                                                  <p className="text-gray-500 text-xs flex items-center">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 16 16"
                                                      fill="currentColor"
                                                      className="w-4 h-4 mr-1"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M12 1.69a.494.494 0 0 0-.438-.494 32.352 32.352 0 0 0-7.124 0A.494.494 0 0 0 4 1.689v.567c-.811.104-1.612.24-2.403.406a.75.75 0 0 0-.595.714 4.5 4.5 0 0 0 4.35 4.622A3.99 3.99 0 0 0 7 8.874V10H6a1 1 0 0 0-1 1v2h-.667C3.597 13 3 13.597 3 14.333c0 .368.298.667.667.667h8.666a.667.667 0 0 0 .667-.667c0-.736-.597-1.333-1.333-1.333H11v-2a1 1 0 0 0-1-1H9V8.874a3.99 3.99 0 0 0 1.649-.876 4.5 4.5 0 0 0 4.35-4.622.75.75 0 0 0-.596-.714A30.897 30.897 0 0 0 12 2.256v-.567ZM4 3.768c-.49.066-.976.145-1.458.235a3.004 3.004 0 0 0 1.64 2.192A3.999 3.999 0 0 1 4 5V3.769Zm8 0c.49.066.976.145 1.458.235a3.004 3.004 0 0 1-1.64 2.192C11.936 5.818 12 5.416 12 5V3.769Z"
                                                        clipRule="evenodd"
                                                      />
                                                    </svg>

                                                    <span className="pt-0.5">
                                                      {contact.item[
                                                        "Website Insights"
                                                      ]["Traffic rank"] +
                                                        "th rank"}
                                                    </span>
                                                  </p>
                                                )}
                                              {contact.item.Misc
                                                .GegruendetJahr && (
                                                <p className="text-gray-500 text-xs flex items-center">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 16"
                                                    fill="currentColor"
                                                    className="w-4 h-4 mr-1"
                                                  >
                                                    <path d="M5.75 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM5 10.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM10.25 7.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5ZM7.25 8.25a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0ZM8 9.5A.75.75 0 1 0 8 11a.75.75 0 0 0 0-1.5Z" />
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M4.75 1a.75.75 0 0 0-.75.75V3a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2V1.75a.75.75 0 0 0-1.5 0V3h-5V1.75A.75.75 0 0 0 4.75 1ZM3.5 7a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4.5a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7Z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>

                                                  <span className="pt-0.5">
                                                    {"Founded " +
                                                      contact.item.Misc
                                                        .GegruendetJahr}
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="w-1/2 flex-grow my-4">
                                        {contact.item.Prospects.length >= 1 && (
                                          <div className="">
                                            {contact.item.Prospects.map(
                                              (prospect, index) => (
                                                <div
                                                  onClick={(event) => {
                                                    if (
                                                      prospect["_id"] !==
                                                      activeMessageId
                                                    ) {
                                                      setToneTags(
                                                        selectedList.settings.tone.split(
                                                          ", "
                                                        )
                                                      );
                                                      setCompellingTags(
                                                        selectedList.settings
                                                          .event_tags
                                                      );
                                                      window.open(
                                                        "/v1/composer/?prospect=" +
                                                          prospect["_id"],
                                                        "_self"
                                                      );
                                                    } else {
                                                      //prevent default and propagation
                                                      event.preventDefault();
                                                      event.stopPropagation();
                                                    }
                                                  }}
                                                  key={prospect["_id"]}
                                                  className={
                                                    prospect["_id"] ===
                                                    activeMessageId
                                                      ? index > 0
                                                        ? "relative group/prospect mt-2 flex-row items-center border border-gray-100 rounded-md cursor-default"
                                                        : "relative group/prospect flex-row items-center border border-gray-100 rounded-md cursor-default"
                                                      : index > 0
                                                      ? "relative group/prospect mt-2 flex-row items-center border border-opacity-0 border-gray-100 hover:border-opacity-100 rounded-md"
                                                      : "relative group/prospect flex-row items-center border border-opacity-0 border-gray-100 hover:border-opacity-100 rounded-md"
                                                  }
                                                >
                                                  <div className="flex items-center space-x-2 py-1">
                                                    {/*bg gradient from transparent to white*/}
                                                    {prospect["_id"] !==
                                                      activeMessageId && (
                                                      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-l from-30% from-white to-transparent opacity-0 rounded-md group-hover/prospect:opacity-100 group-hover/prospect:from-white group-hover/prospect:to-transparent transition duration-150 ease-in-out flex items-center justify-end">
                                                        <ul className="mr-2 mt-0.5">
                                                          <li className="inline-flex mr-2">
                                                            <Tooltip
                                                              disableHoverListener={
                                                                connected.linkedin
                                                              }
                                                              arrow
                                                              title={
                                                                <React.Fragment>
                                                                  First connect
                                                                  your LinkedIn
                                                                  <br />
                                                                  account to
                                                                  send messages
                                                                </React.Fragment>
                                                              }
                                                              placement="bottom"
                                                            >
                                                              <span>
                                                                <button
                                                                  disabled={
                                                                    !connected.linkedin ||
                                                                    !prospect.linkedin_url
                                                                  }
                                                                  className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                                                  onClick={(
                                                                    event
                                                                  ) =>
                                                                    newMessage(
                                                                      event,
                                                                      prospect,
                                                                      contact.item
                                                                    )
                                                                  }
                                                                >
                                                                  {!gettingProspect ? (
                                                                    <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      viewBox="0 0 20 20"
                                                                      fill="currentColor"
                                                                      className="w-4 h-4 text-gray-500"
                                                                    >
                                                                      <path d="M3.105 2.288a.75.75 0 0 0-.826.95l1.414 4.926A1.5 1.5 0 0 0 5.135 9.25h6.115a.75.75 0 0 1 0 1.5H5.135a1.5 1.5 0 0 0-1.442 1.086l-1.414 4.926a.75.75 0 0 0 .826.95 28.897 28.897 0 0 0 15.293-7.155.75.75 0 0 0 0-1.114A28.897 28.897 0 0 0 3.105 2.288Z" />
                                                                    </svg>
                                                                  ) : (
                                                                    <div
                                                                      role="status"
                                                                      className=""
                                                                    >
                                                                      <svg
                                                                        aria-hidden="true"
                                                                        className="w-4 h-4 animate-spin fill-gray-500 bg-gray-200"
                                                                        viewBox="0 0 100 101"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                          fill="currentColor"
                                                                        />
                                                                        <path
                                                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                          fill="currentFill"
                                                                        />
                                                                      </svg>
                                                                      <span className="sr-only">
                                                                        Loading...
                                                                      </span>
                                                                    </div>
                                                                  )}
                                                                </button>
                                                              </span>
                                                            </Tooltip>
                                                          </li>
                                                          <li className="inline-flex mr-2">
                                                            <Tooltip
                                                              disableHoverListener={
                                                                !prospect.linkedin_url
                                                              }
                                                              arrow
                                                              title={
                                                                <React.Fragment>
                                                                  Visit LinkedIn
                                                                  Profile
                                                                </React.Fragment>
                                                              }
                                                              placement="bottom"
                                                            >
                                                              <span>
                                                                <button
                                                                  role="link"
                                                                  onClick={(
                                                                    event
                                                                  ) => {
                                                                    event.preventDefault();
                                                                    event.stopPropagation();
                                                                    window.open(
                                                                      prospect.linkedin_url,
                                                                      "_blank",
                                                                      "noreferrer"
                                                                    );
                                                                  }}
                                                                  disabled={
                                                                    !prospect.linkedin_url
                                                                  }
                                                                  className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                                                >
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    x="0px"
                                                                    y="0px"
                                                                    className="w-4 h-4 text-gray-500"
                                                                    viewBox="0 0 192 192"
                                                                  >
                                                                    {
                                                                      <g>
                                                                        <path
                                                                          d="M0,192v-192h192v192z"
                                                                          fill="none"
                                                                        ></path>
                                                                        <g fill="currentColor">
                                                                          <g id="surface1">
                                                                            <path d="M156,0h-120c-19.875,0 -36,16.125 -36,36v120c0,19.875 16.125,36 36,36h120c19.875,0 36,-16.125 36,-36v-120c0,-19.875 -16.125,-36 -36,-36zM59.36539,162.98077h-29.82693l-0.17307,-89.30769h29.82692zM43.70192,61.99038h-0.17308c-9.75,0 -16.03846,-6.72115 -16.03846,-15.08653c0,-8.56731 6.49039,-15.0577 16.41347,-15.0577c9.92308,0 16.00961,6.49038 16.21153,15.0577c0,8.36538 -6.31731,15.08653 -16.41346,15.08653zM162.77885,162.98077h-30.08654v-48.51923c0,-11.74039 -3.11538,-19.73077 -13.61538,-19.73077c-8.01923,0 -12.34615,5.39423 -14.42308,10.61538c-0.77885,1.875 -0.98077,4.44231 -0.98077,7.06731v50.56731h-30.23077l-0.17308,-89.30769h30.23077l0.17308,12.60577c3.86538,-5.97116 10.29808,-14.42308 25.70192,-14.42308c19.09616,0 33.37501,12.46154 33.37501,39.25961v51.86539z"></path>
                                                                          </g>
                                                                        </g>
                                                                      </g>
                                                                    }
                                                                  </svg>
                                                                </button>
                                                              </span>
                                                            </Tooltip>
                                                          </li>
                                                          <li className="inline-flex mr-2">
                                                            <Tooltip
                                                              disableHoverListener={
                                                                !prospect.email
                                                              }
                                                              arrow
                                                              title={
                                                                <React.Fragment>
                                                                  {
                                                                    prospect.email
                                                                  }
                                                                  <span className="bg-gray-50 rounded px-1 text-gray-600 ml-1.5">
                                                                    {prospect.email_status ===
                                                                    "valid"
                                                                      ? "Verified"
                                                                      : "Risky"}
                                                                  </span>
                                                                </React.Fragment>
                                                              }
                                                              placement="bottom"
                                                            >
                                                              <span>
                                                                <button
                                                                  onClick={(
                                                                    event
                                                                  ) => {
                                                                    event.preventDefault();
                                                                    event.stopPropagation();
                                                                    window.open(
                                                                      "mailto:" +
                                                                        prospect.email,
                                                                      "_blank",
                                                                      "noreferrer"
                                                                    );
                                                                  }}
                                                                  disabled={
                                                                    !prospect.email
                                                                  }
                                                                  className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                                                >
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    fill="currentColor"
                                                                    className="w-4 h-4 text-gray-500"
                                                                  >
                                                                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                                                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                                                  </svg>
                                                                </button>
                                                              </span>
                                                            </Tooltip>
                                                          </li>
                                                          <li className="inline-flex">
                                                            <Tooltip
                                                              disableHoverListener={
                                                                !prospect.phone
                                                              }
                                                              arrow
                                                              title={
                                                                <React.Fragment>
                                                                  {
                                                                    prospect.phone
                                                                  }
                                                                </React.Fragment>
                                                              }
                                                              placement="bottom"
                                                            >
                                                              <span>
                                                                <button
                                                                  onClick={(
                                                                    event
                                                                  ) => {
                                                                    event.preventDefault();
                                                                    event.stopPropagation();
                                                                    navigator.clipboard.writeText(
                                                                      prospect.phone
                                                                    );
                                                                  }}
                                                                  disabled={
                                                                    !prospect.phone
                                                                  }
                                                                  className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                                                >
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    fill="currentColor"
                                                                    className="w-4 h-4 text-gray-500"
                                                                  >
                                                                    <path
                                                                      fillRule="evenodd"
                                                                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                                                                      clipRule="evenodd"
                                                                    />
                                                                  </svg>
                                                                </button>
                                                              </span>
                                                            </Tooltip>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    )}
                                                    <div className="flex-shrink-0 ml-2">
                                                      <img
                                                        className="w-6 h-6 rounded-full"
                                                        src={
                                                          "https://api.dicebear.com/7.x/big-ears/svg?seed=" +
                                                          prospect["vorname"] +
                                                          prospect["name"]
                                                        }
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                      <p className="text-sm font-medium text-gray-900 truncate">
                                                        {prospect.vorname +
                                                          " " +
                                                          prospect.name}
                                                      </p>
                                                      <p className="text-xs text-gray-500 truncate">
                                                        {prospect.jobtitel}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  {prospect["_id"] ===
                                                    activeMessageId && (
                                                    <div className="flex-row m-2">
                                                      <div
                                                        className={
                                                          messageCharCount <=
                                                          300
                                                            ? "text-xs text-gray-500 float-right mr-1"
                                                            : "text-xs text-red-600 float-right mr-1"
                                                        }
                                                      >
                                                        {messageCharCount}/300
                                                      </div>
                                                      <div className="flex-grow-0">
                                                        {fetching && (
                                                          <div
                                                            role="status"
                                                            className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 mt-1.5"
                                                          >
                                                            <svg
                                                              aria-hidden="true"
                                                              className="w-8 h-8 text-compelling-bright animate-spin fill-indigo-600 bg-white p-1 rounded-full"
                                                              viewBox="0 0 100 101"
                                                              fill="none"
                                                            >
                                                              <path
                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                fill="currentColor"
                                                              />
                                                              <path
                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                fill="currentFill"
                                                              />
                                                            </svg>
                                                            <span className="sr-only">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        )}
                                                        <textarea
                                                          className="w-full text-xs border-1 border-gray-100 rounded-md resize-none placeholder-gray-300"
                                                          rows="6"
                                                          onChange={(event) => {
                                                            setMessage(
                                                              event.target.value
                                                            );
                                                          }}
                                                          value={message}
                                                          placeholder={
                                                            "Hi " +
                                                            prospect.vorname +
                                                            " I'm impressed by your success at " +
                                                            contact.item.Account
                                                              .Namen +
                                                            ". Eager to connect to exchange insights. I believe we can learn a lot from each other. Best, " +
                                                            user.name.split(
                                                              " "
                                                            )[0]
                                                          }
                                                        ></textarea>
                                                      </div>
                                                      <div className="grow flex justify-between mt-1 items-center">
                                                        <button
                                                          onClick={() => {
                                                            setActiveMessageId(
                                                              null
                                                            );
                                                          }}
                                                          className="border border-gray-500 text-xs gray-500 rounded-md p-2 hover:bg-gray-100"
                                                        >
                                                          Cancel
                                                        </button>
                                                        <div className="flex items-center">
                                                          <button
                                                            onClick={(
                                                              event
                                                            ) => {
                                                              event.preventDefault();
                                                              event.stopPropagation();
                                                              generateMessage(
                                                                prospect,
                                                                contact.item,
                                                                "linkedin",
                                                                currentProspectLanguage
                                                              );
                                                            }}
                                                            className="border border-gray-500 text-xs gray-500 rounded-md p-2 mr-2 hover:bg-gray-100"
                                                          >
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              viewBox="0 0 20 20"
                                                              fill="currentColor"
                                                              className="w-4 h-4"
                                                            >
                                                              <path
                                                                fillRule="evenodd"
                                                                d="M15.312 11.424a5.5 5.5 0 0 1-9.201 2.466l-.312-.311h2.433a.75.75 0 0 0 0-1.5H3.989a.75.75 0 0 0-.75.75v4.242a.75.75 0 0 0 1.5 0v-2.43l.31.31a7 7 0 0 0 11.712-3.138.75.75 0 0 0-1.449-.39Zm1.23-3.723a.75.75 0 0 0 .219-.53V2.929a.75.75 0 0 0-1.5 0V5.36l-.31-.31A7 7 0 0 0 3.239 8.188a.75.75 0 1 0 1.448.389A5.5 5.5 0 0 1 13.89 6.11l.311.31h-2.432a.75.75 0 0 0 0 1.5h4.243a.75.75 0 0 0 .53-.219Z"
                                                                clipRule="evenodd"
                                                              />
                                                            </svg>
                                                          </button>
                                                          <button
                                                            onClick={async (
                                                              event
                                                            ) => {
                                                              event.preventDefault();
                                                              event.stopPropagation();

                                                              let organization;
                                                              let person;

                                                              if (
                                                                connected.pipedrive
                                                              ) {
                                                                organization =
                                                                  await fetchPipedriveOrganizationByName(
                                                                    contact.item
                                                                      .Account
                                                                      .Namen
                                                                  );
                                                                if (
                                                                  organization ==
                                                                  null
                                                                ) {
                                                                  organization =
                                                                    await createPipedriveOrganization(
                                                                      contact.item
                                                                    );
                                                                }

                                                                person =
                                                                  await fetchPipedrivePersonByEmail(
                                                                    prospect.email
                                                                  );
                                                                if (
                                                                  person == null
                                                                ) {
                                                                  person =
                                                                    await createPipedrivePerson(
                                                                      prospect,
                                                                      organization.data
                                                                        ? organization
                                                                            .data
                                                                            .id
                                                                        : organization.id
                                                                    );
                                                                }

                                                                await logPipedriveActivity(
                                                                  person.data
                                                                    ? person
                                                                        .data.id
                                                                    : person.id,
                                                                  organization.data
                                                                    ? organization
                                                                        .data.id
                                                                    : organization.id,
                                                                  {
                                                                    subject:
                                                                      "Sent LinkedIn Invite",
                                                                    type: "task",
                                                                    done: true,
                                                                    note: message,
                                                                  }
                                                                );
                                                              }

                                                              setActiveMessageId(
                                                                null
                                                              );
                                                              navigator.clipboard.writeText(
                                                                message
                                                              );
                                                              window.open(
                                                                prospect.linkedin_url,
                                                                "_blank",
                                                                "noreferrer"
                                                              );
                                                            }}
                                                            className="border border-indigo-600 text-xs bg-indigo-600 text-white rounded-md p-2 hover:bg-indigo-700"
                                                          >
                                                            Send
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {(contact.item.Account.Beschreibung ||
                                      (contact.item.Enrichment &&
                                        contact.item.Enrichment
                                          .company_enrichment &&
                                        contact.item.Enrichment
                                          .company_enrichment.description)) && (
                                      <div>
                                        <button
                                          className="text-xs flex items-center mb-1 text-gray-500 hover:text-gray-900"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setDescriptionVisible(
                                              !isDescriptionVisible
                                            );
                                          }}
                                        >
                                          Description
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                            className="w-4 h-4 mb-0.5"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </button>
                                        <div
                                          className={`text-xs text-gray-500 mb-8 ${
                                            isDescriptionVisible
                                              ? ""
                                              : "truncate"
                                          }`}
                                        >
                                          {contact.item.Account.Beschreibung
                                            ? contact.item.Account.Beschreibung
                                            : contact.item.Enrichment
                                                .company_enrichment.description}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </li>
                          )
                      )
                    ) : (
                      <li
                        className="text-center text-gray-500 font-mono p-3 grayscale opacity-50"
                        key="None"
                      >
                        No accounts found 🧭
                      </li>
                    ))}
                </ul>
                {/*  Loading animation for fetching next page*/}
                <div className="mt-12 text-gray-400 text-sm font-mono">
                  {fetching ? (
                    <div className="flex justify-center">FETCHING TEST</div>
                  ) : (
                    <div className="flex justify-center">
                      No more accounts for you 🥲
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-center">
              <p className="flex-grow max-w-xl rounded-md border border-gray-200 bg-white p-8 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 py-3 text-xs font-medium text-gray-700 text-center">
                <span className="text-sm mr-2">👋</span>Please connect your CRM
              </p>
              <button
                title="Import CSV"
                onClick={() => setShowCsvUpload(true)}
                className="rounded-md border border-gray-200 from-indigo-400 to-indigo-600 bg-white ml-1 aspect-square h-[46px] flex justify-center items-center text-indigo-600 focus:border-indigo-500 outline-none hover:border-indigo-600 hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:text-stone-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </Container>
      <Transition appear show={fieldSelectionModal} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="bg-stone-100 w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                      <div className="w-full max-w-md">
                        <div>
                          <h2 className="text-center text-3xl tracking-tight text-gray-900">
                            Map your Pipedrive fields
                          </h2>
                          <div className="mt-10 space-y-6 tracking-tight text-gray-900 text-left text-sm font-mono text-center">
                            Interface coming soon
                            {/*{organizationFields.map((field, index) => (*/}
                            {/*  <div key={index}>*/}
                            {/*    <label className="block text-gray-900">*/}
                            {/*      <input*/}
                            {/*        type="checkbox"*/}
                            {/*        className="mr-2"*/}
                            {/*        checked={field.selected}*/}
                            {/*        onChange={(event) => {*/}
                            {/*          setOrganizationFields(*/}
                            {/*            organizationFields.map((item, i) =>*/}
                            {/*              i === index*/}
                            {/*                ? {*/}
                            {/*                    ...item,*/}
                            {/*                    selected: event.target.checked,*/}
                            {/*                  }*/}
                            {/*                : item*/}
                            {/*            )*/}
                            {/*          );*/}
                            {/*        }}*/}
                            {/*      />*/}
                            {/*      {field.name}*/}
                            {/*    </label>*/}
                            {/*  </div>*/}
                            {/*))}*/}
                            {/*{personFields.map((field, index) => (*/}
                            {/*  <div key={index}>*/}
                            {/*    <label className="block text-gray-900">*/}
                            {/*      <input*/}
                            {/*        type="checkbox"*/}
                            {/*        className="mr-2"*/}
                            {/*        checked={field.selected}*/}
                            {/*        onChange={(event) => {*/}
                            {/*          setPersonFields(*/}
                            {/*            personFields.map((item, i) =>*/}
                            {/*              i === index*/}
                            {/*                ? {*/}
                            {/*                    ...item,*/}
                            {/*                    selected: event.target.checked,*/}
                            {/*                  }*/}
                            {/*                : item*/}
                            {/*            )*/}
                            {/*          );*/}
                            {/*        }}*/}
                            {/*      />*/}
                            {/*      {field.name}*/}
                            {/*    </label>*/}
                            {/*  </div>*/}
                            {/*))}*/}
                            <button
                              type="button"
                              className="group relative font-sans flex w-full justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-2"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  JSON.stringify({
                                    organizationFields,
                                    personFields,
                                  })
                                );
                              }}
                            >
                              Copy to clipboard
                            </button>
                          </div>
                        </div>
                        <div className="mt-10 justify-center flex">
                          <button
                            onClick={() => {
                              setFieldSelectionModal(false);
                            }}
                            type="button"
                            className="group relative font-sans flex justify-center rounded-md bg-gray-300 py-2 px-3 text-sm font-semibold text-white hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

export default Hub;
