import React from "react";
import { useMsal } from "@azure/msal-react";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  const { isAuthenticated, logout } = useAuth0();

  const handleLogout = (logoutType) => {
    if (logoutType === "redirect") {
      if (localStorage.getItem("hubspotAccessToken")) {
        localStorage.removeItem("hubspotAccessToken");
        localStorage.removeItem("hubspotRefreshToken");
        localStorage.removeItem("hubspotExpiresAt");
        window.location.href = "/composer";
      } else if (localStorage.getItem("salesforceAccessToken")) {
        localStorage.removeItem("salesforceAccessToken");
        localStorage.removeItem("salesforceInstanceUrl");
        localStorage.removeItem("salesforceExpiryTime");
        localStorage.removeItem("salesforceId");
        localStorage.removeItem("salesforceName");
        localStorage.removeItem("salesforceRefreshToken");
        window.location.href = "/composer";
      } else if (isAuthenticated) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      } else {
        instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      }
    }
  };

  return (
    <button
      className="flex-grow flex h-full w-full"
      onClick={() => handleLogout("redirect")}
    >
      Sign out
    </button>
  );
};
