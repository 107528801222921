import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../msalAuthConfig";
import { classNames } from "../pages/Main";
import { useLocalStorage } from "../pages/Composer";

const sfID = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
const hsID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
const pdID = process.env.REACT_APP_PIPEDRIVE_CLIENT_ID;
const orID = process.env.REACT_APP_OUTREACH_CLIENT_ID;

export const SignInButton = () => {
  const { instance } = useMsal();

  const [connected, setConnected] = useLocalStorage("connectedAccounts", {
    microsoft: false,
    salesforce: false,
    hubspot: false,
    pipedrive: false,
    linkedin: false,
    outreach: false,
  });

  const handleLogin = (loginType) => {
    if (loginType === "microsoft") {
      instance.loginRedirect(loginRequest).catch((e) => {
        // console.log(e);
      });
    } else if (loginType === "salesforce") {
      console.log("salesforce:", sfID);
      window.location.href =
        "https://login.salesforce.com/services/oauth2/authorize?" +
        new URLSearchParams({
          response_type: "token",
          client_id: sfID,
          redirect_uri:
            window.location.protocol +
            "//" +
            window.location.host +
            "/callback/salesforce/oauth2",
        });
    } else if (loginType === "hubspot") {
      window.location.href =
        "https://app-eu1.hubspot.com/oauth/authorize?" +
        new URLSearchParams({
          client_id: hsID,
          scope:
            "tickets crm.objects.contacts.read crm.objects.contacts.write crm.objects.companies.write crm.objects.companies.read crm.objects.deals.read crm.objects.deals.write crm.objects.owners.read crm.objects.line_items.read crm.objects.line_items.write",
          // "transactional-email tickets sales-email-read crm.objects.contacts.read crm.objects.contacts.write crm.objects.companies.write crm.schemas.contacts.read crm.objects.companies.read crm.objects.deals.read crm.objects.deals.write crm.schemas.contacts.write crm.objects.owners.read crm.objects.line_items.read crm.objects.line_items.write",
          redirect_uri:
            window.location.protocol +
            "//" +
            window.location.host +
            "/callback/hubspot/oauth2",
        });
    } else if (loginType === "pipedrive") {
      window.location.href =
        "https://oauth.pipedrive.com/oauth/authorize?" +
        new URLSearchParams({
          client_id: pdID,
          redirect_uri:
            window.location.protocol +
            "//" +
            window.location.host +
            "/callback/pipedrive/oauth2",
        });
    } else if (loginType === "outreach") {
      window.location.href =
        "https://api.outreach.io/oauth/authorize?" +
        new URLSearchParams({
          client_id: orID,
          redirect_uri:
            window.location.protocol +
            "//" +
            window.location.host +
            "/callback/outreach/oauth2",
          response_type: "code",
          scope:
            "accounts.all emailAddresses.all prospects.all sequences.all stages.all tags.all tasks.all users.all templates.all snippets.all sequenceStates.all mailboxes.all",
        });
    } else if (loginType === "linkedin") {
      // get unipile auth URL from backend
      fetch("https://api.compelling.ai/linkedin/LinkedInAPI", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "createHostedAuthLink",
          name: JSON.parse(localStorage.getItem("local-user"))["sub"],
        }),
      }).then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error " + response.status);
        } else {
          response.json().then((data) => {
            window.location.href = data.url;
          });
        }
      });
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Connect
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-200"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white ring-1 ring-black-5 border border-indigo-600 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleLogin("microsoft")}
                  className={classNames(
                    active || connected.microsoft
                      ? "text-gray-900 opacity-100 grayscale-0"
                      : "text-gray-700 opacity-50 grayscale",
                    "flex w-full px-4 py-2 text-left text-sm items-center hover:bg-gray-100"
                  )}
                >
                  <div className="w-1/3 items-center justify-center h-full inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 23 23"
                      height="20"
                    >
                      <path fill="#f3f3f3" d="M0 0h23v23H0z" />
                      <path fill="#f35325" d="M1 1h10v10H1z" />
                      <path fill="#81bc06" d="M12 1h10v10H12z" />
                      <path fill="#05a6f0" d="M1 12h10v10H1z" />
                      <path fill="#ffba08" d="M12 12h10v10H12z" />
                    </svg>
                  </div>
                  <span>Microsoft</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleLogin("salesforce")}
                  className={classNames(
                    active || connected.salesforce
                      ? "text-gray-900 opacity-100 grayscale-0"
                      : "text-gray-700 opacity-50 grayscale",
                    "flex w-full px-4 py-2 text-left text-sm items-center hover:bg-gray-100"
                  )}
                >
                  <div className="w-1/3 items-center justify-center h-full inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="20"
                    >
                      <g transform="translate(-148 -447)">
                        <path
                          d="M163.004 447.649c1.796 0 3.38 1.027 4.226 2.54.74-.324 1.532-.54 2.378-.54 3.275 0 5.917 2.702 5.917 6.054 0 3.351-2.642 6.054-5.917 6.054a7.74 7.74 0 0 1-1.163-.108 4.34 4.34 0 0 1-3.803 2.27c-.687 0-1.321-.162-1.902-.433a4.897 4.897 0 0 1-4.544 3.028 4.94 4.94 0 0 1-4.649-3.298c-.317.054-.634.108-.95.108-2.537 0-4.597-2.108-4.597-4.702 0-1.73.898-3.244 2.272-4.054a5.829 5.829 0 0 1-.423-2.163c0-2.973 2.377-5.405 5.336-5.405 1.849.054 3.381.865 4.332 2.162.898-.919 2.113-1.513 3.487-1.513Z"
                          id="Path"
                          fill="#00A1E0"
                        />
                      </g>
                    </svg>
                  </div>
                  <span>salesforce</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleLogin("hubspot")}
                  className={classNames(
                    active || connected.hubspot
                      ? "text-gray-900 opacity-100 grayscale-0"
                      : "text-gray-700 opacity-50 grayscale",
                    "flex w-full px-4 py-2 text-left text-sm items-center hover:bg-gray-100"
                  )}
                >
                  <div className="w-1/3 items-center justify-center h-full inline-flex">
                    <svg id="hupspot-logo" viewBox="0 0 242.04 257" height="20">
                      <defs>
                        <style>
                          {`#hupspot-logo .cls-1 {
                          fill: none;
                        }`}

                          {`#hupspot-logo .cls-2 {
                          clip-path: url(#a);
                        }`}

                          {`#hupspot-logo .cls-3 {
                          fill: #ff7a59;
                          opacity: 1;
                        }`}
                        </style>
                        <clipPath id="a">
                          <rect
                            className="cls-1"
                            x="42.87"
                            y="46.9"
                            width="156.42"
                            height="163.35"
                          ></rect>
                        </clipPath>
                      </defs>
                      <g className="cls-2">
                        <g className="cls-2">
                          <path
                            className="cls-3"
                            d="M162.79,101.25V82a14.84,14.84,0,0,0,8.56-13.38v-.45a14.87,14.87,0,0,0-14.83-14.83h-.45a14.87,14.87,0,0,0-14.83,14.83v.45A14.84,14.84,0,0,0,149.8,82v19.29a42.06,42.06,0,0,0-20,8.8L76.86,68.86a16.89,16.89,0,1,0-7.91,10.29L121,119.67a42.18,42.18,0,0,0,.65,47.56l-15.84,15.84a13.6,13.6,0,0,0-3.93-.64,13.75,13.75,0,1,0,13.75,13.75,13.56,13.56,0,0,0-.64-3.93l15.67-15.67a42.25,42.25,0,1,0,32.1-75.33m-6.49,63.42A21.68,21.68,0,1,1,178,143a21.68,21.68,0,0,1-21.68,21.68"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <span>HubSpot</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleLogin("pipedrive")}
                  className={classNames(
                    active || connected.pipedrive
                      ? "text-gray-900 opacity-100 grayscale-0"
                      : "text-gray-700 opacity-50 grayscale",
                    "flex w-full px-4 py-2 text-left text-sm items-center hover:bg-gray-100"
                  )}
                >
                  <div className="w-1/3 items-center justify-center h-full inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 254 254"
                      fill="none"
                    >
                      <rect width="254" height="254" rx="127" fill="#017737" />
                      <path
                        d="M196.68 105.85C196.68 140.21 174.51 163.71 142.81 163.71C127.74 163.71 115.32 157.5 110.67 149.74L110.89 157.72V206.93H78.53V79.69C78.53 77.92 78.09 77.47 76.09 77.47H65V50.43H92.05C104.46 50.43 107.57 61.07 108.45 65.73C113.33 57.53 126.18 48 144.36 48C175.62 47.99 196.68 71.26 196.68 105.85ZM163.65 106.07C163.65 87.67 151.68 75.04 136.6 75.04C124.19 75.04 110.22 83.24 110.22 106.3C110.22 121.37 118.64 137.11 136.16 137.11C149.02 137.1 163.65 127.79 163.65 106.07Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <span>Pipedrive</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleLogin("linkedin")}
                  className={classNames(
                    active || connected.linkedin
                      ? "text-gray-900 opacity-100 grayscale-0"
                      : "text-gray-700 opacity-50 grayscale",
                    "flex w-full px-4 py-2 text-left text-sm items-center hover:bg-gray-100"
                  )}
                >
                  <div className="w-1/3 items-center justify-center h-full inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      data-supported-dps="27x27"
                      fill="currentColor"
                      width="20"
                      height="20"
                      focusable="false"
                    >
                      <path
                        fill="#0A66C2"
                        d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"
                      ></path>
                    </svg>
                  </div>
                  <span>LinkedIn</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleLogin("outreach")}
                  className={classNames(
                    active || connected.outreach
                      ? "text-gray-900 opacity-100 grayscale-0"
                      : "text-gray-700 opacity-50 grayscale",
                    "flex w-full px-4 py-2 text-left text-sm items-center" // hover:bg-gray-100"
                  )}
                >
                  <div className="w-1/3 items-center justify-center h-full inline-flex">
                    <img
                      className="h-5 w-5"
                      src="https://images.ctfassets.net/7rbn0raz0t75/2iNzfRoSih00SbUSSimyYJ/5e2d6e8678dfda59950eb2e2f5e2f037/Outreach-icon-color?w=640&q=100"
                    />
                  </div>
                  <span>Outreach</span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
