import React, { Fragment, useEffect, useState } from "react";
import Nav from "../components/Nav";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "./Composer";
import { Box, Container, Divider } from "@mui/material";
import { classNames } from "../pages/Main";

const Messages = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const name = user ? user.name : "Stranger";

  const [compellingUser, setCompellingUser] = useLocalStorage(
    "local-compelling-user",
    { fullName: "Stranger" }
  );

  const [messages, setMessages] = useState(null);
  const [invitations, setInvitations] = useState([]);
  const [currentProspects, setCurrentProspects] = useState(
    JSON.parse(localStorage.getItem("local-current-prospects")).filter(
      (prospect) =>
        prospect.linkedin_profile !== undefined &&
        prospect.linkedin_profile !== null &&
        prospect.linkedin_profile !== ""
    )
  );
  const [currentUserLinkedInProfile, setCurrentUserLinkedInProfile] = useState(
    {}
  );
  const [displayChat, setDisplayChat] = useState(false);

  const [linkedinProfileIds, setLinkedinProfileIds] = useState(
    currentProspects.map((prospect) => prospect.linkedin_profile.provider_id)
  );

  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {
    if (
      selectedChat === null &&
      messages !== null &&
      currentProspects !== null
    ) {
      // remove all messages where is_sender is true
      let receivedMessages = Object.entries(messages).map(
        ([chatId, senderMessages]) => {
          return senderMessages.filter(
            (message) =>
              message.sender_id !== currentUserLinkedInProfile.provider_id
          );
        }
      );
      if (Object.values(receivedMessages)[0] !== undefined) {
        setSelectedChat({
          chat_id: Object.values(receivedMessages)[0][0].chat_id,
          otherParty:
            currentProspects.find(
              (prospect) =>
                prospect.linkedin_profile.provider_id ===
                Object.values(receivedMessages)[0][0].sender_id
            ).vorname +
            " " +
            currentProspects.find(
              (prospect) =>
                prospect.linkedin_profile.provider_id ===
                Object.values(receivedMessages)[0][0].sender_id
            ).name,
        });
      }
    }
  }, [messages, currentProspects]);

  useEffect(() => {
    console.log(selectedChat);
  }, [selectedChat]);

  useEffect(() => {
    const getOwnProfile = async () => {
      try {
        fetch("https://api.compelling.ai/linkedin/LinkedInAPI", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "getOwnProfile",
            accound_id: JSON.parse(localStorage.getItem("local-user"))[
              "https://compelling.ai/claims/user_metadata"
            ]["linkedinAuthId"],
          }),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          } else {
            response.json().then((data) => {
              console.log(data);
              setCurrentUserLinkedInProfile(data);
            });
          }
        });
      } catch (error) {
        console.error(error.message);
      }
    };
    getOwnProfile();

    const getInvitations = async () => {
      if (
        localStorage.getItem("local-user") !== null &&
        JSON.parse(localStorage.getItem("local-user"))[
          "https://compelling.ai/claims/user_metadata"
        ]["linkedinAuthId"] !== undefined
      ) {
        try {
          fetch("https://api.compelling.ai/linkedin/LinkedInAPI", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              action: "getAllInvitationsPending",
              accound_id: JSON.parse(localStorage.getItem("local-user"))[
                "https://compelling.ai/claims/user_metadata"
              ]["linkedinAuthId"],
            }),
          }).then((response) => {
            if (!response.ok) {
              throw new Error("HTTP error " + response.status);
            } else {
              response.json().then((data) => {
                console.log(data);
                // check if invitation is from a current prospect
                let displayInvitations = [];
                data.items.forEach((invitation) => {
                  console.log(invitation);
                  if (linkedinProfileIds.includes(invitation.invited_user_id)) {
                    console.log("invitation from current prospect");
                    displayInvitations.push(invitation);
                  }
                });
                setInvitations(displayInvitations);
              });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    getInvitations();
    const getMessages = async () => {
      if (
        localStorage.getItem("local-user") !== null &&
        JSON.parse(localStorage.getItem("local-user"))[
          "https://compelling.ai/claims/user_metadata"
        ]["linkedinAuthId"] !== undefined
      ) {
        try {
          fetch("https://api.compelling.ai/linkedin/LinkedInAPI", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              action: "getAllMessages",
              accound_id: JSON.parse(localStorage.getItem("local-user"))[
                "https://compelling.ai/claims/user_metadata"
              ]["linkedinAuthId"],
            }),
          }).then((response) => {
            if (!response.ok) {
              throw new Error("HTTP error " + response.status);
            } else {
              response.json().then((data) => {
                // check if one message is from a current prospect, if yes, get the chat_id from the message and add all messages from this chat_id to messages
                console.log(data);
                let displayMessages = {};
                let processedChats = new Set();
                data.items.forEach((message) => {
                  if (
                    linkedinProfileIds.includes(message.sender_id) &&
                    !processedChats.has(message.chat_id)
                  ) {
                    // get all messages from this chat_id
                    data.items.forEach((message2) => {
                      if (message2.chat_id === message.chat_id) {
                        if (!displayMessages[message.chat_id]) {
                          displayMessages[message.chat_id] = [];
                        }
                        displayMessages[message.chat_id].push(message2);
                      }
                    });
                    processedChats.add(message.chat_id);
                  }
                });
                // invert the order of the messages
                Object.entries(displayMessages).forEach(
                  ([chatId, messages]) => {
                    displayMessages[chatId] = messages.reverse();
                  }
                );
                setMessages(displayMessages);
              });
            }
          });
        } catch (error) {
          console.error(error.message);
        }
      }
    };
    getMessages();
  }, []);

  return (
    <>
      <Nav compellingUser={compellingUser} />
      <div className="">
        <div
          role="tablist"
          aria-label="Tabs"
          className="flex justify-center min-w-full fixed z-10 bg-[#f0f0f0]/50 backdrop-blur-md pt-[128px]"
        >
          <div className="border-b border-gray-300 rounded-b-md max-w-2xl flex justify-center flex-grow text-sm">
            <button
              type="button"
              className={classNames(
                "w-1/2 py-2 mb-1 mx-1 rounded-bl-md rounded-tl-md text-xs",
                !displayChat
                  ? "bg-compelling-bright text-white"
                  : "bg-gradient-to-b from-transparent from-0% to-gray-200 hover:to-compelling-bright/20"
              )}
              onClick={() => setDisplayChat(false)}
            >
              Open invitations
            </button>
            <button
              type="button"
              className={classNames(
                "w-1/2 py-2 mb-1 mx-1 rounded-br-md rounded-tr-md text-xs",
                displayChat
                  ? "bg-compelling-bright text-white"
                  : "bg-gradient-to-b from-transparent from-0% to-gray-200 hover:to-compelling-bright/20"
              )}
              onClick={() => setDisplayChat(true)}
            >
              Your Chats
            </button>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="basis-2/3 mb-auto p-10 mt-[168px] sticky">
            <div
              className={classNames(
                "mx-4 mt-5",
                displayChat ? "block" : "hidden"
              )}
            >
              <div className="basis-1/3 fixed w-1/6">
                <div>
                  <ul>
                    {messages !== null &&
                      Object.entries(messages).map(
                        ([chatId, senderMessages]) => {
                          let otherPartyMessage = senderMessages.find(
                            (message) =>
                              message.sender_id !==
                              currentUserLinkedInProfile.provider_id
                          );
                          let otherParty = otherPartyMessage
                            ? currentProspects.find(
                                (prospect) =>
                                  otherPartyMessage.sender_id ===
                                  prospect.linkedin_profile.provider_id
                              )
                            : null;
                          let otherPartyName = otherParty
                            ? otherParty.vorname + " " + otherParty.name
                            : compellingUser.fullName;
                          return (
                            <li key={chatId} className="pb-2">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedChat({
                                    chat_id: chatId,
                                    otherParty: otherPartyName,
                                  });
                                }}
                                className={classNames(
                                  "block w-full px-4 py-2 text-sm  rounded-lg hover:bg-white hover:text-indigo-600 focus:z-10 focus:ring-2 focus:ring-indigo-600 focus:text-indigo-600",
                                  selectedChat &&
                                    selectedChat.otherParty === otherPartyName
                                    ? "bg-white text-indigo-600"
                                    : "text-gray-900 bg-gray-100"
                                )}
                              >
                                {otherPartyName}
                              </button>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              </div>
              <div className="float-right w-4/6">
                <div className="bg-gradient-to-b rounded-md">
                  <div className="bg-white text-stone-800 border-r-4 border-b-4 border-stone-200 border-opacity-50 rounded-md shadow-xl min-h-80 text-sm p-4">
                    {messages !== null &&
                      selectedChat !== null &&
                      Object.entries(messages).map(
                        ([chatId, senderMessages]) => {
                          const filteredMessages = senderMessages.filter(
                            (message) =>
                              message.chat_id === selectedChat.chat_id
                          );
                          return (
                            <Fragment key={chatId}>
                              {filteredMessages.map((message) => (
                                <div key={message.id} className="py-4">
                                  {/*{message.attachments.length > 0 && (*/}
                                  {/*  <p>*/}
                                  {/*    <strong>Attachment:</strong>{" "}*/}
                                  {/*    {message.attachments[0].type} -{" "}*/}
                                  {/*    {message.attachments[0].url}*/}
                                  {/*  </p>*/}
                                  {/*)}*/}
                                  <div className="flex items-start gap-2.5">
                                    <img
                                      className="w-8 h-8 rounded-full float-left mr-4 mt-0.5"
                                      src={
                                        currentProspects.find(
                                          (prospect) =>
                                            prospect.linkedin_profile
                                              .provider_id === message.sender_id
                                        )
                                          ? currentProspects.find(
                                              (prospect) =>
                                                prospect.linkedin_profile
                                                  .provider_id ===
                                                message.sender_id
                                            ).linkedin_profile
                                              .profile_picture_url
                                          : `https://ui-avatars.com/api/?name=${compellingUser.fullName}&background=8888FF&color=464FE5`
                                      }
                                      alt="LinkedIn Profile"
                                    />
                                    <div className="flex flex-col w-full max-w-[320px] leading-1.5 p-4 bg-gray-100 rounded-e-xl rounded-es-xl">
                                      <div className="flex items-center space-x-2 rtl:space-x-reverse">
                                        <span className="text-sm font-semibold text-gray-900">
                                          {message.sender_id !==
                                            currentUserLinkedInProfile.provider_id &&
                                          currentProspects.find(
                                            (prospect) =>
                                              prospect.linkedin_profile
                                                .provider_id ===
                                              message.sender_id
                                          )
                                            ? currentProspects.find(
                                                (prospect) =>
                                                  prospect.linkedin_profile
                                                    .provider_id ===
                                                  message.sender_id
                                              ).vorname +
                                              " " +
                                              currentProspects.find(
                                                (prospect) =>
                                                  prospect.linkedin_profile
                                                    .provider_id ===
                                                  message.sender_id
                                              ).name
                                            : compellingUser.fullName}
                                        </span>
                                        <span className="text-sm font-normal text-gray-500">
                                          {
                                            //  fromat timestamp to show only date
                                            new Date(
                                              message.timestamp
                                            ).toLocaleDateString()
                                          }
                                        </span>
                                        <span className="text-sm font-normal text-gray-500">
                                          {
                                            //  fromat timestamp to show only time without seconds
                                            new Date(
                                              message.timestamp
                                            ).toLocaleTimeString([], {
                                              hour: "2-digit",
                                              minute: "2-digit",
                                            })
                                          }
                                        </span>
                                      </div>
                                      <p className="text-sm font-normal py-2.5 text-gray-900">
                                        {message.text}
                                      </p>
                                      <span className="text-sm font-normal text-gray-500">
                                        {message.seen === 1
                                          ? "Seen"
                                          : "Delivered"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Fragment>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(!displayChat ? "block" : "hidden")}>
              <div className="container mt-5">
                <div>
                  {/*skeleton loader*/}
                  {invitations.length === 0 && (
                    <div className="bg-gradient-to-b bg-gray-200 rounded-md mb-4 mx-4 animate-pulse">
                      <div className="px-8 py-4">
                        <div>
                          <div className="bg-gray-300 h-5 w-1/6 rounded-md mb-2"></div>
                          <div className="bg-gray-300 h-10 w-full rounded-md"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {invitations.map((invitation) => (
                    <Fragment key={invitation.id}>
                      <div className="bg-gradient-to-b rounded-md mb-4 mx-4">
                        <div className="bg-white text-stone-800 border-r-4 border-b-4 border-stone-200 border-opacity-50 rounded-md shadow-xl min-h-80 text-sm">
                          <div className="px-8 py-4">
                            <img
                              className="w-8 h-8 rounded-full float-left mr-4 mt-0.5"
                              src={
                                currentProspects.find(
                                  (prospect) =>
                                    prospect.linkedin_profile.provider_id ===
                                    invitation.invited_user_id
                                ).linkedin_profile.profile_picture_url
                              }
                              alt="LinkedIn Profile Picture"
                            />
                            <p className="ml-12">
                              <span className="block text-gray-400 text-xs">
                                {invitation.date}
                              </span>
                              <strong>
                                <a
                                  href={
                                    "https://linkedin.com/in/" +
                                    invitation.invited_user_id
                                  }
                                  target="_blank"
                                >
                                  {currentProspects.find(
                                    (prospect) =>
                                      prospect.linkedin_profile.provider_id ===
                                      invitation.invited_user_id
                                  ) &&
                                    currentProspects.find(
                                      (prospect) =>
                                        prospect.linkedin_profile
                                          .provider_id ===
                                        invitation.invited_user_id
                                    ).vorname +
                                      " " +
                                      currentProspects.find(
                                        (prospect) =>
                                          prospect.linkedin_profile
                                            .provider_id ===
                                          invitation.invited_user_id
                                      ).name}
                                </a>
                                :
                              </strong>{" "}
                              <span
                                className={
                                  invitation.invitation_text !== "" &&
                                  invitation.invitation_text != null
                                    ? "text-gray-600"
                                    : "text-gray-400"
                                }
                              >
                                {invitation.invitation_text !== "" &&
                                invitation.invitation_text != null
                                  ? invitation.invitation_text
                                  : "No message"}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
