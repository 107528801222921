import React from "react";
import { Link } from "react-router-dom";

const asciiString = `
    d8888   .d8888b.      d8888  
   d8P888  d88P  Y88b    d8P888  
  d8P 888  888    888   d8P 888  
 d8P  888  888    888  d8P  888  
d88   888  888    888 d88   888  
8888888888 888    888 8888888888 
      888  Y88b  d88P       888  
      888   "Y8888P"        888  
`;

const PageNotFound = () => {
  const asciiChars = asciiString.split("");
  return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <section className="bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <div className="mb-4 font-mono text-xs tracking-tight text-compelling-bright">
              <pre className="">
                {asciiChars.map((char, index) => (
                  <span
                    className="hover:text-indigo-200 hover: select-none"
                    key={index}
                  >
                    {char}
                  </span>
                ))}
              </pre>
            </div>
            <p className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">
              Something's missing.
            </p>
            <p className="mb-4 text-lg font-light text-gray-400">
              Sorry, we can't find that page.{" "}
            </p>
            <a
              href="/"
              className="inline-flex text-white bg-indigo-600 hover:bg-indigo-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-indigo-900 my-4"
            >
              Back to Homepage
            </a>
          </div>
        </div>
      </section>
      <footer className="max-w-7xl absolute bottom-0 mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="flex flex-wrap justify-center text-sm"
          aria-label="Footer"
        >
          <span className="text-white text-opacity-50">
            © 2023 seekwhens GmbH
          </span>
          <Link to="https://www.seekwhens.com/impressum/" target="_blank">
            <span className="pl-5 underline text-white text-opacity-50 hover:text-opacity-90">
              Legal Notice
            </span>
          </Link>
        </nav>
      </footer>
    </div>
  );
};

export default PageNotFound;
