import React from "react";

export default function CallbackContent() {
  return (
    //  Universal callback page showing "Connecting ..." message in the perfect center of the page
    <div className="flex items-center justify-center h-screen">
      <div className="text-2xl font-mono">Connecting ...</div>
    </div>
  );
}
