async function fetchApi(url, options) {
  const accessToken = localStorage.getItem("salesforceAccessToken");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const response = await fetch(url, { headers, ...options });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export async function fetchData() {
  const instanceUrl = localStorage.getItem("salesforceInstanceUrl");
  const contacts = await fetchApi(
    `${instanceUrl}/services/data/v52.0/query?q=SELECT+Id,Name,Email,Account.Name,Title+FROM+Contact`
  );
  const products = await fetchApi(
    `${instanceUrl}/services/data/v52.0/query?q=SELECT+Id,Name,ProductCode,Description+FROM+Product2+WHERE+ProductCode='SoSafe-1'`
  );
  const data = { contacts, products };
  console.log(data);
  return await data;
}
