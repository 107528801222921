import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

import { Card, CardContent, CardHeader } from "../components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "../components/ui/chart";
import dayjs from "dayjs";
import Nav from "../components/Nav";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "./Composer";

const calculateDateRanges = () => {
  const now = dayjs();
  return {
    last_7_days: {
      start: now.subtract(7, "days").format("YYYY-MM-DD"),
      end: now.format("YYYY-MM-DD"),
    },
    month_to_date: {
      start: now.startOf("month").format("YYYY-MM-DD"),
      end: now.format("YYYY-MM-DD"),
    },
    last_30_days: {
      start: now.subtract(30, "days").format("YYYY-MM-DD"),
      end: now.format("YYYY-MM-DD"),
    },
    last_90_days: {
      start: now.subtract(90, "days").format("YYYY-MM-DD"),
      end: now.format("YYYY-MM-DD"),
    },
    last_180_days: {
      start: now.subtract(180, "days").format("YYYY-MM-DD"),
      end: now.format("YYYY-MM-DD"),
    },
    last_365_days: {
      start: now.subtract(365, "days").format("YYYY-MM-DD"),
      end: now.format("YYYY-MM-DD"),
    },
  };
};

const Analytics = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const name = user ? user.name : "Stranger";

  const [compellingUser, setCompellingUser] = useLocalStorage(
    "local-compelling-user",
    { fullName: "Stranger" }
  );

  const campaignId = user["https://compelling.ai/claims/campaign_ids"][0];

  const [summaryData, setSummaryData] = useState(null);
  const [timedData, setTimedData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState("month_to_date");
  const [chartData, setChartData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dateRanges = calculateDateRanges();

  useEffect(() => {
    setStartDate(new Date(dateRanges[dateRange].start));
    setEndDate(new Date(dateRanges[dateRange].end));
  }, []);

  const fetchSummaryData = async () => {
    try {
      const response = await fetch(
        "https://api.compelling.ai/instantly/OppyAnalytics",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
          }),
        }
      );

      const result = await response.json();
      setSummaryData(result);
    } catch (error) {
      setError(error.toString());
    }
  };

  const fetchTimedData = async (start, end) => {
    try {
      const response = await fetch(
        "https://api.compelling.ai/instantly/OppyAnalytics",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: campaignId,
            start_date: start,
            end_date: end,
          }),
        }
      );

      const result = await response.json();
      setTimedData(result);
    } catch (error) {
      setError(error.toString());
    }
  };

  const fetchAllCampaignAnalytics = async () => {
    try {
      const response = await fetch(
        "https://api.compelling.ai/instantly/OppyAnalytics",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            time_period: dateRange,
          }),
        }
      );

      const result = await response.json();
      setAllData(result.campaign_data_mongo.data);
      return result.campaign_data_mongo.data;
    } catch (error) {
      setError(error.toString());
    }
  };

  const getCampaignDataById = (campaigns, campaignId) => {
    let selectedCampaigns = {};
    for (let i = 0; i < campaigns.length; i++) {
      if (campaigns[i].campaign === campaignId) {
        // combine the data from all the campaigns
        selectedCampaigns = { ...selectedCampaigns, ...campaigns[i] };
        console.log(selectedCampaigns);
      }
    }

    return selectedCampaigns;
  };

  useEffect(() => {
    const { start, end } = dateRanges[dateRange];
    fetchSummaryData();
    fetchTimedData(start, end);
    fetchAllCampaignAnalytics().then((data) => {
      updateChartData(data);
    });
    setLoading(false);
  }, [dateRange]);

  if (loading) {
    return <div className="text-xl">Loading...</div>;
  }

  if (!campaignId) {
    return <div className="text-xl">No campaign found.</div>;
  }

  if (error) {
    return <div className="text-xl">Error: {error}</div>;
  }

  if (!summaryData || !allData || !timedData) {
    return null;
  }

  function updateChartData(freshData) {
    const campaignData = getCampaignDataById(freshData, campaignId);

    // const startDate = new Date(campaignData.month_start);
    // const endDateKeys = Object.keys(campaignData)
    //   .filter((key) => key.match(/^\d{4}-\d{2}-\d{2}$/))
    //   .sort();
    // const endDate = new Date(endDateKeys[endDateKeys.length - 1]);

    // Function to generate all dates between two dates
    function generateDateRange(startDate, endDate) {
      const dates = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().split("T")[0]); // Format date to match your keys
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    }

    // Generate all dates in the range
    const allDates = generateDateRange(
      new Date(dateRanges[dateRange].start),
      new Date(dateRanges[dateRange].end)
    );

    // Map over all dates and create chart data
    setChartData(
      allDates.map((date) => {
        const dayData = campaignData[date] || {}; // Get data for the day or default to an empty object
        return {
          date,
          sent: Object.values(dayData.sent || {}).reduce(
            (acc, curr) => acc + Object.values(curr).reduce((a, b) => a + b, 0),
            0
          ),
          replies: Object.values(dayData.reply_volume || {}).reduce(
            (acc, curr) => acc + Object.values(curr).reduce((a, b) => a + b, 0),
            0
          ),
        };
      })
    );
    console.log(chartData);
  }

  const {
    campaign_name,
    total_leads,
    leads_who_read,
    leads_who_replied,
    bounced,
    unsubscribed,
    completed,
  } = summaryData;

  const contacted = timedData[0].new_leads_contacted;

  const totalSent = chartData.reduce((acc, curr) => acc + curr.sent, 0);
  const totalReplies = chartData.reduce((acc, curr) => acc + curr.replies, 0);
  const replyRate = contacted
    ? ((totalReplies / contacted) * 100).toFixed(1)
    : 0;

  const chartConfig = {
    sent: {
      label: "Sent",
      color: "hsl(var(--chart-1))",
    },
    replies: {
      label: "Replies",
      color: "hsl(var(--chart-2))",
    },
  };

  return (
    <>
      <Nav compellingUser={compellingUser} />
      <div className="p-20 pt-40">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-5 items-center">
          <div className="text-2xl font-bold">{campaign_name}</div>
          <div></div>
          <div>
            <Select
              value={dateRange}
              onValueChange={(value) => setDateRange(value)}
            >
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectItem value="month_to_date">Month to date</SelectItem>
                <SelectItem value="last_7_days">Last 7 days</SelectItem>
                <SelectItem value="last_30_days">Last 4 weeks</SelectItem>
                <SelectItem value="last_90_days">Last 3 months</SelectItem>
                <SelectItem value="last_180_days">Last 6 months</SelectItem>
                <SelectItem value="last_365_days">Last 12 months</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-5">
          <Card>
            <CardHeader>
              <div className="text-lg font-medium">Total Leads</div>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{total_leads}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <div className="text-lg font-medium">Contacted</div>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{contacted}</div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <div className="text-lg font-medium">Opened</div>
            </CardHeader>
            <CardContent>
              <div
                className={
                  leads_who_read === 0
                    ? "text-2xl font-bold text-gray-300"
                    : "text-2xl font-bold"
                }
              >
                {leads_who_read === 0 ? "Not tracked" : leads_who_read}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <div className="text-lg font-medium">Reply Rate</div>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold flex items-center">
                {replyRate}%
                <span className="text-sm ml-2 font-normal">
                  | {totalReplies} Replies
                </span>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <div className="text-lg font-medium">Bounced</div>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{bounced || "N/A"}</div>
            </CardContent>
          </Card>
          {/*<Card>*/}
          {/*  <CardHeader>*/}
          {/*    <div className="text-lg font-medium">Unsubscribed</div>*/}
          {/*  </CardHeader>*/}
          {/*  <CardContent>*/}
          {/*    <div className="text-base">{unsubscribed || "N/A"}</div>*/}
          {/*  </CardContent>*/}
          {/*</Card>*/}
          <Card>
            <CardHeader>
              <div className="text-lg font-medium">Completed</div>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{completed}</div>
            </CardContent>
          </Card>
        </div>
        <Card className="mt-5 pt-10 pr-6">
          <CardContent>
            <ChartContainer
              config={chartConfig}
              className="min-h-[250px] max-h-[300px]  w-full"
            >
              <AreaChart data={chartData}>
                <defs>
                  <linearGradient id="fillSent" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="hsl(var(--chart-1))"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="hsl(var(--chart-1))"
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                  <linearGradient id="fillReplies" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="hsl(var(--chart-2))"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="hsl(var(--chart-2))"
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  minTickGap={32}
                  tickFormatter={(value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                  }}
                />
                <YAxis />
                <Tooltip
                  cursor={{ stroke: "#ccc", strokeDasharray: "5 5" }}
                  content={
                    <ChartTooltipContent
                      labelFormatter={(value) => {
                        return new Date(value).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                        });
                      }}
                      indicator="dot"
                    />
                  }
                />
                <Area
                  dataKey="sent"
                  type="linear"
                  fill="url(#fillSent)"
                  stroke="hsl(var(--chart-1))"
                  activeDot={{ r: 5 }}
                  dot={{ r: 3 }}
                />
                <Area
                  dataKey="replies"
                  type="linear"
                  fill="url(#fillReplies)"
                  stroke="hsl(var(--chart-2))"
                  activeDot={{ r: 5 }}
                  dot={{ r: 3 }}
                />
                <ChartLegend content={<ChartLegendContent />} />
              </AreaChart>
            </ChartContainer>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Analytics;
