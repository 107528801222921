import React from "react";
import { Tab } from "@headlessui/react";

import { classNames } from "../pages/Main";
import { Divider } from "@mui/material";

function parseUrls(urlsString) {
  const urlsArray = urlsString.match(/\[\d+\]\s*https?:\/\/[^\s\|]*/g) || [];
  return urlsArray
    .map((url) => {
      const fullUrlMatch = url.match(/https?:\/\/[^\s\|]*/g);
      if (!fullUrlMatch) {
        return null;
      }
      const fullUrl = fullUrlMatch[0];
      const baseUrlMatch = fullUrl.match(/:\/\/(?:www\.)?([^/]+)/);
      if (!baseUrlMatch) {
        return null;
      }
      const baseUrl = baseUrlMatch[1];
      const domainParts = baseUrl.split(".");
      const name = domainParts[domainParts.length - 2];
      const base = `${name}.${domainParts[domainParts.length - 1]}`;
      return { fullUrl, baseUrl: base, name };
    })
    .filter((entry) => entry !== null);
}

function parseAnswer(answer) {
  if (answer.answer === "No relevant information.") {
    return "No relevant information.";
  } else {
    const urlsArray = answer.source.match(/[\[\(]\d+[\]\)][^\[\]\(\)]+/g);
    if (urlsArray !== null) {
      const urlsDict = {};
      urlsArray.forEach((url) => {
        const [key, value] = url.match(/\d+|[^\[\]\(\)\s]+/g);
        urlsDict[key] = value;
      });

      return answer.answer.replace(/[\[\(]\d+[\]\)]/g, (match) => {
        const num = match.match(/\d+/)[0];
        const url = urlsDict[num];
        return `<a href="${url}" target="_blank"><sup>${num}</sup></a>`;
      });
    } else {
      return answer.answer;
    }
  }
}

export default function CompellingPredictions({
  apiData,
  preds,
  fetching,
  asteriskData,
  selectedContact,
  linkedinProfile,
  setLinkedinProfile,
}) {
  const [extensionInstalled, setExtensionInstalled] = React.useState(false);
  const extensionId = "gppiijkckphfjhofaoooendnckjeigcg"; // PROD extension ID

  const [sourceURLs, setSourceURLs] = React.useState([]);
  const [researchAnswerReceived, setResearchAnswerReceived] =
    React.useState(false);
  const [researchAnswer, setResearchAnswer] = React.useState("");

  const [linkedInIdentifier, setLinkedInIdentifier] = React.useState(null);
  const [summaryOpen, setSummaryOpen] = React.useState(false);

  const [categories, setCategories] = React.useState({
    Events: ["dummy"],
    General: [],
    // Challenges: [],
    Technology: [],
    LinkedIn: [],
    "+": ["dummy"],
  });

  React.useEffect(() => {
    // Function to check for the extension
    const checkExtension = () => {
      try {
        // Send a message to the extension's content script
        const message = { type: "CHECK_CAI_EXTENSION" };
        window.chrome.runtime.sendMessage(extensionId, message, (response) => {
          if (response) {
            console.log("Extension response:", response);
            setExtensionInstalled(true); // Extension is installed
          }
        });
      } catch (error) {
        // console.error("Extension not found:", error);
        setExtensionInstalled(false);
      }
    };

    checkExtension();
  }, [extensionId]);

  React.useEffect(() => {
    const handleMessage = (event) => {
      // Check the sender and type of message
      if (event.source === window && event.data.type === "FROM_EXTENSION") {
        console.log("Data received from extension:", event.data.data);
        setLinkedinProfile(null, event.data.data);
        // console.log(linkedinProfile);
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  React.useEffect(() => {
    if (apiData.data) {
      if (JSON.parse(apiData.data).answer) {
        setResearchAnswerReceived(true);
      }
    }
  }, [apiData.data]);

  React.useEffect(() => {
    if (apiData.data) {
      if (JSON.parse(apiData.data).answer) {
        setResearchAnswer(
          parseAnswer(JSON.parse(apiData.data).answer).replace(
            /<sup>/g,
            "<sup class='text-compelling-bright underline'>"
          )
          // +
          // " " +
          // JSON.parse(apiData.data).answer.inferred_challenges
        );
      }
    }
  }, [apiData.data]);

  React.useEffect(() => {
    if (apiData.data) {
      if (JSON.parse(apiData.data).answer) {
        console.log(JSON.parse(apiData.data).answer.source);
        const urls = parseUrls(JSON.parse(apiData.data).answer.source);
        setSourceURLs(urls);
        console.log(urls);
      }
    }
  }, [apiData.data]);

  React.useEffect(() => {
    if (asteriskData) {
      console.log(asteriskData);

      setCategories({
        ...categories,
        General: {
          KG: asteriskData.KG_response,
          WP: asteriskData.company_response,
        },
        Technology: {
          WAPP: asteriskData.tech_response,
        },
      });
    }
  }, [asteriskData]);

  React.useEffect(() => {
    //console.log("selected contact:", selectedContact);
    if (selectedContact && selectedContact.linkedin) {
      //console.log(selectedContact.linkedin);
      // if the last character is a not a slash, add it
      const linkedInURL =
        selectedContact.linkedin[selectedContact.linkedin.length - 1] === "/"
          ? selectedContact.linkedin
          : selectedContact.linkedin + "/";

      let identifier = selectedContact.linkedin.match(
        /\/in\/([^\/]+?)(?:\/|$)/
      );
      if (identifier && identifier[1]) {
        console.log("LinkedIn identifier:", identifier[1]);
        setLinkedInIdentifier(identifier[1]);
      } else {
        console.log("No LinkedIn identifier found.");
      }

      if (categories.LinkedIn && categories.LinkedIn.URL === linkedInURL) {
        console.log("Already fetched LinkedIn profile.");
      } else {
        setCategories({
          ...categories,
          LinkedIn: {
            URL: linkedInURL,
          },
        });
        fetch("https://api.compelling.ai/linkedin/LinkedInAPI", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "getPosts",
            identifier: linkedInURL.split("/in/")[1].replace(/\/\s*$/, ""),
            accound_id: JSON.parse(localStorage.getItem("local-user"))[
              "https://compelling.ai/claims/user_metadata"
            ]["linkedinAuthId"],
          }),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          } else {
            response.json().then((data) => {
              console.log(data);
              setLinkedinProfile(null, data);
              setExtensionInstalled(true);
            });
          }
        });
      }
    } else {
      setCategories({
        ...categories,
        LinkedIn: null,
      });
    }
  }, [selectedContact]);

  return (
    <div className="opacity-100 w-full px-2 mt-3">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-t-md bg-stone-300 p-1 pb-0 border border-stone-700 border-b-0">
          {Object.entries(categories).map(([categoryName, content]) =>
            categoryName !== "+" ? (
              <Tab
                key={categoryName}
                className={({ selected }) =>
                  classNames(
                    "rounded-t-md text-xs bg-stone-600 font-mono leading-5 px-2.5 py-1",
                    "focus:outline-none",
                    selected
                      ? "bg-stone-700 text-compelling-bright"
                      : "text-stone-50 hover:bg-stone-500"
                  )
                }
              >
                {!content || content.length === 0 ? (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 inline text-white/50 animate-spin fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  categoryName
                )}
              </Tab>
            ) : (
              <Tab
                key={categoryName}
                disabled
                className={({ selected }) =>
                  classNames(
                    "rounded-t-md text-lg bg-stone-400 font-mono leading-5 px-3 py-1",
                    "focus:outline-none",
                    selected
                      ? "bg-stone-700 text-compelling-bright"
                      : "text-stone-700 hover:bg-stone-500 hover:text-stone-50"
                  )
                }
              >
                {!content || content.length === 0 ? (
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 inline text-white/50 animate-spin fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  categoryName
                )}
              </Tab>
            )
          )}
          {/*<Tab*/}
          {/*  className={({ selected }) =>*/}
          {/*    classNames(*/}
          {/*      "rounded-md h-full right-0.5 text-xs font-mono px-1",*/}
          {/*      "focus:outline-none",*/}
          {/*      selected*/}
          {/*        ? "bg-stone-50 text-stone-500"*/}
          {/*        : "text-stone-500 hover:text-white"*/}
          {/*    )*/}
          {/*  }*/}
          {/*>*/}
          {/*  <svg*/}
          {/*    xmlns="http://www.w3.org/2000/svg"*/}
          {/*    fill="none"*/}
          {/*    viewBox="0 0 24 24"*/}
          {/*    strokeWidth={1.5}*/}
          {/*    stroke="currentColor"*/}
          {/*    className="w-6 h-6"*/}
          {/*  >*/}
          {/*    <path*/}
          {/*      strokeLinecap="round"*/}
          {/*      strokeLinejoin="round"*/}
          {/*      d="M12 6v12m6-6H6"*/}
          {/*    />*/}
          {/*  </svg>*/}
          {/*</Tab>*/}
        </Tab.List>
        <Tab.Panels>
          {Object.values(categories).map((tab, idx) => (
            <Tab.Panel
              key={idx}
              className="bg-stone-700 selection:text-stone-700 selection:bg-stone-50 w-full flex -z-10 text-xs py-2 rounded-b-md font-mono px-3 text-stone-50"
            >
              {idx === 0 && (
                <>
                  <div className="h-full flex flex-col mr-2">
                    <span
                      className={
                        fetching || preds
                          ? "text-compelling-bright"
                          : "text-stone-300"
                      }
                    >
                      >
                    </span>
                    {fetching && (
                      <span className="absolute text-compelling-bright animate-ping">
                        >
                      </span>
                    )}
                  </div>
                  <div className="" id="compellingStream">
                    {apiData.data ? (
                      researchAnswerReceived ? (
                        <>
                          <div
                            className=""
                            dangerouslySetInnerHTML={{
                              __html: researchAnswer,
                            }}
                          ></div>
                          <div className="bg-gradient-to-r from-stone-600 from-75% to-compelling-bright/50 to-100% mt-2 rounded-full max-w-xl p-2 mr-3 flex overflow-x-auto">
                            {sourceURLs.map(
                              ({ fullUrl, baseUrl, name }, index) => (
                                <button
                                  key={index}
                                  className="border rounded-full flex p-2 mr-2 items-center hover:border-compelling-bright"
                                  onClick={() => window.open(fullUrl, "_blank")}
                                >
                                  <span className="text-sm ml-1 mr-2">
                                    {index + 1}
                                  </span>
                                  <Divider
                                    sx={{ bgcolor: "white" }}
                                    orientation="vertical"
                                    flexItem
                                  />
                                  <div className="h-[16px] w-[16px] mr-3.5">
                                    <img
                                      className="ml-2 h-[16px] w-[16px] mr-1"
                                      src={
                                        "https://www.google.com/s2/favicons?domain=" +
                                        baseUrl
                                      }
                                      alt={name + " favicon"}
                                      height={16}
                                      width={16}
                                    />
                                  </div>
                                  <p className="mr-1 whitespace-nowrap">
                                    {name}
                                  </p>
                                </button>
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        "No relevant information."
                      )
                    ) : (
                      "Searching for compelling events will take up to 60 seconds, depending on events."
                    )}
                  </div>
                </>
              )}
              {idx === 1 &&
                (tab != null && tab.KG != null ? (
                  <div className="h-full flex flex-col mr-2">
                    <p>{tab.KG.title}</p>
                    {/*<p>{tab.WP.industry}</p>*/}
                    <p>{tab.KG.type}</p>
                    <br></br>
                    {tab.WP && tab.WP.companySize && (
                      <>
                        <p>
                          Company size:{" "}
                          <span className="border border-white text-white text-xs font-medium px-2.5 py-0.5 rounded">
                            {tab.WP.companySize}
                          </span>
                        </p>
                        <br></br>
                      </>
                    )}
                    {tab.WP && tab.WP.technologySpend && (
                      <>
                        <p>
                          Technology spent:{" "}
                          <span className="border border-white text-white text-xs font-medium px-2.5 py-0.5 rounded">
                            {tab.WP.technologySpend}
                          </span>
                        </p>
                        <br></br>
                      </>
                    )}
                    <div className="text-white/50">
                      {tab.KG.website && (
                        <a
                          href={tab.KG.website.replace(
                            /(\w*:\/{2}.*\.\w*)(.*)/,
                            "$1"
                          )}
                          className="underline"
                          target="_blank"
                        >
                          {tab.KG.website
                            .replace(/\w.*:\/{2}/, "")
                            .replace(/\/.*/, "")}
                        </a>
                      )}
                      {tab.KG.attributes && (
                        <>
                          <p>{tab.KG.attributes.Address}</p>
                          <p>{tab.KG.attributes.Phone}</p>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="h-full flex flex-col mr-2">
                    <p>No general information available.</p>
                  </div>
                ))}
              {idx === 2 &&
                (tab != null && tab.WAPP != null ? (
                  <div className="h-full flex flex-col mr-2">
                    {Object.entries(tab.WAPP)
                      .filter(([category, entries]) => entries.length > 0)
                      .map(([category, entries]) => (
                        <div key={category}>
                          <span className="font-bold">{category}</span>
                          {entries.map((entry, index) => (
                            <p key={index}>{entry.name}</p>
                          ))}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="h-full flex flex-col mr-2">
                    <p>No technology information available.</p>
                  </div>
                ))}
              {idx === 3 &&
                (tab != null && tab.URL != null ? (
                  <div className="max-w-2xl my-4 mx-3 w-full ">
                    <div
                      id="cai-linkedin-profile-container"
                      className="h-full w-full"
                    >
                      {linkedinProfile ? (
                        linkedinProfile.public_identifier ===
                        linkedInIdentifier ? (
                          <div className="flex flex-row flex-grow w-full">
                            <div className="container mx-auto text-stone-600 mr-6">
                              {/* Profile Header */}
                              <div className="p-4 bg-white shadow-lg rounded-lg relative">
                                <div className="flex justify-center">
                                  <div className="grid grid-cols-2 place-content-center place-items-center">
                                    <img
                                      className="w-24 h-24 rounded-full"
                                      src={linkedinProfile.profile_pic_url}
                                      alt={linkedinProfile.full_name}
                                    />
                                    <h1 className="text-xl font-semibold">
                                      {linkedinProfile.full_name}
                                    </h1>
                                  </div>
                                </div>
                                <p className="mt-4 text-center text-stone-600">
                                  {linkedinProfile.headline}
                                </p>
                                <p className="text-center text-stone-500">
                                  {linkedinProfile.city},{" "}
                                  {linkedinProfile.country_full_name}
                                </p>
                                <p className="text-center text-stone-500">
                                  {linkedinProfile.follower_count} followers
                                </p>
                                <button
                                  className="absolute bottom-2 right-2 w-6 h-6"
                                  onClick={() => setSummaryOpen(!summaryOpen)}
                                >
                                  {summaryOpen ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                      className="size-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                      />
                                    </svg>
                                  )}
                                </button>
                              </div>
                              {/* Experience List */}
                              <div
                                className={classNames(
                                  summaryOpen ? "visible" : "hidden",
                                  "mt-6 bg-white shadow-lg rounded-lg text-stone-600"
                                )}
                              >
                                {linkedinProfile.experiences.map(
                                  (exp, index) => (
                                    <div
                                      key={index}
                                      className={`${
                                        index ===
                                        linkedinProfile.experiences.length - 1
                                          ? ""
                                          : "border-b" // border bottom only when not last element
                                      } border-stone-300 p-4`}
                                    >
                                      <h2 className="text-lg font-semibold">
                                        {exp.company}
                                      </h2>
                                      <p className="text-stone-600">
                                        {exp.title || "Role not specified"}
                                      </p>
                                      <p className="text-stone-500">{`${
                                        exp.starts_at.month
                                      }/${exp.starts_at.year} - ${
                                        exp.ends_at
                                          ? `${exp.ends_at.month}/${exp.ends_at.year}`
                                          : "Present"
                                      }`}</p>
                                      <p className="text-stone-500">
                                        {exp.location}
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>

                              {/* Education List */}
                              <div
                                className={classNames(
                                  summaryOpen ? "visible" : "hidden",
                                  "mt-6 bg-white shadow-lg rounded-lg text-stone-600"
                                )}
                              >
                                <h2 className="text-lg font-semibold p-4 border-b border-stone-300">
                                  Education
                                </h2>
                                {linkedinProfile.education.map((edu, index) => (
                                  <div
                                    key={index}
                                    className={`${
                                      index ===
                                      linkedinProfile.education.length - 1
                                        ? ""
                                        : "border-b" // border bottom only when not last element
                                    } border-stone-300 p-4`}
                                  >
                                    <h3 className="text-lg font-semibold">
                                      {edu.school}
                                    </h3>
                                    <p className="text-stone-600">
                                      {edu.degree_name ||
                                        "Degree not specified"}
                                    </p>
                                    {/*<p className="text-stone-500">{`${*/}
                                    {/*  edu.starts_at.month ||*/}
                                    {/*  "Start date not specified"*/}
                                    {/*}/${edu.starts_at.year} - ${*/}
                                    {/*  edu.ends_at*/}
                                    {/*    ? `${edu.ends_at.month}/${edu.ends_at.year}`*/}
                                    {/*    : "Present"*/}
                                    {/*}`}</p>*/}
                                    <p className="text-stone-500">
                                      {edu.field_of_study}
                                    </p>
                                  </div>
                                ))}
                              </div>

                              {/* Languages */}
                              <div
                                className={classNames(
                                  summaryOpen ? "visible" : "hidden",
                                  "mt-6 bg-white shadow-lg rounded-lg text-stone-600 p-4"
                                )}
                              >
                                <h2 className="text-lg font-semibold">
                                  Languages
                                </h2>
                                <ul>
                                  {linkedinProfile.languages.map(
                                    (language, index) => (
                                      <li
                                        key={index}
                                        className="text-stone-600"
                                      >
                                        {language}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="container mx-auto text-stone-600 mr-6">
                              {/* Connections */}
                              <div
                                className={classNames(
                                  summaryOpen ? "visible" : "hidden",
                                  "bg-white shadow-lg rounded-lg text-stone-600 p-4"
                                )}
                              >
                                <h2 className="text-lg font-semibold">
                                  Connections
                                </h2>
                                <p>{linkedinProfile.connections} connections</p>
                              </div>
                              {/* Activities */}
                              <div
                                className={classNames(
                                  summaryOpen ? "visible" : "hidden",
                                  "bg-white shadow-lg rounded-lg text-stone-600 p-4 mt-6"
                                )}
                              >
                                <h2 className="text-lg font-semibold">
                                  Recent Activities
                                </h2>
                                {linkedinProfile.activities.length > 0 ? (
                                  <ul>
                                    {linkedinProfile.activities.map(
                                      (activity, index) => (
                                        <li
                                          key={index}
                                          className="text-stone-600 mb-2"
                                        >
                                          <a
                                            href={activity.link}
                                            className="text-blue-500 hover:underline"
                                          >
                                            {activity.title}
                                          </a>
                                          <p className="text-stone-500">
                                            {activity.activity_status}
                                          </p>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <p>No recent activities</p>
                                )}
                              </div>
                              <div
                                id="cai-linkedin-post-summary"
                                className="w-full flex flex-grow mt-6 "
                              >
                                <div
                                  className={classNames(
                                    summaryOpen ? "visible" : "hidden",
                                    "container mx-auto text-stone-50"
                                  )}
                                >
                                  {linkedinProfile.summary}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="container flex flex-grow text-stone-50">
                            <div className="flex">
                              <div className="flex flex-col items-center p-8 bg-white shadow-lg rounded-lg">
                                <div className="flex flex-col animate-pulse space-y-4 items-center">
                                  <div className="h-6 w-32 rounded-lg bg-stone-300"></div>
                                  <div className="h-4 w-40 rounded-lg bg-stone-300"></div>
                                  <div className="h-4 w-60 rounded-lg bg-stone-300"></div>
                                  <div className="h-4 w-60 rounded-lg bg-stone-300"></div>
                                  <div className="h-4 w-60 rounded-lg bg-stone-300"></div>
                                </div>
                                <p className="text-center text-stone-600 mt-8">
                                  Generating Summary …
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      ) : extensionInstalled ? (
                        <button
                          id="cai-get-linkedin-profile"
                          data-linkedin-url={tab.URL}
                          className="m-1 border border-white p-2 hover:bg-white/20 rounded"
                        >
                          Scan profile
                        </button>
                      ) : (
                        <button
                          disabled
                          className="m-1 border border-white p-2 hover:bg-white/20 rounded"
                        >
                          Get compelling Chrome Extension
                          <span className="ml-2 bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                            Coming soon
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="h-full flex flex-col mr-2">
                    <p>No LinkedIn URL found.</p>
                  </div>
                ))}
              {idx === categories.length - 1 &&
                (tab != null ? (
                  <div className="h-full flex flex-col mr-2">
                    <p>Coming soon.</p>
                  </div>
                ) : (
                  <div className="h-full flex flex-col mr-2">
                    <p>Coming soon.</p>
                  </div>
                ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
