import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./msalAuthConfig";
import { Auth0Provider } from "@auth0/auth0-react";
import TagManager from "react-gtm-module";

import reportWebVitals from "./reportWebVitals";
import Composer from "./pages/Composer";
import Main from "./pages/Main";
import Auth0Callback from "./components/CallbackContent";
import Hub from "./pages/Hub";
import Messages from "./pages/Messages";
import {
  HubSpotCallback,
  SalesforceCallback,
  PipedriveCallback,
  OutreachCallback,
} from "./pages/OAuth2";
import PageNotFound from "./pages/PageNotFound";
import { AuthenticationGuard } from "./components/authentication-guard";
import TechRadar from "./pages/TechRadar";
import Analytics from "./pages/Analytics";

const tagManagerArgs = {
  gtmId: "GTM-KZ79DV76",
};

TagManager.initialize(tagManagerArgs);

export default function App() {
  return (
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin + "/v1/dashboard",
          audience: "https://auth0-jwt-authorizer",
        }}
      >
        <Routes>
          <Route path="/" element={<AuthenticationGuard component={Main} />}>
            <Route path="/" element={<Navigate to="/v1/dashboard" />} />
            <Route path="composer" element={<Navigate to="/v1/composer" />} />
            <Route path="dashboard" element={<Navigate to="/v1/dashboard" />} />
            <Route path="/v1/" element={<Main />}>
              <Route path="composer" element={<Composer />} />
              <Route path="dashboard" element={<Hub />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="messages" element={<Messages />} />
              <Route path="tech-radar" element={<TechRadar />} />
            </Route>
            <Route path="" element={<Navigate to="/v1/dashboard" />} />
            <Route path="callback/auth0" element={<Auth0Callback />} />
            <Route
              path="callback/salesforce/oauth2"
              element={<SalesforceCallback />}
            />
            <Route
              path="callback/hubspot/oauth2"
              element={<HubSpotCallback />}
            />
            <Route
              path="callback/pipedrive/oauth2"
              element={<PipedriveCallback />}
            />
            <Route
              path="callback/outreach/oauth2"
              element={<OutreachCallback />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Auth0Provider>
    </BrowserRouter>
  );
}

// create PublicClientApplication instance
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
