import React from "react";

export default function FollowUp({
  preds,
  followUp,
  setFollowUp,
  editEmail,
  setEditorData,
  localEmail,
  localFollowUp,
  setFollowUpUI,
  followUpCreated,
  setFollowUpCreated,
}) {
  return (
    <div className="ml-4 flex flex-grow">
      {preds || followUpCreated ? (
        <button
          className="mr-2 disabled:bg-gray-200 disabled:grayscale flex flex-row w-2/5 items-center justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium bg-green-400 text-white hover:border-transparent hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          onClick={() => {
            setFollowUpUI(true);
            setFollowUp(false);
            setEditorData(localEmail);
          }}
        >
          Initial email<span className="ml-2 mt-0.5">✅</span>
        </button>
      ) : (
        ""
      )}
      {followUpCreated ? (
        <button
          className="mr-2 flex flex-row w-2/5 items-center justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium bg-amber-300 text-white hover:border-transparent hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-400 focus:ring-offset-2"
          onClick={() => {
            setFollowUpUI(true);
            setFollowUp(true);
            setEditorData(localFollowUp);
          }}
        >
          1st follow up<span className="ml-2 mt-0.5">🥇</span>
        </button>
      ) : (
        ""
      )}
      <button
        disabled={!preds || followUpCreated}
        type="Button"
        className="flex disabled:bg-white/50 disabled:border-gray-200 disabled:text-gray-200 flex-grow items-center justify-center rounded-md border border-dashed border-indigo-300 py-2 px-4 text-sm font-medium text-indigo-300 hover:border-transparent hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => {
          setFollowUp(true);
          setFollowUpCreated(true);
          editEmail((followUp = true));
        }}
      >
        <span className="flex flex-col">
          {!followUpCreated ? "Add follow up" : "+"}
        </span>
      </button>
    </div>
  );
}
