import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuth0 } from "@auth0/auth0-react";
import { isIntegrationAuthenticated } from "./authUtils";

export const UnAuthTemplate = ({ children }) => {
  const msalIsAuthenticated = useIsAuthenticated();
  const { isAuthenticated: auth0IsAuthenticated } = useAuth0();

  return !isIntegrationAuthenticated(
    msalIsAuthenticated,
    auth0IsAuthenticated
  ) ? (
    <>{children}</>
  ) : null;
};
