import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { AuthTemplate } from "../utils/AuthTemplate";
import { UnAuthTemplate } from "../utils/UnAuthTemplate";
import { useAuth0 } from "@auth0/auth0-react";

const navigation = [
  // { name: "Demo", href: "/demo", current: true },
  { name: "Dashboard", href: "/v1/dashboard", current: true, auth: true },
  {
    name: "Messages",
    href: "/v1/messages",
    current: false,
    auth: !!(
      JSON.parse(localStorage.getItem("local-user")) &&
      JSON.parse(localStorage.getItem("local-user"))[
        "https://compelling.ai/claims/user_metadata"
      ] &&
      JSON.parse(localStorage.getItem("local-user"))[
        "https://compelling.ai/claims/user_metadata"
      ]["linkedinAuthId"] !== undefined
    ),
  },
  { name: "Composer", href: "/v1/composer", current: false, auth: true },
  {
    name: "Analytics",
    href: "/v1/analytics",
    current: false,
    auth: !!(
      JSON.parse(localStorage.getItem("local-user")) &&
      JSON.parse(localStorage.getItem("local-user"))[
        "https://compelling.ai/claims/campaign_ids"
      ]
    ),
  },
  // { name: "NodeComposerDemo", href: "/composer", current: false },
  // { name: "Chat", href: "/chat", current: false },
  // { name: "Calendar", href: "#", current: false },
];

const setCurrent = (href) => {
  navigation.forEach((item) => {
    // if URL contains the href, set current to true
    item.current = href.includes(item.href);
  });
};

setCurrent(window.location.pathname);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav({ compellingUser }) {
  const [name, setName] = useState("Stranger");
  const [subscription, setSubscription] = useState({});

  useEffect(() => {
    if (compellingUser.subscription) {
      setName(compellingUser ? compellingUser.fullName : "Stranger");
      setSubscription({
        tier: compellingUser.subscription.tier,
        credits_used: compellingUser.subscription.credits_used,
        credits_total: compellingUser.subscription.credits_total,
        progress_percentage: (
          (compellingUser.subscription.credits_used /
            compellingUser.subscription.credits_total) *
          100
        ).toFixed(0),
      });
    }
  }, [compellingUser]);

  return (
    <Disclosure
      as="nav"
      className="bg-gray-200/50 fixed top-0 w-full z-40 backdrop-blur-md"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <a href="/" className="h-8 w-auto">
                    <span className="sr-only">compelling.ai</span>
                    <img className="h-8" src="/icon.svg" alt="" />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map(
                      (item) =>
                        item.auth && (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "border border-gray-400 text-gray-400"
                                : "text-gray-300 border border-transparent hover:border-gray-300",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </a>
                        )
                    )}
                    {process.env.REACT_APP_ENV !== "Production" && (
                      <div className="px-3 py-2 rounded-md text-sm font-medium text-red-300">
                        {process.env.REACT_APP_ENV}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <AuthTemplate>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="rounded-full relative bg-gray-200 p-1 text-gray-400 focus:outline-none focus:ring-2 focus:ring-compelling-bright"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                    <div className="absolute inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white bg-compelling-bright rounded-full -top-1 -right-1"></div>
                  </button>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-400 text-sm focus:outline-none focus:ring-2 focus:ring-compelling-bright">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={`https://ui-avatars.com/api/?name=${name}&background=8888FF&color=464FE5`}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white pb-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          id="currentPlanOverview"
                          className="bg-stone-100 flex flex-col items-center p-2 mb-2 rounded-t-md "
                        >
                          <p className="text-xs text-gray-500 pb-2 pt-3 flex items-center">
                            <span className="text-lg mr-2">👋</span>
                            Welcome {name ? name.split(" ")[0] : ""}{" "}
                          </p>
                          <div className="text-lg font-bold text-gray-500 px-4 py-2">
                            {subscription.tier} Plan
                          </div>
                          <div className="text-xs text-gray-500">
                            Credits used:{" "}
                            {!isNaN(parseInt(subscription.credits_total))
                              ? Intl.NumberFormat("en-US").format(
                                  subscription.credits_used
                                ) +
                                " / " +
                                Intl.NumberFormat("en-US").format(
                                  subscription.credits_total
                                )
                              : Intl.NumberFormat("en-US").format(
                                  subscription.credits_used
                                ) +
                                " / " +
                                subscription.credits_total}
                          </div>
                          <div className="w-10/12 bg-gray-200 rounded-full h-1.5 mb-4 mt-2">
                            <div
                              className={`bg-indigo-600 h-1.5 rounded-full`}
                              style={{
                                // animate the width of the progress bar
                                width: `${subscription.progress_percentage}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                        <Menu.Item disabled>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              <SignInButton />
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item disabled>
                          {({ active }) => (
                            <p
                              href="/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </p>
                          )}
                        </Menu.Item>
                        <Menu.Item disabled>
                          {({ active }) => (
                            <p
                              href="/"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Settings
                            </p>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              <SignOutButton />
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </AuthTemplate>
              <UnAuthTemplate>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <SignInButton />
                </div>
              </UnAuthTemplate>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
