export const isIntegrationAuthenticated = (
  msalIsAuthenticated,
  auth0IsAuthenticated
) => {
  // Check if the Salesforce token is valid
  const salesforceAuthenticated = !isSalesforceTokenExpired();

  // Check if the HubSpot token is valid
  const hubspotAuthenticated = !isHubSpotTokenExpired();

  // Check if the Pipedrive token is valid
  const pipedriveAuthenticated = !isPipedriveTokenExpired();

  return (
    msalIsAuthenticated ||
    salesforceAuthenticated ||
    hubspotAuthenticated ||
    pipedriveAuthenticated ||
    auth0IsAuthenticated
  );
};

export function isSalesforceTokenExpired() {
  const expiryTime = Number(localStorage.getItem("salesforceExpiryTime"));

  if (!expiryTime) {
    return true;
  }

  //if the token is expired, try to refresh it with a POST request to /services/oauth2/token
  if (((Date.now() / 1000) | 0) > expiryTime) {
    const token = localStorage.getItem("salesforceRefreshToken");
    if (token) {
      // refresh the token
      fetch("https://api.compelling.ai/salesforce/SalesforceAuth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grantType: "refresh_token",
          codeOrToken: token,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);

          const accessToken = data.access_token;

          const expiryTime = ((Date.now() / 1000) | 0) + 15 * 60;

          localStorage.setItem("salesforceAccessToken", accessToken);
          localStorage.setItem("salesforceExpiryTime", expiryTime.toString());
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  return ((Date.now() / 1000) | 0) > expiryTime;
}

export function isHubSpotTokenExpired() {
  const expiryTime = Number(localStorage.getItem("hubspotExpiresAt"));

  if (!expiryTime) {
    return true;
  }

  // if the token is expired, try to refresh it
  if (((Date.now() / 1000) | 0) > expiryTime) {
    const token = localStorage.getItem("hubspotRefreshToken");
    if (token) {
      // refresh the token
      fetch("https://api.compelling.ai/hubspot/HubSpotAuth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grantType: "refresh_token",
          codeOrToken: token,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);

          const accessToken = data.access_token;
          const refreshToken = data.refresh_token;
          const expiresIn = data.expires_in;

          const expiresAt = ((Date.now() / 1000) | 0) + expiresIn;

          localStorage.setItem("hubspotAccessToken", accessToken);
          localStorage.setItem("hubspotRefreshToken", refreshToken);
          localStorage.setItem("hubspotExpiresAt", expiresAt.toString());
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }

  return ((Date.now() / 1000) | 0) > expiryTime;
}

export function isPipedriveTokenExpired() {
  const expiryTime = Number(localStorage.getItem("pipedriveExpiresAt"));

  if (!expiryTime) {
    return true;
  }

  // if the token is expired, try to refresh it
  if (((Date.now() / 1000) | 0) > expiryTime) {
    const token = localStorage.getItem("pipedriveRefreshToken");
    if (token) {
      // refresh the token
      fetch("https://api.compelling.ai/pipedrive/PipedriveAuth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          grantType: "refresh_token",
          codeOrToken: token,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP error " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);

          const accessToken = data.access_token;
          const refreshToken = data.refresh_token;
          const expiresIn = data.expires_in;

          const expiresAt = ((Date.now() / 1000) | 0) + expiresIn;

          localStorage.setItem("pipedriveAccessToken", accessToken);
          localStorage.setItem("pipedriveRefreshToken", refreshToken);
          localStorage.setItem("pipedriveExpiresAt", expiresAt.toString());
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
  return ((Date.now() / 1000) | 0) > expiryTime;
}
