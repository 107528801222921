import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Fragment,
} from "react";
import {
  Slider,
  createTheme,
  ThemeProvider,
  Divider,
  Popover,
  TextField,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Papa from "papaparse";
import Fuse from "fuse.js";

import { loginRequest } from "../msalAuthConfig";
import { callDynamicsAPI } from "../dynamics";
import { classNames } from "../pages/Main";
import { fetchProspects } from "../utils/seekwhensAPI";
import { isIntegrationAuthenticated } from "../utils/authUtils";
import { AuthTemplate } from "../utils/AuthTemplate";
import { UnAuthTemplate } from "../utils/UnAuthTemplate";
import { fetchData } from "../salesforce";
import { getHubspotContacts } from "../hubSpot";
import { isSalesforceTokenExpired } from "../utils/authUtils";

const loadingTexts = [
  "Warming up …",
  "Finding relevant positions …",
  "Searching for prospects …",
  "Almost done …",
];

const compellingTheme = createTheme({
  palette: {
    primary: {
      light: "#8888ff",
      main: "#4F46E5",
      dark: "#4338ca",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ecf096",
      main: "#dde546",
      dark: "#c9ce10",
      contrastText: "#000",
    },
  },
});

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(249,250,251,0.9)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid rgb(229,231,235)",
  },
}));

export const CompellingTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    fontSize: "0.875rem",
    fontFamily: "Inter, sans-serif",
  },
  "& .MuiInputBase-input": {
    fontSize: "0.875rem",
    fontFamily: "Inter, sans-serif",
    backgroundColor: "white",
    borderRadius: "0.375rem",
  },
  "&:focus .MuiInputBase-input": {
    border: "none!important",
  },
  "& label.Mui-focused": {
    color: "rgb(79, 70, 229)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(79, 70, 229)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(209, 213, 219)",
      borderRadius: "0.375rem",
    },
    "&:hover fieldset": {
      borderColor: "rgb(229, 231, 235)",
      borderRadius: "0.375rem",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(79, 70, 229)",
      borderRadius: "0.375rem",
      borderWidth: 1,
    },
  },
});

export const CompellingSelect = styled(Select)({
  "& .MuiFormLabel-root": {
    fontSize: "0.875rem",
    fontFamily: "Inter, sans-serif",
  },
  "& .MuiSelect-select.MuiOutlinedInput-input": {
    fontSize: "0.875rem",
    fontFamily: "Inter, sans-serif",
    backgroundColor: "white",
    borderRadius: "0.375rem",
  },
  "&:focus .MuiSelect-select": {
    border: "none!important",
  },
  "& .MuiSelect-select.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      height: "0.4em",
    },
  "&.Mui-focused .MuiFormLabel-root-MuiInputLabel-root": {
    color: "rgb(79, 70, 229)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(209, 213, 219)",
    borderRadius: "0.375rem",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(229, 231, 235)",
    borderRadius: "0.375rem",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(79, 70, 229)",
    borderRadius: "0.375rem",
    borderWidth: 1,
  },
});

export function useSearchDebounce(delay = 350) {
  const [search, setSearch] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}

export default function Settings({
  selectedContact,
  setSelectedContact,
  selectedProduct,
  setSelectedProduct,
  dynamicsAPIData,
  setDynamicsAPIData,
  localSettings,
  setLocalSettings,
  setRequiredFields,
  setCompellingTags,
  compellingTags,
  setToneTags,
  toneTags,
  rules,
  setRules,
  templates,
  setTemplates,
  noUseBestPractices,
  setNoUseBestPractices,
  localSettingsJSON,
  setLocalSettingsJSON,
  callAsterisk,
  salesforceAPIData,
  setSalesforceAPIData,
  hubSpotAPIData,
  setHubSpotAPIData,
}) {
  const [useCRMData, setUseCRMData] = useState(false);

  // on tag add/edit/remove
  const onTagChange = useCallback((e) => {
    console.log(
      "CHANGED:",
      e.detail.tagify.settings.id, // the tagify instance's ID
      e.detail.tagify.value, // Array where each tag includes tagify's (needed) extra properties
      e.detail.tagify.getCleanValue(), // Same as above, without the extra properties
      e.detail.value // a string representing the tags
    );

    if (e.detail.tagify.settings.id === "prospect-compelling-events") {
      const tags = e.detail.tagify.value.map((tag) => tag.value);
      console.log("events-tags", tags);
      setCompellingTags(tags);
    } else if (e.detail.tagify.settings.id === "prospect-tone") {
      const tags = e.detail.tagify.value.map((tag) => tag.value);
      console.log("tone-tags", tags);
      setToneTags(tags);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { instance, accounts } = useMsal();
  const msalIsAuthenticated = useIsAuthenticated();

  const [styleSlider, setStyleSlider] = useState(50);

  const [prospects, setProspects] = useState({});

  const [anchorRules, setAnchorRules] = useState(null);
  const [anchorDescription, setAnchorDescription] = useState(null);
  const [anchorProspects, setAnchorProspects] = useState(null);

  const [settingsOpen, setSettingsOpen] = useState(false);

  const [search, setSearch] = useSearchDebounce();

  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [templateOpen, setTemplateOpen] = useState(0);

  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const [localCRMData, setLocalCRMData] = useState(
    JSON.parse(localStorage.getItem("local-CRM"))
  );

  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        localStorage.setItem("local-CRM", JSON.stringify(results.data));
        setLocalCRMData(results.data);
        setShowCsvUpload(false);
      },
    });
  };

  const [searchFuse, setSearchFuse] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const searchOptions = {
  //   // isCaseSensitive: false,
  //   // includeScore: true,
  //   // shouldSort: true,
  //   // includeMatches: false,
  //   // findAllMatches: false,
  //   // minMatchCharLength: 1,
  //   // location: 0,
  //   threshold: 0.1,
  //   // distance: 100,
  //   // useExtendedSearch: false,
  //   // ignoreLocation: false,
  //   // ignoreFieldNorm: false,
  //   // fieldNormWeight: 1,
  //   keys: ["Job Title", "Prospect Name", "Account Name"],
  // };

  const handleSearchChange = (event) => {
    isSalesforceTokenExpired();
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchFuse && hubSpotAPIData) {
      if (searchTerm !== "") {
        const results = searchFuse.search(searchTerm);
        setSearchResults(results);
      } else {
        const fullContacts = hubSpotAPIData.map((contact) => ({
          item: Object.assign(contact, {}),
          matches: [],
          score: 1,
        }));
        setSearchResults(fullContacts);
      }
    } else if (searchFuse && salesforceAPIData && salesforceAPIData.contacts) {
      if (searchTerm !== "") {
        const results = searchFuse.search(searchTerm);
        setSearchResults(results);
      } else {
        const fullContacts = salesforceAPIData.contacts.records.map(
          (contact) => ({
            item: Object.assign(contact, {}),
            matches: [],
            score: 1,
          })
        );
        setSearchResults(fullContacts);
      }
    } else if (searchFuse && dynamicsAPIData && dynamicsAPIData.contacts) {
      if (searchTerm !== "") {
        const results = searchFuse.search(searchTerm);
        setSearchResults(results);
      } else {
        const fullContacts = dynamicsAPIData.contacts.map((contact) => ({
          item: Object.assign(contact, {}),
          matches: [],
          score: 1,
        }));
        setSearchResults(fullContacts);
      }
    } else if (searchFuse && localCRMData) {
      if (searchTerm !== "") {
        const results = searchFuse.search(searchTerm);
        setSearchResults(results);
      } else {
        const fullContacts = localCRMData.map((contact) => ({
          item: Object.assign(contact, {}),
          matches: [],
          score: 1,
        }));
        setSearchResults(fullContacts);
      }
    }
  }, [searchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (localCRMData && localCRMData.length > 0) {
      setUseCRMData(true);
      const fullContacts = localCRMData.map((contact) => ({
        item: Object.assign(contact, {}),
        matches: [],
        score: 1,
      }));
      setSearchResults(fullContacts);
      const fuse = new Fuse(localCRMData, {
        threshold: 0.1,
        keys: ["Job Title", "Prospect Name", "Account Name"],
      });
      setSearchFuse(fuse);
    }
  }, [localCRMData]);

  useEffect(() => {
    if ((loadingIndex) => 0 && loadingIndex < 3) {
      setTimeout(() => {
        setLoadingIndex(loadingIndex + 1);
      }, 13000);
    }
  }, [loadingIndex]);

  const editRules = (event) => {
    event.preventDefault();
    setAnchorRules(event.currentTarget);
  };

  const newDescription = (event) => {
    event.preventDefault();
    setAnchorDescription(event.currentTarget);
  };

  const showProspects = (event) => {
    event.preventDefault();
    setAnchorProspects(event.currentTarget);
    if (prospects && Object.keys(prospects).length === 0) {
      console.log("fetching prospects");
      setLoadingIndex(0);
      fetchProspects({
        company:
          localSettingsJSON &&
          localSettingsJSON.prospect &&
          localSettingsJSON.prospect.companyname
            ? localSettingsJSON.prospect.companyname
            : "",
        product:
          // select description from localSettingsJSON.settings.opportunities where selected = true
          localSettingsJSON &&
          localSettingsJSON.settings &&
          localSettingsJSON.settings.opportunities
            ? localSettingsJSON.settings.opportunities
                .filter((opportunity) => {
                  return opportunity.selected;
                })
                .map((opportunity) => {
                  return opportunity.description;
                })
                .join(",")
            : "",
      })
        .then((prospects) => {
          console.log("prospects", prospects);
          setProspects(prospects);
        })
        .catch((error) => {
          console.log("error", error);
          setProspects({});
        });
    }
  };

  const closeRules = () => {
    setAnchorRules(null);
    setRules(null, document.getElementById("rules-text").value);
  };

  const closeDescription = () => {
    setAnchorDescription(null);
  };

  const closeProspects = () => {
    setAnchorProspects(null);
    setProspects({});
  };

  const saveTemplate = (templateID) => {
    const template = document.getElementById(
      "template-" + templateID + "-text"
    ).value;
    console.log({ templateID, template });
    if (templateID !== 0) {
      setTemplates(templateID, { template });
    }
    setTemplateOpen(0);
  };

  const openRules = Boolean(anchorRules);
  const openNewDesc = Boolean(anchorDescription);
  const openProspects = Boolean(anchorProspects);

  const handleChange = (event) => {
    setStyleSlider(event.target.value);
    // save in localSettingsJSON
    if (localSettingsJSON && localSettingsJSON.settings) {
      localSettingsJSON.settings.style = event.target.value;
      setLocalSettingsJSON(localSettingsJSON);
    }
  };

  const newOpportunityRef = useRef(null);

  const newOpportunity = (event) => {
    event.preventDefault();
    setAnchorDescription(null);
    let lastOpportunityId = 0;
    let opportunities = [];
    // get all opportunities from localSettingsJSON
    if (
      localSettingsJSON &&
      localSettingsJSON.settings &&
      localSettingsJSON.settings.opportunities
    ) {
      opportunities = localSettingsJSON.settings.opportunities;
      // set all selected to false
      opportunities.forEach((opportunity) => {
        opportunity.selected = false;
      });
      // get the last opportunity id
      lastOpportunityId = opportunities[opportunities.length - 1].id;
      // create new enrty in localSettingsJSON
    }
    const newOpportunityName = newOpportunityRef.current.value;
    const newOpportunity = {
      name: newOpportunityName,
      description: "",
      id: lastOpportunityId + 1,
      selected: true,
    };
    // set selected product
    setSelectedProduct(newOpportunity);
    opportunities.push(newOpportunity);
    console.log("Oppies: ", opportunities);

    // update localSettingsJSON
    if (localSettingsJSON && localSettingsJSON.settings) {
      setLocalSettingsJSON("settings", {
        ...localSettingsJSON.settings,
        opportunities: opportunities,
      });
    } else {
      setLocalSettingsJSON("settings", { opportunities: opportunities });
    }
  };

  const styleSliderMarks = [
    {
      value: 0,
      label: "Product",
    },
    {
      value: 50,
      label: "Mix",
    },
    {
      value: 100,
      label: "Relation",
    },
  ];

  function RequestContactData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callDynamicsAPI(response.accessToken).then((response) =>
          setDynamicsAPIData(response)
        );
      })
      .catch(() => {
        instance.acquireTokenPopup(request).then((response) => {
          callDynamicsAPI(response.accessToken).then((response) =>
            setDynamicsAPIData(response)
          );
        });
      });
  }

  // LOAD LOCAL SETTINGS
  useEffect(() => {
    if (localSettingsJSON && localSettingsJSON.settings) {
      // set style slider
      if (localSettingsJSON.settings.style !== undefined) {
        setStyleSlider(localSettingsJSON.settings.style);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (localSettings && localSettings.type !== "JSON") {
      let oldString = localSettings;
      console.log(oldString);
      const newSettings = {
        opportunities: [
          {
            name: oldString.split(" ").slice(0, 3).join(" ").concat(" ..."),
            description: oldString,
            id: 1,
            selected: true,
          },
        ],
      };
      // remove old settings
      localStorage.removeItem("local-settings");
      // set new settings
      setLocalSettingsJSON("settings", newSettings);
      setSelectedProduct(newSettings.opportunities[0]);
      // reload page to display text area
      window.location.reload();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dynamicsAPIData && dynamicsAPIData.products) {
      // parse dynamicsAPIData.products as opportunity and add to localSettingsJSON
      let opportunities = [];
      let highestId = 0;
      let index = 0;
      // get current opportunities from localSettingsJSON
      if (
        localSettingsJSON &&
        localSettingsJSON.settings &&
        localSettingsJSON.settings.opportunities
      ) {
        opportunities = localSettingsJSON.settings.opportunities;

        // get highest id
        opportunities.forEach((opportunity) => {
          if (opportunity.id > highestId) {
            highestId = opportunity.id;
          }
        });
        dynamicsAPIData.products.forEach((product) => {
          // if product.name is already in opportunities, skip
          let exists = false;
          opportunities.forEach((opportunity) => {
            if (opportunity.name === product.name) {
              exists = true;
            }
          });

          if (exists) {
            return;
          }
          index++;
          opportunities.push({
            name: product.name,
            description: product.description,
            id: highestId + index,
            selected: false,
            productid: product.productid,
          });
        });
        // update localSettingsJSON
        setLocalSettingsJSON("settings", {
          ...localSettingsJSON.settings,
          opportunities: opportunities,
        });
      } else {
        dynamicsAPIData.products.forEach((product) => {
          index++;
          opportunities.push({
            name: product.name,
            description: product.description,
            id: highestId + index,
            selected: index === 1,
            productid: product.productid,
          });
        });
        // update localSettingsJSON
        setLocalSettingsJSON("settings", { opportunities: opportunities });
      }

      if (useCRMData) {
        //setSelectedContact(dynamicsAPIData.contacts[0]);
      }

      // if URL contains contactid, set selected contact from dynamicsAPIData
      if (window.location.href.includes("contactid")) {
        const contactId = window.location.href.split("contactid=")[1];
        dynamicsAPIData.contacts.forEach((contact) => {
          if (contact.contactid === contactId) {
            setSelectedContact(contact);
            setLocalSettingsJSON("prospect", {
              name: contact.fullname,
              email: contact.emailaddress1,
              companyname: contact.parentcustomerid_account.name,
              jobtitle: contact.jobtitle,
            });
          }
        });
      }
    }

    // setup search for dynamicsAPIData
    if (dynamicsAPIData) {
      const fullContacts = dynamicsAPIData.contacts.map((contact) => ({
        item: Object.assign(contact, {}),
        matches: [],
        score: 1,
      }));
      setSearchResults(fullContacts);
      const fuse = new Fuse(dynamicsAPIData.contacts, {
        keys: ["jobtitle", "fullname", "parentcustomerid_account.name"],
        threshold: 0.1,
      });
      setSearchFuse(fuse);
    }
  }, [dynamicsAPIData]); // eslint-disable-line react-hooks/exhaustive-deps

  // setup search for salesforceAPIData
  useEffect(() => {
    if (salesforceAPIData) {
      const fullContacts = salesforceAPIData.contacts.records.map(
        (contact) => ({
          item: Object.assign(contact, {}),
          matches: [],
          score: 1,
        })
      );
      setSearchResults(fullContacts);
      const fuse = new Fuse(salesforceAPIData.contacts.records, {
        keys: ["Title", "Name", "Account.Name"],
        threshold: 0.1,
      });
      setSearchFuse(fuse);
    }
  }, [salesforceAPIData]); // eslint-disable-line react-hooks/exhaustive-deps

  // setup search for hupSpotAPIData
  useEffect(() => {
    if (hubSpotAPIData) {
      const fullContacts = hubSpotAPIData.map((contact) => ({
        item: Object.assign(contact, {}),
        matches: [],
        score: 1,
      }));
      setSearchResults(fullContacts);
      const fuse = new Fuse(hubSpotAPIData, {
        keys: [
          "properties.jobtitle",
          "properties.firstname",
          "properties.lastname",
          "properties.company",
        ],
        threshold: 0.1,
      });
      setSearchFuse(fuse);
    }
  }, [hubSpotAPIData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (salesforceAPIData && salesforceAPIData.products) {
      // parse salesforceAPIData.products as opportunity and add to localSettingsJSON
      let opportunities = [];
      let highestId = 0;
      let index = 0;
      // get current opportunities from localSettingsJSON
      if (
        localSettingsJSON &&
        localSettingsJSON.settings &&
        localSettingsJSON.settings.opportunities
      ) {
        opportunities = localSettingsJSON.settings.opportunities;

        // get highest id
        opportunities.forEach((opportunity) => {
          if (opportunity.id > highestId) {
            highestId = opportunity.id;
          }
        });
        salesforceAPIData.products.records.forEach((product) => {
          // if product.name is already in opportunities, skip
          let exists = false;
          opportunities.forEach((opportunity) => {
            if (opportunity.name === product.Name) {
              exists = true;
            }
          });

          if (exists) {
            return;
          }
          index++;
          opportunities.push({
            name: product.Name,
            description: product.Description,
            id: highestId + index,
            selected: false,
            productid: product.Id,
          });
        });
        // update localSettingsJSON
        setLocalSettingsJSON("settings", {
          ...localSettingsJSON.settings,
          opportunities: opportunities,
        });
      } else {
        salesforceAPIData.products.records.forEach((product) => {
          index++;
          opportunities.push({
            name: product.Name,
            description: product.Description,
            id: highestId + index,
            selected: index === 1,
            productid: product.Id,
          });
        });
        // update localSettingsJSON
        setLocalSettingsJSON("settings", { opportunities: opportunities });
      }
    }
  }, [salesforceAPIData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return; // WARNING: this is actually unwanted behaviour, so I'm disabling it for now
    if (dynamicsAPIData) {
      setSelectedContact(dynamicsAPIData.contacts[0]);
    } else if (salesforceAPIData) {
      setSelectedContact({
        fullname: salesforceAPIData.contacts.records[0].Id,
        emailaddress1: salesforceAPIData.contacts.records[0].Email,
        parentcustomerid_account: {
          name: salesforceAPIData.contacts.records[0].Name,
        },
        jobtitle: salesforceAPIData.contacts.records[0].Title,
      });
    } else if (hubSpotAPIData) {
      setSelectedContact({
        fullname:
          hubSpotAPIData[0].properties.firstname +
          " " +
          hubSpotAPIData[0].properties.lastname,
        emailaddress1: hubSpotAPIData[0].properties.email,
        parentcustomerid_account: {
          name: hubSpotAPIData[0].properties.company,
        },
        jobtitle: hubSpotAPIData[0].properties.jobtitle,
        id: hubSpotAPIData[0].id,
        linkedin: hubSpotAPIData[0].properties.linkedin_url,
        phone: hubSpotAPIData[0].properties.phone,
      });
    } else if (localCRMData) {
      setSelectedContact({
        fullname: localCRMData[0]["Prospect Name"],
        emailaddress1: "",
        parentcustomerid_account: {
          name: localCRMData[0]["Account Name"],
        },
        jobtitle: localCRMData[0]["Job Title"],
      });
    }
  }, [useCRMData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(
      "isAuthenticated: ",
      isIntegrationAuthenticated(msalIsAuthenticated)
    );
    if (isIntegrationAuthenticated(msalIsAuthenticated)) {
      // delete local-CRM and reload page if it exists
      if (localStorage.getItem("local-CRM")) {
        localStorage.removeItem("local-CRM");
        window.location.reload();
      }
      if (msalIsAuthenticated) {
        RequestContactData();
      } else if (localStorage.getItem("salesforceAccessToken")) {
        async function handleSalesforceFetch() {
          const data = await fetchData();
          setSalesforceAPIData(data);
        }

        handleSalesforceFetch();
      } else {
        async function handleHubSpotFetch() {
          const data = await getHubspotContacts();
          setHubSpotAPIData(data);
        }

        handleHubSpotFetch();
      }
    }
  }, [isIntegrationAuthenticated(msalIsAuthenticated)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedContact && selectedProduct) {
      if (
        selectedContact.fullname &&
        selectedContact.parentcustomerid_account.name &&
        selectedContact.jobtitle &&
        selectedProduct.description
      ) {
        setRequiredFields(true);
      }
    }
  }, [selectedContact, selectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProduct) {
      console.log(selectedProduct);

      // Find the index of the selected opportunity
      const selectedIndex = localSettingsJSON.settings.opportunities.findIndex(
        (opportunity) => opportunity.selected
      );

      if (selectedIndex !== -1) {
        // Update the selected opportunity with the new description
        const updatedOpportunities =
          localSettingsJSON.settings.opportunities.map((opportunity, index) =>
            index === selectedIndex
              ? { ...opportunity, description: selectedProduct.description }
              : opportunity
          );

        // Update localSettingsJSON using setLocalSettingsJSON
        setLocalSettingsJSON("settings", {
          ...localSettingsJSON.settings,
          opportunities: updatedOpportunities,
        });
      }
    }
  }, [selectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // use setSelectedContact to update the selected contact from the prospect in the localSettingsJSON. localSettingsJSON.prospect.name is fullname: value
    if (localSettingsJSON && localSettingsJSON.prospect) {
      setSelectedContact({
        fullname: localSettingsJSON.prospect.name,
        emailaddress1: localSettingsJSON.prospect.email,
        parentcustomerid_account: {
          name: localSettingsJSON.prospect.companyname,
        },
        jobtitle: localSettingsJSON.prospect.jobtitle,
        id: localSettingsJSON.prospect.id,
        linkedin: localSettingsJSON.prospect.linkedin,
        phone: localSettingsJSON.prospect.phone,
      });
      if (localSettingsJSON.prospect.companyname) {
        setSearch(localSettingsJSON.prospect.companyname);
      }
    }
  }, [localSettingsJSON]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (search) {
      console.log("new company search:", search);
      callAsterisk(search);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AuthTemplate>
        <div>
          {!useCRMData ? (
            <button
              type="button"
              className="block w-full rounded-md h-[46px] border border-gray-200 bg-white p-3 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 py-3 text-xs font-medium text-gray-700 mb-3"
              onClick={() => {
                setUseCRMData(true);
              }}
            >
              Import contacts from CRM
            </button>
          ) : (
            <>
              <Combobox
                className="block w-full mb-3 z-10"
                onChange={(c) => {
                  if (dynamicsAPIData) {
                    setLocalSettingsJSON("prospect", {
                      name: c.fullname,
                      email: c.emailaddress1,
                      companyname: c.parentcustomerid_account.name,
                      jobtitle: c.jobtitle,
                      id: c.contactid,
                    });
                  } else if (salesforceAPIData) {
                    setLocalSettingsJSON("prospect", {
                      name: c.Name,
                      email: c.Email,
                      companyname: c.Account.Name,
                      jobtitle: c.Title,
                      id: c.Id,
                    });
                  } else if (hubSpotAPIData) {
                    setLocalSettingsJSON("prospect", {
                      name:
                        c.properties.firstname + " " + c.properties.lastname,
                      email: c.properties.email,
                      companyname: c.properties.company,
                      jobtitle: c.properties.jobtitle,
                      id: c.id,
                      linkedin: c.properties.linkedin_url,
                      phone: c.properties.phone,
                    });
                  } else {
                    setLocalSettingsJSON("prospect", {
                      name: c.Name,
                      email: c.Email,
                      companyname: c.Account.Name,
                      jobtitle: c.Title,
                    });
                  }
                }}
              >
                <div className="relative">
                  <div className="relative w-full cursor-default overflow-hidden text-left">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <Combobox.Button as="div">
                      <Combobox.Input
                        className="w-full h-[46px] rounded-md border border-gray-200 py-2 pl-10 pr-10 leading-5 bg-white focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-xs font-medium text-gray-700 focus:ring-0"
                        // displayValue={(contact) => contact["Prospect Name"]}
                        onChange={handleSearchChange}
                        placeholder="Search Prospects, Accounts …"
                      />
                    </Combobox.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setSearchTerm("")}
                  >
                    <Combobox.Options
                      static
                      className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      {searchResults.length === 0 && searchTerm !== "" ? (
                        <div className="relative text-xs cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : dynamicsAPIData ? (
                        searchResults.map((contact) => (
                          <Combobox.Option
                            key={contact.item.contactid}
                            value={contact.item}
                            className={({ active }) =>
                              `relative cursor-default text-xs select-none py-2 px-4 ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-900"
                              }`
                            }
                          >
                            <span className="block truncate font-normal">
                              {contact.item.fullname} –{" "}
                              {contact.item.parentcustomerid_account.name}
                            </span>
                          </Combobox.Option>
                        ))
                      ) : salesforceAPIData ? (
                        searchResults.map((contact) => (
                          <Combobox.Option
                            key={contact.item.Id}
                            value={contact.item}
                            className={({ active }) =>
                              `relative cursor-default text-xs select-none py-2 px-4 ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-900"
                              }`
                            }
                          >
                            <span className="block truncate font-normal">
                              {contact.item.Name} – {contact.item.Account.Name}
                            </span>
                          </Combobox.Option>
                        ))
                      ) : (
                        hubSpotAPIData &&
                        searchResults.map((contact) => (
                          <Combobox.Option
                            key={contact.item.id}
                            value={contact.item}
                            className={({ active }) =>
                              `relative cursor-default text-xs select-none py-2 px-4 ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-900"
                              }`
                            }
                          >
                            <span className="block truncate font-normal">
                              {contact.item.properties.firstname}{" "}
                              {contact.item.properties.lastname} –{" "}
                              {contact.item.properties.company}
                            </span>
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
              {/*<select*/}
              {/*  id="contact-select"*/}
              {/*  name="contact-select"*/}
              {/*  autoComplete="contact"*/}
              {/*  className="block w-full h-[46px] truncate pr-7 rounded-md border border-gray-200 bg-white p-3 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 py-3 text-xs font-medium text-gray-700 mb-3"*/}
              {/*  onChange={(e) => {*/}
              {/*    if (dynamicsAPIData) {*/}
              {/*      const contact = dynamicsAPIData.contacts.find(*/}
              {/*        (contact) => contact.contactid === e.target.value*/}
              {/*      );*/}
              {/*      setLocalSettingsJSON("prospect", {*/}
              {/*        name: contact.fullname,*/}
              {/*        email: contact.emailaddress1,*/}
              {/*        companyname: contact.parentcustomerid_account.name,*/}
              {/*        jobtitle: contact.jobtitle,*/}
              {/*      });*/}
              {/*    } else {*/}
              {/*      const contact = salesforceAPIData.contacts.records.find(*/}
              {/*        (record) => record.Id === e.target.value*/}
              {/*      );*/}
              {/*      setLocalSettingsJSON("prospect", {*/}
              {/*        name: contact.Name,*/}
              {/*        email: contact.Email,*/}
              {/*        companyname: contact.Account.Name,*/}
              {/*        jobtitle: contact.Title,*/}
              {/*      });*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {(dynamicsAPIData &&*/}
              {/*    dynamicsAPIData.contacts.map((contact) => (*/}
              {/*      <option value={contact.contactid} key={contact.contactid}>*/}
              {/*        {contact.fullname} -{" "}*/}
              {/*        {contact.parentcustomerid_account.name}*/}
              {/*      </option>*/}
              {/*    ))) ||*/}
              {/*    (salesforceAPIData &&*/}
              {/*      salesforceAPIData.contacts.records.map((record) => (*/}
              {/*        <option value={record.Id} key={record.Id}>*/}
              {/*          {record.Name} – {record.Account.Name}*/}
              {/*        </option>*/}
              {/*      ))) || <option value="0">Loading …</option>}*/}
              {/*</select>*/}
            </>
          )}
        </div>
      </AuthTemplate>
      <UnAuthTemplate>
        <Transition appear show={showCsvUpload} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setShowCsvUpload(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="bg-stone-100 w-full max-w-xl transform overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                      <div className="w-full max-w-md">
                        <div className="flex items-center justify-center w-full">
                          <label
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-8 h-8 text-gray-500 mb-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                              <p className="mb-4 text-sm text-gray-500">
                                <span className="font-semibold">
                                  Click to import
                                </span>{" "}
                                your CRM data from a CSV file.
                              </p>
                              <div
                                className="flex items-center p-4 mx-8 text-xs text-gray-500 border border-gray-300 rounded-lg bg-gray-50"
                                role="alert"
                              >
                                <svg
                                  className="flex-shrink-0 inline w-4 h-4 mr-3"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                </svg>
                                <span className="sr-only">Info</span>
                                <div>
                                  <span className="font-medium">
                                    Please import a CSV file
                                  </span>{" "}
                                  that only contains exactly these columns with
                                  column names and order matching:{" "}
                                  <div className="mt-2 font-mono p-2 rounded-md bg-stone-100 text-center">
                                    Account Name, Prospect Name, Job Title
                                  </div>
                                </div>
                              </div>
                              <p className="text-xs text-gray-500"></p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              accept=".csv"
                              className="hidden"
                              onChange={handleCsvFileChange}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {!useCRMData ? (
          <div className="flex w-full items-center mb-3">
            <p className="flex-grow items-center rounded-md border border-gray-200 bg-white p-3 py-3 text-xs font-medium text-gray-700 text-center">
              <span className="text-sm mr-2">👋</span>Please connect your CRM
            </p>
            <button
              title="Import CSV"
              onClick={() => setShowCsvUpload(true)}
              className="rounded-md border border-gray-200 from-indigo-400 to-indigo-600 bg-white ml-1 aspect-square h-[46px] flex justify-center items-center text-indigo-600 focus:border-indigo-500 outline-none hover:border-indigo-600 hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:text-stone-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
            </button>
          </div>
        ) : (
          <>
            <div className="flex w-full items-center mb-3">
              <Combobox
                className="block w-full z-10"
                onChange={(c) => {
                  const contact = c;
                  setLocalSettingsJSON("prospect", {
                    name: contact["Prospect Name"],
                    email: "",
                    companyname: contact["Account Name"],
                    jobtitle: contact["Job Title"],
                  });
                }}
              >
                <div className="relative">
                  <div className="relative w-full cursor-default overflow-hidden text-left">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <Combobox.Button as="div">
                      <Combobox.Input
                        className="w-full h-[46px] rounded-md border border-gray-200 py-2 pl-10 pr-10 leading-5 bg-white focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-xs font-medium text-gray-700 focus:ring-0"
                        // displayValue={(contact) => contact["Prospect Name"]}
                        onChange={handleSearchChange}
                        placeholder="Search Prospects, Accounts …"
                      />
                    </Combobox.Button>
                    {/*<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">*/}
                    {/*  <ChevronUpDownIcon*/}
                    {/*    className="h-5 w-5 text-gray-400"*/}
                    {/*    aria-hidden="true"*/}
                    {/*  />*/}
                    {/*</Combobox.Button>*/}
                  </div>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setSearchTerm("")}
                  >
                    <Combobox.Options
                      static
                      className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      {searchResults.length === 0 && searchTerm !== "" ? (
                        <div className="relative text-xs cursor-default select-none py-2 px-4 text-gray-700">
                          Nothing found.
                        </div>
                      ) : (
                        searchResults.map((contact, index) => (
                          <Combobox.Option
                            key={index}
                            value={contact.item}
                            className={({ active }) =>
                              `relative cursor-default text-xs select-none py-2 px-4 ${
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-900"
                              }`
                            }
                          >
                            <span className="block truncate font-normal">
                              {contact.item["Prospect Name"]} –{" "}
                              {contact.item["Account Name"]}
                            </span>
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
              <button
                title="Import CSV"
                onClick={() => setShowCsvUpload(true)}
                className="rounded-md border border-gray-200 from-indigo-400 to-indigo-600 bg-white ml-1 aspect-square h-[46px] flex justify-center items-center text-indigo-600 focus:border-indigo-500 outline-none hover:border-indigo-600 hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:text-stone-50"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                  />
                </svg>
              </button>
            </div>
            <div></div>
          </>
        )}
      </UnAuthTemplate>
      <div className="bg-stone-100 rounded-md ring-1 ring-slate-900/10 text-gray-700">
        <div className="mx-auto max-w-7xl pt-6">
          <div>
            <div className="">
              <div className="">
                <div className="px-4 flex justify-between items-center">
                  <h3 className="text-md font-medium text-gray-500 flex">
                    Prospect
                  </h3>
                  <button
                    title="Clear prospect"
                    className="p-2 text-xs text-gray-500 rounded-md hover:bg-stone-200"
                    onClick={() => {
                      setLocalSettingsJSON("prospect", null);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-5">
                <form id="contactInfo">
                  <div className="">
                    <div className="space-y-3 px-4 py-5">
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 flex rounded-md">
                          <CompellingTextField
                            id="prospect-company"
                            size="small"
                            label="Account Name"
                            name="prospect-company"
                            variant="outlined"
                            fullWidth
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setProspects({});
                              if (
                                localSettingsJSON &&
                                localSettingsJSON.prospect
                              ) {
                                setLocalSettingsJSON("prospect", {
                                  ...localSettingsJSON.prospect,
                                  companyname: event.target.value,
                                });
                              } else {
                                setLocalSettingsJSON("prospect", {
                                  companyname: event.target.value,
                                });
                              }
                            }}
                            // onBlur={(
                            //   event: React.ChangeEvent<HTMLInputElement>
                            // ) => {
                            //
                            // }}
                            value={
                              localSettingsJSON &&
                              localSettingsJSON.prospect &&
                              localSettingsJSON.prospect.companyname
                                ? localSettingsJSON.prospect.companyname
                                : ""
                            }
                          />
                          <button
                            className="rounded-md border border-gray-300 from-indigo-400 to-indigo-600 bg-white ml-1 h-full aspect-square flex justify-center items-center text-indigo-600 focus:border-indigo-500 outline-none hover:border-indigo-600 hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:text-stone-50"
                            onClick={showProspects}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="w-4 h-4"
                            >
                              <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
                            </svg>
                          </button>
                          <Popover
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "center",
                              horizontal: "left",
                            }}
                            id="prospects-popover"
                            open={openProspects}
                            anchorEl={anchorProspects}
                            onClose={closeProspects}
                            PaperProps={{
                              sx: {
                                background: "none",
                                ml: 1,
                                minWidth: "35%",
                                maxWidth: "100%",
                                boxShadow: "none",
                              },
                            }}
                          >
                            <ul className="max-w-xl w-full">
                              {prospects &&
                              prospects.answer &&
                              prospects.answer instanceof Array &&
                              prospects.answer.length !== 0 ? (
                                prospects.answer.map((prospect: any, index) => (
                                  <li
                                    onClick={() => {
                                      if (
                                        localSettingsJSON &&
                                        localSettingsJSON.prospect
                                      ) {
                                        setLocalSettingsJSON("prospect", {
                                          ...localSettingsJSON.prospect,
                                          companyname: prospect.company,
                                          name: prospect.name,
                                          jobtitle: prospect.position,
                                          email: prospect.email,
                                          linkedin: prospects.source[index],
                                        });
                                      } else {
                                        setLocalSettingsJSON("prospect", {
                                          companyname: prospect.company,
                                          name: prospect.name,
                                          jobtitle: prospect.position,
                                          email: prospect.email,
                                          linkedin: prospects.source[index],
                                        });
                                      }
                                      closeProspects();
                                    }}
                                    className="group pb-3 w-full relative sm:pb-4 bg-white p-4 my-1 rounded-md border cursor-pointer border-indigo-600"
                                    key={index}
                                    value={prospect.name}
                                  >
                                    <div className="flex items-center space-x-4">
                                      <div className="flex-shrink-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="w-4 h-4 text-indigo-600 group-hover:scale-150 opacity-0 group-hover:opacity-100 transition duration-150 ease-in-out"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4.5 12.75l6 6 9-13.5"
                                          />
                                        </svg>
                                        {/*<img*/}
                                        {/*  className="w-8 h-8 rounded-full group-hover:scale-110 transition duration-150 ease-in-out"*/}
                                        {/*  src={*/}
                                        {/*    "https://i.pravatar.cc/150?img=" +*/}
                                        {/*    index*/}
                                        {/*  }*/}
                                        {/*  alt="Profile image"*/}
                                        {/*/>*/}
                                      </div>
                                      <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate">
                                          {prospect.name}
                                        </p>
                                        <p className="text-xs text-gray-500">
                                          {prospect.position} at{" "}
                                          {prospect.company}
                                        </p>
                                        {prospects && prospects.source && (
                                          <a
                                            href={prospects.source[index]}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-xs text-gray-500 hover:text-gray-700 hover:underline"
                                          >
                                            LinkedIn
                                          </a>
                                        )}
                                        {/*<p className="text-sm text-gray-500 truncate">*/}
                                        {/*  {contact.item.emailaddress1}*/}
                                        {/*</p>*/}
                                      </div>
                                    </div>
                                  </li>
                                ))
                              ) : (
                                <div className="bg-white rounded-md w-4/5 border border-indigo-600">
                                  {prospects && !prospects.answer ? (
                                    <li className="text-center text-sm text-gray-500 font-mono items-center justify-center grayscale flex p-3 opacity-50">
                                      <div>
                                        <svg
                                          aria-hidden="true"
                                          className="w-5 h-5 inline text-stone-400/50 animate-spin fill-stone-400/50 mr-2"
                                          viewBox="0 0 100 101"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                          />
                                        </svg>
                                        {loadingTexts[loadingIndex]}
                                      </div>
                                    </li>
                                  ) : (
                                    <li className="text-center text-gray-500 font-mono p-3 grayscale opacity-50">
                                      No prospects found 💩
                                    </li>
                                  )}
                                </div>
                              )}
                            </ul>
                          </Popover>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 shadow-sm rounded-md">
                          <CompellingTextField
                            id="prospect-name"
                            size="small"
                            label="Prospect Name"
                            name="prospect-name"
                            variant="outlined"
                            fullWidth
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (
                                localSettingsJSON &&
                                localSettingsJSON.prospect
                              ) {
                                setLocalSettingsJSON("prospect", {
                                  ...localSettingsJSON.prospect,
                                  name: event.target.value,
                                });
                              } else {
                                setLocalSettingsJSON("prospect", {
                                  name: event.target.value,
                                });
                              }
                            }}
                            value={
                              localSettingsJSON &&
                              localSettingsJSON.prospect &&
                              localSettingsJSON.prospect.name
                                ? localSettingsJSON.prospect.name
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 shadow-sm rounded-md">
                          <CompellingTextField
                            id="prospect-persona"
                            size="small"
                            label="Job Title"
                            name="prospect-persona"
                            variant="outlined"
                            fullWidth
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (
                                localSettingsJSON &&
                                localSettingsJSON.prospect
                              ) {
                                setLocalSettingsJSON("prospect", {
                                  ...localSettingsJSON.prospect,
                                  jobtitle: event.target.value,
                                });
                              } else {
                                setLocalSettingsJSON("prospect", {
                                  jobtitle: event.target.value,
                                });
                              }
                            }}
                            value={
                              localSettingsJSON &&
                              localSettingsJSON.prospect &&
                              localSettingsJSON.prospect.jobtitle
                                ? localSettingsJSON.prospect.jobtitle
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3">
                          <div>
                            <ul className="flex-grow ml-2 mt-1">
                              <li className="inline-flex mr-3">
                                <Tooltip
                                  disableHoverListener={
                                    !(
                                      localSettingsJSON &&
                                      localSettingsJSON.prospect &&
                                      localSettingsJSON.prospect.linkedin
                                    )
                                  }
                                  arrow
                                  title={
                                    <React.Fragment>
                                      Visit LinkedIn Profile
                                    </React.Fragment>
                                  }
                                  placement="bottom"
                                >
                                  <span>
                                    <button
                                      role="link"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.open(
                                          localSettingsJSON.prospect.linkedin,
                                          "_blank",
                                          "noreferrer"
                                        );
                                      }}
                                      disabled={
                                        !(
                                          localSettingsJSON &&
                                          localSettingsJSON.prospect &&
                                          localSettingsJSON.prospect.linkedin
                                        )
                                      }
                                      className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        className="w-4 h-4 text-gray-500"
                                        viewBox="0 0 192 192"
                                      >
                                        {
                                          <g>
                                            <path
                                              d="M0,192v-192h192v192z"
                                              fill="none"
                                            ></path>
                                            <g fill="currentColor">
                                              <g id="surface1">
                                                <path d="M156,0h-120c-19.875,0 -36,16.125 -36,36v120c0,19.875 16.125,36 36,36h120c19.875,0 36,-16.125 36,-36v-120c0,-19.875 -16.125,-36 -36,-36zM59.36539,162.98077h-29.82693l-0.17307,-89.30769h29.82692zM43.70192,61.99038h-0.17308c-9.75,0 -16.03846,-6.72115 -16.03846,-15.08653c0,-8.56731 6.49039,-15.0577 16.41347,-15.0577c9.92308,0 16.00961,6.49038 16.21153,15.0577c0,8.36538 -6.31731,15.08653 -16.41346,15.08653zM162.77885,162.98077h-30.08654v-48.51923c0,-11.74039 -3.11538,-19.73077 -13.61538,-19.73077c-8.01923,0 -12.34615,5.39423 -14.42308,10.61538c-0.77885,1.875 -0.98077,4.44231 -0.98077,7.06731v50.56731h-30.23077l-0.17308,-89.30769h30.23077l0.17308,12.60577c3.86538,-5.97116 10.29808,-14.42308 25.70192,-14.42308c19.09616,0 33.37501,12.46154 33.37501,39.25961v51.86539z"></path>
                                              </g>
                                            </g>
                                          </g>
                                        }
                                      </svg>
                                    </button>
                                  </span>
                                </Tooltip>
                              </li>
                              <li className="inline-flex mr-3">
                                <Tooltip
                                  disableHoverListener={
                                    !(
                                      localSettingsJSON &&
                                      localSettingsJSON.prospect &&
                                      localSettingsJSON.prospect.email
                                    )
                                  }
                                  arrow
                                  title={
                                    <React.Fragment>
                                      {localSettingsJSON &&
                                        localSettingsJSON.prospect &&
                                        localSettingsJSON.prospect.email &&
                                        localSettingsJSON.prospect.email}
                                    </React.Fragment>
                                  }
                                  placement="bottom"
                                >
                                  <span>
                                    <button
                                      onClick={(event) => {
                                        event.preventDefault();
                                        window.open(
                                          "mailto:" +
                                            localSettingsJSON.prospect.email,
                                          "_blank",
                                          "noreferrer"
                                        );
                                      }}
                                      disabled={
                                        !(
                                          localSettingsJSON &&
                                          localSettingsJSON.prospect &&
                                          localSettingsJSON.prospect.email
                                        )
                                      }
                                      className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-4 h-4 text-gray-500"
                                      >
                                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                      </svg>
                                    </button>
                                  </span>
                                </Tooltip>
                              </li>
                              <li className="inline-flex mr-3">
                                <Tooltip
                                  disableHoverListener={
                                    !(
                                      localSettingsJSON &&
                                      localSettingsJSON.prospect &&
                                      localSettingsJSON.prospect.phone
                                    )
                                  }
                                  arrow
                                  title={
                                    <React.Fragment>
                                      {localSettingsJSON &&
                                        localSettingsJSON.prospect &&
                                        localSettingsJSON.prospect.phone &&
                                        localSettingsJSON.prospect.phone}
                                    </React.Fragment>
                                  }
                                  placement="bottom"
                                >
                                  <span>
                                    <button
                                      onClick={(event) => {
                                        event.preventDefault();
                                        navigator.clipboard.writeText(
                                          localSettingsJSON.prospect.phone
                                        );
                                      }}
                                      disabled={
                                        !(
                                          localSettingsJSON &&
                                          localSettingsJSON.prospect &&
                                          localSettingsJSON.prospect.phone
                                        )
                                      }
                                      className="disabled:opacity-25 p-1 rounded-md hover:bg-stone-200"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-4 h-4 text-gray-500"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </button>
                                  </span>
                                </Tooltip>
                              </li>
                            </ul>
                          </div>
                          {/*<CompellingTextField*/}
                          {/*  id="prospect-email"*/}
                          {/*  size="small"*/}
                          {/*  label="Email (optional)"*/}
                          {/*  type="email"*/}
                          {/*  name="prospect-email"*/}
                          {/*  variant="outlined"*/}
                          {/*  fullWidth*/}
                          {/*  onChange={(*/}
                          {/*    event: React.ChangeEvent<HTMLInputElement>*/}
                          {/*  ) => {*/}
                          {/*    if (*/}
                          {/*      localSettingsJSON &&*/}
                          {/*      localSettingsJSON.prospect*/}
                          {/*    ) {*/}
                          {/*      setLocalSettingsJSON("prospect", {*/}
                          {/*        ...localSettingsJSON.prospect,*/}
                          {/*        email: event.target.value,*/}
                          {/*      });*/}
                          {/*    } else {*/}
                          {/*      setLocalSettingsJSON("prospect", {*/}
                          {/*        email: event.target.value,*/}
                          {/*      });*/}
                          {/*    }*/}
                          {/*  }}*/}
                          {/*  value={*/}
                          {/*    localSettingsJSON &&*/}
                          {/*    localSettingsJSON.prospect &&*/}
                          {/*    localSettingsJSON.prospect.email*/}
                          {/*      ? localSettingsJSON.prospect.email*/}
                          {/*      : ""*/}
                          {/*  }*/}
                          {/*/>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-stone-100 mt-3 rounded-md ring-1 ring-slate-900/10 text-gray-700">
        <div
          className={classNames(
            !settingsOpen && "pb-6",
            "mx-auto max-w-7xl pt-6"
          )}
        >
          <div>
            <div>
              <div className="px-4 justify-between flex items-center">
                <div className="text-md font-medium text-gray-500">
                  Outreach Settings
                </div>
                <button
                  className="p-2 text-xs text-gray-500 rounded-md hover:bg-stone-200"
                  onClick={() => {
                    setSettingsOpen(!settingsOpen);
                  }}
                >
                  {!settingsOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div className={classNames(!settingsOpen && "hidden", "mt-5")}>
              <div className="">
                <div className="space-y-3 px-4 py-5">
                  <div className="shadow-sm rounded-md border border-gray-300 bg-gray-50 p-3 sm:text-sm">
                    <label
                      htmlFor="opportunity-select"
                      className="text-sm text-gray-500 flex"
                    >
                      Products & Features
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <b>
                              {
                                "Describe your products and features you want to sell in a few words."
                              }
                            </b>
                            <br></br>
                            <br></br>
                            {
                              "This should be like your value proposition to your customer. It should include your product and/or company name, any specific features and how they solve your customer's problems."
                            }
                            <br></br>
                            <br></br>
                            <p className="text-gray-500">
                              Use the "+" button and provide a name to add a new
                              description.
                            </p>
                          </React.Fragment>
                        }
                        placement="right"
                      >
                        <svg
                          aria-hidden="true"
                          className="ml-1 flex-shrink-0 w-5 h-5 text-gray-500/50"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </HtmlTooltip>
                    </label>
                    <div className="mt-1">
                      <div className="flex mt-1">
                        <select
                          id="opportunity-select"
                          name="opportunity-select"
                          autoComplete="opportunity"
                          value={selectedProduct ? selectedProduct.id : ""}
                          onChange={(e) => {
                            setSelectedProduct(
                              localSettingsJSON.settings.opportunities.find(
                                (opportunity) =>
                                  opportunity.id === parseInt(e.target.value)
                              )
                            );
                            // set all opportunities to false
                            let newOpportunities =
                              localSettingsJSON.settings.opportunities.map(
                                (opportunity) => {
                                  return {
                                    ...opportunity,
                                    selected: false,
                                  };
                                }
                              );
                            // set selected opportunity to true
                            newOpportunities[
                              localSettingsJSON.settings.opportunities.findIndex(
                                (opportunity) =>
                                  opportunity.id === parseInt(e.target.value)
                              )
                            ].selected = true;

                            setLocalSettingsJSON("settings", {
                              ...localSettingsJSON.settings,
                              opportunities: newOpportunities,
                            });
                          }}
                          className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-7 shadow-sm focus:ring-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm truncate"
                        >
                          {localSettingsJSON ? (
                            localSettingsJSON.settings ? (
                              localSettingsJSON.settings.opportunities ? (
                                localSettingsJSON.settings.opportunities.map(
                                  (opportunity) => (
                                    <option
                                      value={opportunity.id}
                                      key={opportunity.id}
                                    >
                                      {opportunity.name}
                                    </option>
                                  )
                                )
                              ) : (
                                <option value="">Create a description</option>
                              )
                            ) : (
                              <option value="">Create a description</option>
                            )
                          ) : (
                            <option value="">Create a description</option>
                          )}
                        </select>
                        <button
                          className="rounded-md border border-gray-300 hover:border-indigo-600 hover:bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] hover:text-stone-50 from-indigo-400 to-indigo-600 bg-white ml-1 w-[3.5em] flex justify-center items-center text-indigo-600 focus:border-indigo-500 outline-none"
                          onClick={newDescription}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 5.25a.75.75 0 01.75.75v5.25H18a.75.75 0 010 1.5h-5.25V18a.75.75 0 01-1.5 0v-5.25H6a.75.75 0 010-1.5h5.25V6a.75.75 0 01.75-.75z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <Popover
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          id="description-popover"
                          open={openNewDesc}
                          anchorEl={anchorDescription}
                          onClose={closeDescription}
                          PaperProps={{
                            sx: {
                              background: "none",
                              mt: 1,
                              minWidth: "35%",
                              maxWidth: "100%",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <div className="flex justify-center">
                            <div className="">
                              <form onSubmit={newOpportunity}>
                                <input
                                  type="text"
                                  ref={newOpportunityRef}
                                  className="p-4 placeholder-gray-300 block rounded-md border border-gray-300 focus:ring-0 focus:border-indigo-500 focus:ring-indigo-500 text-xs outline-none"
                                  placeholder="Description name"
                                  autoFocus
                                ></input>
                                <button
                                  type="submit"
                                  className="rounded-md bg-indigo-600 text-white flex justify-center items-center outline-none py-1 px-2 mt-1 float-right text-xs"
                                >
                                  Save
                                </button>
                              </form>
                            </div>
                          </div>
                        </Popover>
                      </div>
                      {selectedProduct && (
                        <textarea
                          id="opportunity-text"
                          name="opportunity-text"
                          rows={6}
                          className="placeholder-gray-300 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-0 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                          placeholder="compelling.ai creates outreach personalized to compelling events in seconds. We enable B2B SaaS companies to 10x their conversions from accounts to meetings."
                          // set default value to the description of the selected option
                          value={
                            selectedProduct ? selectedProduct.description : ""
                          }
                          onInput={(e) => {
                            setSelectedProduct({
                              ...selectedProduct,
                              description: e.target.value,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="shadow-sm rounded-md border bg-gray-50 p-3 border-gray-300 sm:text-sm">
                    <label
                      htmlFor="compelling-events"
                      className="text-sm text-gray-500 flex"
                    >
                      Compelling events
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <b>
                              {
                                "Compelling events refer to the events at your prospect's company that make your product or service more relevant to them."
                              }
                            </b>
                            <br></br>
                            <br></br>
                            {
                              "For example, if your product is great at scaling, you might want to target companies that just raised a round of funding."
                            }
                            <br></br>
                            <br></br>
                            <p className="text-gray-500">
                              The tags in the dropdown are just suggestions, you
                              are free to type your own events.
                            </p>
                          </React.Fragment>
                        }
                        placement="right"
                      >
                        <svg
                          aria-hidden="true"
                          className="ml-1 flex-shrink-0 w-5 h-5 text-gray-500/50"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </HtmlTooltip>
                    </label>
                    <div className="mt-1">
                      <Tags
                        id="prospect-compelling-events"
                        className="mt-1 block w-full bg-white rounded-md border-gray-300 shadow-sm text-xs"
                        // tagifyRef={tagifyRef} // optional Ref object for the Tagify instance itself, to get access to  inner-methods
                        value={compellingTags}
                        settings={{
                          maxTags: 10,
                          placeholder: "Define up to 10 event tags",
                          id: "prospect-compelling-events",
                          whitelist: [
                            "Funding",
                            "Open sales roles",
                            "Expansion",
                            "Regulatory change",
                            "New partnership",
                            "Acquisition",
                            "Leadership change",
                            "New product",
                            "New service",
                            "New feature",
                            "New pricing",
                            "New competitor",
                          ],
                          delimiters: null,
                          dropdown: {
                            classname: "tags-look",
                            enabled: 0, // show the dropdown immediately on focus
                            maxItems: 8,
                            position: "text", // place the dropdown near the typed text
                            closeOnSelect: false, // keep the dropdown open after selecting a suggestion
                            highlightFirst: true,
                          },
                        }}
                        onChange={onTagChange}
                      />
                    </div>
                  </div>
                  <div className="shadow-sm rounded-md border bg-gray-50 p-3 border-gray-300 sm:text-sm">
                    <label
                      htmlFor="prospect-tone"
                      className="text-sm text-gray-500 flex"
                    >
                      Preferred tone
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <b>{"Set the tone of your outreach."}</b>
                            <br></br>
                            <br></br>
                            {
                              "Provide descriptive words to fine-tune how you want to come across to your prospects and how you think your prospects would like to be approached."
                            }
                            <br></br>
                            <br></br>
                            <p className="text-gray-500">
                              The tags in the dropdown are just suggestions, you
                              are free to type your own tone descriptors.
                            </p>
                          </React.Fragment>
                        }
                        placement="right"
                      >
                        <svg
                          aria-hidden="true"
                          className="ml-1 flex-shrink-0 w-5 h-5 text-gray-500/50"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </HtmlTooltip>
                    </label>
                    <div className="mt-1">
                      <Tags
                        id="prospect-tone"
                        className="mt-1 block w-full bg-white rounded-md border-gray-300 shadow-sm text-xs"
                        // tagifyRef={tagifyRef} // optional Ref object for the Tagify instance itself, to get access to  inner-methods
                        value={toneTags}
                        settings={{
                          placeholder: "Define up to 3 tone tags",
                          maxTags: 3,
                          id: "prospect-tone",
                          whitelist: [
                            "Personal",
                            "Humorous",
                            "Professional",
                            "Informative",
                            "Educational",
                            "Inspirational",
                            "Motivational",
                            "Funny",
                          ],
                          dropdown: {
                            classname: "tags-look",
                            enabled: 0, // show the dropdown immediately on focus
                            maxItems: 10,
                            position: "text", // place the dropdown near the typed text
                            closeOnSelect: false, // keep the dropdown open after selecting a suggestion
                            highlightFirst: true,
                          },
                        }}
                        onChange={onTagChange}
                      />
                    </div>
                  </div>
                  <div className="col-span-3 pt-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Unique writing style
                    </label>
                    <div className="mt-1 justify-center bg-gray-50 rounded-md border border-gray-300 px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center block mb-2">
                        {templateOpen === 0 ? (
                          <div className="grid grid-cols-3 gap-4">
                            <button
                              className={classNames(
                                templates[1] === undefined ||
                                  templates[1].template === ""
                                  ? "border-gray-300"
                                  : "border-compelling-bright",
                                "flex justify-center rounded-md border-2 border-dashed p-3"
                              )}
                              onClick={() => {
                                setTemplateOpen(1);
                              }}
                            >
                              <div className="text-xl grayscale font-mono text-compelling-bright hover:filter-none hover:scale-105 transition ease-in-out">
                                1<span className="text-2xl">📑</span>
                              </div>
                            </button>
                            <button
                              className={classNames(
                                templates[2] === undefined ||
                                  templates[2].template === ""
                                  ? "border-gray-300"
                                  : "border-compelling-bright",
                                "flex justify-center rounded-md border-2 border-dashed p-3"
                              )}
                              onClick={() => {
                                setTemplateOpen(2);
                              }}
                            >
                              <div className="text-xl grayscale font-mono text-compelling-bright hover:filter-none hover:scale-105 transition ease-in-out">
                                2<span className="text-2xl">📑</span>
                              </div>
                            </button>
                            <button
                              className={classNames(
                                templates[3] === undefined ||
                                  templates[3].template === ""
                                  ? "border-gray-300"
                                  : "border-compelling-bright",
                                "flex justify-center rounded-md border-2 border-dashed p-3"
                              )}
                              onClick={() => {
                                setTemplateOpen(3);
                              }}
                            >
                              <div className="text-xl grayscale font-mono text-compelling-bright hover:filter-none hover:scale-105 transition ease-in-out">
                                3<span className="text-2xl">📑</span>
                              </div>
                            </button>
                          </div>
                        ) : (
                          <>
                            <textarea
                              id={"template-" + templateOpen + "-text"}
                              defaultValue={
                                templates && templates[templateOpen]
                                  ? templates[templateOpen].template
                                  : ""
                              }
                              rows="4"
                              autoFocus
                              placeholder="Paste your template here."
                              className="mb-2 resize-none placeholder-gray-300 block w-full rounded-md border-gray-300 focus:ring-0 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                            ></textarea>
                            <div className="justify-end flex">
                              <div></div>
                              <button
                                className="p-2 text-xs text-gray-500 rounded-md hover:bg-stone-200"
                                onClick={() => {
                                  setTemplateOpen(0);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                              <button
                                className="p-2 text-xs text-indigo-600 rounded-md hover:bg-stone-200"
                                onClick={() => {
                                  saveTemplate(templateOpen);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12.75l6 6 9-13.5"
                                  />
                                </svg>
                              </button>
                            </div>
                          </>
                        )}
                        <div className="flex justify-center text-sm text-gray-600">
                          <label className="relative rounded-md font-medium text-gray-700">
                            <span>Train our AI on your messages</span>
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">
                          It will mimic your style of writing.
                        </p>
                      </div>
                      <Divider orientation="horizontal" flexItem>
                        <span className="font-mono text-sm text-gray-500">
                          OR
                        </span>
                      </Divider>
                      <div className="space-y-1 text-center block mt-2">
                        <button
                          className={classNames(
                            rules === ""
                              ? "border-gray-300"
                              : "border-compelling-bright",
                            "flex w-full justify-center rounded-md border-2 border-dashed p-3"
                          )}
                          onClick={editRules}
                        >
                          <div className="flex items-center grayscale font-mono text-compelling-bright text-sm hover:filter-none hover:scale-[102%] transition ease-in-out">
                            {rules === ""
                              ? "Set style rules"
                              : "Edit style rules"}
                            <span className="ml-2 text-2xl">📝</span>
                          </div>
                        </button>
                        <Popover
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          id="rules-popover"
                          open={openRules}
                          anchorEl={anchorRules}
                          onClose={closeRules}
                          PaperProps={{
                            sx: {
                              background: "none",
                              mt: 1,
                              minWidth: "35%",
                              maxWidth: "100%",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <textarea
                            autoFocus
                            id="rules-text"
                            name="rules-text"
                            rows={10}
                            className="mb-2 placeholder-gray-300 block w-full rounded-md border-gray-300 focus:ring-0 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
                            placeholder="Examples:&#10;1. Subject line&#10;- 2-3 words&#10;- No pitch, KPIs, or ROI figures&#10;- Pattern interrupt, sparks curiosity&#10;- Internal looking. Do the opposite of a marketing email&#10;2. Trigger&#10;- Tell the prospect why you reached out, and why now&#10;- This shows it's not a mass email, but personalised&#10;- Alternative triggers: hiring, growth, funding&#10;- Important: build the bridge to step 3&#10;3. Problem statement&#10;- A valid assumption about a business challenge&#10;- Needs a logic link to the observed trigger&#10;- No product pitch, but prospect centric&#10;- This is the value adding piece&#10;4. CTA&#10;- SOFT call to conversation&#10;- Simply ask a validating question&#10;- Never ask for time, too much commitment&#10;5. Whole Message&#10;- No more than 70 words"
                            // set default value to the description of the selected option
                            defaultValue={rules ? rules : ""}
                          />
                          <div
                            className="p-4 mb-4 text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
                            role="alert"
                          >
                            <div className="flex items-center justify-center font-mono">
                              <span className="mr-4 text-lg">🚧</span>
                              <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={noUseBestPractices}
                                  className="sr-only peer"
                                  onChange={(e) =>
                                    setNoUseBestPractices(
                                      null,
                                      e.target.checked
                                    )
                                  }
                                />
                                <div className="w-9 h-5 bg-yellow-800 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-100 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-yellow-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-yellow-300"></div>
                                <span className="ml-3 text-xs text-yellow-800">
                                  Deactivate best practices
                                </span>
                              </label>
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <b>
                                      {
                                        "Use this toggle to deactivate our best practices,"
                                      }
                                    </b>
                                    <br></br>
                                    {
                                      "if you think they might interfere with your rules."
                                    }
                                    <br></br>
                                    {"These are our 5 best practices:"}
                                    <br></br>
                                    {
                                      "1. Highlight a painful problem of the client. (Only if you know about one.) 2. Underline the business impact of my product/solution. 3. Do not use more than 100 words for your message. (Keep it short and simple.) 4. Ask exactly one question relating to information you have about the client. 5. Don't sell the product too offensively, but make it clear I work for the company."
                                    }
                                  </React.Fragment>
                                }
                              >
                                <svg
                                  aria-hidden="true"
                                  className="ml-1 flex-shrink-0 w-5 h-5 text-yellow-800/50"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </HtmlTooltip>
                            </div>
                          </div>
                        </Popover>
                        <div className="flex justify-center text-sm text-gray-600">
                          <label className="relative rounded-md font-medium text-gray-700">
                            <span>Set custom rules for our AI</span>
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">
                          Simply describe how you want
                        </p>
                        <p className="text-xs text-gray-500">
                          your messages to be written.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/*<div className="col-span-3 pt-1">*/}
                  {/*  <label className="block text-sm font-medium text-gray-700">*/}
                  {/*    Style focus*/}
                  {/*  </label>*/}
                  {/*  <div className="shadow-sm rounded-md mt-1 border bg-gray-50 border-gray-300 p-3 sm:text-sm px-10 flex items-center">*/}
                  {/*    <ThemeProvider theme={compellingTheme}>*/}
                  {/*      <Slider*/}
                  {/*        disabled*/}
                  {/*        aria-label="focus-slider"*/}
                  {/*        value={styleSlider}*/}
                  {/*        onChange={handleChange}*/}
                  {/*        step={50}*/}
                  {/*        marks={styleSliderMarks}*/}
                  {/*        classes={{*/}
                  {/*          markLabel: "!text-xs !font-mono",*/}
                  {/*        }}*/}
                  {/*        min={0}*/}
                  {/*        max={100}*/}
                  {/*        color="primary"*/}
                  {/*        track={false}*/}
                  {/*      />*/}
                  {/*    </ThemeProvider>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
